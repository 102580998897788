import { ButtonColor } from 'global/types/enums'
import type { TrxHistoryDetailFooterProps } from 'global/types/props'
import { Button } from 'shared/components/button'

export const TrxHistoryDetailFooter = ({ trxDetail, onClick }: TrxHistoryDetailFooterProps) => {
  switch (trxDetail?.paymentInformation.status.toLowerCase()) {
    case 'paid':
      return (
        <div className='grid grid-cols-1 p-4'>
          <Button
            label='Download Invoice'
            icon='arrow-down'
            color={ButtonColor.WhiteBordered}
            onClick={onClick}
          />
        </div>
      )

    case 'waitingpayment':
      return (
        <div className='grid grid-cols-2 p-4'>
          <Button label='Cancel' color={ButtonColor.WhiteBordered} onClick={onClick} />
          <Button label='Go to Payment' color={ButtonColor.Primary} onClick={onClick} />
        </div>
      )

    default:
      return null
  }
}
