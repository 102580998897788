import LocalizedStrings from 'react-localization'

export const L10N = new LocalizedStrings({
  'en-US': {
    common: {
      alertTitleInfo: 'INFO',
      alertTitleError: 'Oops...',
      alertTitleWarning: 'WARNING',
      alertTitleConfirmation: 'CONFIRMATION',
      alertTitleCameraPermission: 'Permission to use camera',
      buttonCaptionOK: 'OK',
      buttonCaptionCancel: 'CANCEL',
      buttonCaptionDetail: 'DETAIL',
      buttonCaptionYes: 'YES',
      buttonCaptionNo: 'NO',
      buttonCaptionSave: 'SAVE',
      buttonCaptionEdit: 'EDIT',
      buttonCaptionSubmit: 'SUBMIT',
      buttonCaptionNext: 'NEXT',
      buttonCaptionPrev: 'PREV',
      buttonCaptionBack: 'BACK',
      buttonCaptionClose: 'CLOSE',
      buttonCaptionReset: 'Reset',
      buttonCaptionApply: 'Apply',
      buttonCaptionSelectAll: 'Select All',
      buttonCaptionDeselectAll: 'Deselect All',
      buttonCaptionClear: 'Clear',
      buttonCaptionClockIn: 'Clock In',
      buttonCaptionClockOut: 'Clock Out',
      buttonCaptionTakePhoto: 'Take Photo',
      buttonCaptionChooseFromLibrary: 'Choose from Library',
      buttonCaptionUpdateApp: 'Update App',
      buttonCaptionLater: 'Later',
      buttonCaptionLikes: 'Likes',
      buttonCaptionDislikes: 'Dislikes',
      buttonCaptionContinue: 'Continue',
      buttonCaptionVerify: 'Verify',
      buttonCaptionMaybeLater: 'Maybe Later',
      placeholderEmail: 'your-email@domain.com',
      placeholderAppSearch: 'Search Application Name',
      errMsgEmptyRequiredFields: 'Required fields cannot be empty',
      errMsgInvalidEmailFormat: 'The email address is invalid',
      errMsgInvalidPhoneNumberFormat: 'The phone number format is invalid',
      errMsgMinPasswordLength: '`Minimum password length is ${PASSWORD_MIN_LENGTH} characters`',
      errMsgPasswordDoesNotMatch: 'The password does not match',
      errMsgCannotOpenUrl: 'Cannot open the URL',
      errMsgRequired: 'Required',
      errMsgNoResultFound: 'No result found',
      errMsgSessionExpired: 'Session expired. Please Log Out then Log In again',
      errMsgNoCameraFound: 'No camera found on this device',
      errMsgCameraPermissionRequired:
        "Camera permission is not granted. Please enable camera permission for this application in application\\'s setting",
      errMsgUserPhoneNotSet: 'Please set your phone number',
      errMsgExceedMaxPurchased: 'The voucher exceeds max allowed purchased',
      errMsgInsufficientMinimumAchievement:
        'Your achievement does not meet minimum achievement for this voucher',
      errMsgInsufficientMaximumAchievement:
        'Your achievement does not meet maximum achievement for this voucher',
      errMsgInsufficientBalanceToPurchaseVoucher:
        'You do not have enough balance to purchase this voucher',
      errMsgExpiredChallenge: 'The challenge was expired',
      errMsgCodeNotFound: 'The code was not found',
      errMsgScanLimitExceeded: 'The scan limit has been exceeded',
      errMsgHourlyScanLimitExceeded: 'The hourly scan limit has been exceeded',
      errMsgDailyScanLimitExceeded: 'The daily scan limit has been exceeded',
      errMsgWeeklyScanLimitExceeded: 'The weekly scan limit has been exceeded',
      errMsgMonthlyScanLimitExceeded: 'The monthly scan limit has been exceeded',
      errMsgVinNotFound: 'The VIN cannot be found',
      errMsgInvalidOldPassword: 'Invalid old password',
      errMsgInvalidUsernameOrPassword: 'Invalid username or password',
      errMsgPhoneNumberAlreadyExist: 'The phone number already exist',
      errMsgEmailAlreadyExist: 'The email already exist',
      errMsgInvalidReferralCode: 'Invalid referral code',
      errMsgPinExpired: 'PIN expired',
      errMsgPinHasBeenUsed: 'PIN has been used',
      errMsgInvalidPin: 'Invalid PIN',
      errMsgCarAlreadyOwned: 'You already own this car',
      errMsgPasswordComplexityRequirements:
        'Your password does not met complexity requirements. It must at least 8 chars, at least contains 1 numeric char, 1 lower case, 1 upper case, and 1 symbol',
      errMsgNotRegisteredOnTanamera:
        'Your email is not registered on Tanamera. Please create an account on Tanamera app using your email',
      errMsgVoucherNotAvailable: 'Sorry, voucher is not available',
      errMsgFileUploadedTooLarge: 'The file uploaded was too large',
      errMsgResourceNotFound: 'The resource was not found',
      errMsgResourceNotAvailable: 'The resource was not available',
      errMsgTimeoutContactingService: 'Timeout while contacting service',
      errMsgMaxNameLength:
        '`The maximum characters for First Name and Last Name combination is ${MAX_FULLNAME_LENGTH} characters`',
      errMsgYouHaveScannedThisCode: 'You have scanned this code before',
      errMsgInvalidNik: 'Please input your valid NIK (KTP) or Permit Number (KITAS)',
      errMsgFailedTakePic: 'Failed to take picture: ',
      msgTitleFakeLocationDetected: 'Fake Location Detected',
      msgRequestDisableFakeLocationApp:
        'Please do not use fake location app to continue using this app.',
      msgCameraPermission: 'We need your permission to use your camera',
      msgRootedDevice: 'Your device is not allowed to run this application.',
      headerTitleMMP: 'myHyundai Indonesia',
      labelHome: 'Home',
      labelDownloading: 'Downloading...',
      labelFilter: 'Filter',
      labelEventDate: 'Event Date',
      labelEventCategory: 'Event Category',
      labelStatus: 'Status',
      labelTo: 'To',
      labelFrom: 'From',
      labelEventCityLocation: 'Event City Location',
      labelBranchCompany: 'Branch Company',
      labelVersion: 'Version',
      labelRecentSearch: 'Recent Search',
      labelOlderAppVersion: 'Older App Version Detected!',
      labelUpdateApp:
        'Please update your app to the latest version.\r\nYour Version: {currentAppVersion}\r\nLatest Version: {latestAppVersion}',
      labeLoggingOut: 'Logging Out...',
      labelWelcomeTo: 'Welcome To',
      labelMmp: 'myHyundai',
      labelYes: 'Yes',
      labelNo: 'No',
      labelUpload: 'Upload',
      labelSelectPhoto: 'Select a Photo',
      labelLikedBy: 'Liked By',
      labelDislikedBy: 'Disliked By',
      labelStarBy: '{starRate} Star By',
      labelYourpasswordIs: 'Your password is',
      labelWeak: 'weak',
      labelMedium: 'medium',
      labelStrong: 'strong',
      labelComment: 'Comments',
      labelMember: 'Member',
      labelPasswordComplexity:
        'Your password must contain 1 lowercase letter, 1 uppercase letter, 1 number and minimum 8 characters. Ex: MyH9nD4!.1d',
      labelInformation: 'Information',
      msgTextCopied: 'Text Copied',
      errMsgEmptyRequired: 'Please input {fieldname}',
      labelMmpIndo: 'Indonesia',
      msgTakePhotoInBox: 'Please take a clear photo in a box provided',
      msgCoinsCouponsInfo: 'Member Info',
      msgHasBalance: 'myHyundai Indonesia has 2 balances',
      msg1Coins: '1. Coins',
      msgCoinsValue: 'Coin value equals to IDR value (i.e. 200,000 coins = 200,000 IDR)',
      msgYouCanEarnCoins: 'You can earn more coins by signing up your Hyundai Car',
      msgYouCanRedeemCoins: 'You can redeem coins with our rewards (vouchers)',
      msg2Coupons: '2. Coupons',
      msgYouCanGetCoupons:
        ' - You can get coupons from your activities in this app, like actively opening the app, taking part in the myHyundai Challenge, and visiting our dealer or exhibition',
      msgYouCanRedeemCoupons: ' - You also can redeem coupons with our rewards (vouchers)',
      labelRp: 'Rp ',
      labelHours: 'Hours',
      labelMinutes: 'Minutes',
      labelSeconds: 'Seconds',
      errMsgNotChoose: 'Please choose the option {fieldname}',
      labelCountryCode: 'Country code',
      alertLoginProg: 'Login on process',
      alertLoginSucc: 'Login Success',
      alertPleaseWait: 'Please wait',
      alertVerif: 'Verifikasi',
      alertCaptchaError: 'Captcha Error',
      alertCaptchaExp: 'Captcha Expired',
      lblResetPass: 'Reset Password',
      lblRegisPass: 'Registration',
      lblLoginSucc: 'Login now to continue using the application',
      labelSort: 'Sort',
      lblDelAccount: 'Delete Account',
      msgExitApps: 'Please press again to close!',
      internetAlert: {
        title: 'Internet Connection Problem',
        message: 'Please check your network connection'
      },
      loading: 'Loading',
      search: 'Search',
      filterSort: 'Filter & Sort',
      carModel: 'Car Model',
      labelExplore: 'Locator',
      youreHere: "You're here",
      direction: 'Direction',
      confirmation: 'Confirmation',
      noMoreData: 'No more data at the moment',
      labelTransaction: 'Transaction',
      labelSetting: 'Settings',
      labelContactUs: 'Contact Us',
      recentSearch: 'Recent Search',
      inputPin: 'Enter your secure PIN',
      inputPinInfo: 'Enter your PIN to continue payment',
      setupBiometric: 'Setup Biometric',
      msgValidating: 'Validating',
      msgFailedValidatingPin: 'Failed to validate PIN.',
      inputFingerprint: 'Verify Your Identity',
      inputFingerprintInfo: 'Please make sure your fingerprint is readable',
      cancel: 'Cancel',
      authRequired: 'Authentication Required',
      msgFailedAuth: 'Failed to authenticate.',
      msgTypeToSearch: 'Type in any keyword to begin',
      msgTryOtherKeyword: 'Try using another keyword',
      msgSwipeToRefresh: 'Swipe down to refresh',
      msgFailedLoadingHistory: 'Failed to load history.',
      stsWaitingPayment: 'Waiting for payment',
      stsPaid: 'Paid',
      stsExpire: 'Expired',
      stsDeny: 'Payment failed',
      stsPending: 'Pending',
      msgFailedLoadingTrxDetail: 'Failed to load transaction detail.',
      msgFailedLoadingData: 'Failed to load data.',
      successCopyText: 'Text copied.',
      stsCompleted: 'Completed',
      errMsgNoSlotAvailable:
        'Sorry, there is no slot available at the selected time. Please select another time.',
      errMsgItemNotFound: 'Item was not found',
      reload: 'Reload',
      okAddCar: 'OK, Add Car'
    },
    landingScreen: {
      labelWelcome: 'Welcome to',
      labelMmpLifestyle: 'myHyundai\\nIndonesia',
      labelNotMember: 'Not a member?',
      labelExperienceExciting: 'You are one tap away to Elevate Your Moments',
      buttonCaptionLoginWithEmail: 'Login with email',
      buttonCaptionRegisterHere: 'Register here'
    },
    loginScreen: {
      title: 'Login',
      labelGoodToSeeYou: 'Hi there, good to see you!',
      labelEmail: 'Email',
      labelPassword: 'Password',
      labelPlaceholderEmail: 'Login with email',
      labelPlaceholderPassword: 'Password',
      buttonCaptionForgotPassword: 'Forgot Pin ?',
      buttonCaptionRegisterHere: 'Register Here',
      buttonCaptionResetByPhone: 'Reset by phone',
      buttonCaptionResetByEmail: 'Reset by email',
      errEmptyUsernamePassword: 'Email and password cannot be empty'
    },
    emailSubmissionScreen: {
      errMsgEmailCannotBeEmpty: 'The email address cannot be empty'
    },
    registerEmailScreen: {
      title: 'Registration',
      description:
        'Write your email here. We will send a PIN to your email to continue the registration process'
    },
    pinVerificationScreen: {
      title: 'OTP Code Verification',
      subTitle: 'Enter PIN that we have sent to',
      labelExpiration: 'Your PIN will expire in',
      labelHaventGotThePin: "Didn't receive OTP Code?",
      labelYouCanResend: 'Request again in',
      labelPinHasExpired: 'Code has expired',
      labelCodeSentTo: 'A code is sent to:',
      labelPleaseCheckInbox: 'Please check your inbox and copy the OTP Code here',
      buttonCaptionResendPin: 'Resend Code',
      msgCancelConfirmation: 'Are you sure you want to cancel this process?',
      errMsgPinMustBeSixDigits: 'The PIN must be six digits',
      labelPleaseCheckOTP: 'Please check your inbox and copy the OTP Code here.'
    },
    resetPasswordScreen: {
      title: 'Forget Password',
      subtitle: 'Did you forgot your password?',
      description:
        'Enter your email address you’re using for your account below and enter new password',
      labelBackTo: 'Back to ',
      labelEmail: 'Email',
      labelNewPassword: 'New Password',
      labelRepeatPassword: 'Repeat New Password',
      labelPlaceholderEmail: 'ex: yourname@ntt.co.id',
      labelPlaceholderPassword:
        'Min. 8 characters, at least contains number, lower case, upper case, and symbol',
      buttonCaptionSubmit: 'Submit',
      buttonCaptionSignIn: 'Sign In'
    },
    registrationInfoScreen: {
      title: 'Sign Up',
      description: 'Fill the information below',
      labelFullName: 'Full Name',
      labelPhone: 'Phone Number',
      labelJobTitle: 'Job Title',
      labelPassword: 'Password',
      labelConfirmPassword: 'Confirm Password',
      labelReferralCode: 'Referral Code',
      labelEmail: 'Email',
      labelIAgree: 'I agree to ',
      labelAnd: ' and ',
      labelMyHyundai: ' myHyundai Indonesia',
      labelSalutation: 'Salutation',
      labelDateOfBirth: 'Date of Birth',
      labelFirstName: 'First Name',
      labelLastName: 'Last Name',
      msgPhoneNumberRule: 'Please input phone number with format 0812xxxxxx',
      msgFullNameRule:
        "`The maximum characters for First Name and Last Name combination is ${MAX_FULLNAME_LENGTH} characters, like ID card/KTP. So if your name is longer, you can summarize your name with it's first character. For example: Ramayana Kresna Mahabharata => Ramayana",
      buttonCaptionRegister: 'Sign Up',
      buttonCaptionTermsConditions: 'Terms and Conditions',
      buttonCaptionPrivacyPolicy: 'Privacy Policy',
      labelProvince: 'Province',
      labelCity: 'City',
      labelDistrict: 'District',
      labelHaveRefCode: 'Do you have referral code ?',
      placeholderSalutation: 'Select Salutation',
      placeholderDateOfBirth: 'dd/mm/yyyy',
      placeholderFirstName: 'Your First Name',
      placeholderLastName: 'Your Last Name',
      placeholderProvince: 'Select Province',
      placeholderCity: 'Select City',
      placeholderDistrict: 'Select District',
      placeholderPhone: 'Your Phone Number',
      placeholderPassword: 'Your Password',
      placeholderConfirmPassword: 'Confirm Password',
      placeholderReferralCode: 'Referral Code',
      placeholderEmail: 'Your Email',
      labelComplexityPass: 'Min 1 uppercase, 1 lowecase, special character'
    },
    homeScreen: {
      title: 'myHyundai Indonesia',
      labelShowQR: 'Show',
      labelMoreApps: 'Other Apps',
      labelApplication: 'Application',
      labelNewsUpdate: 'News update',
      labelforgetPassword: 'Forget Password',
      labelBanner: 'Banner',
      labelSeeMore: 'See All',
      labelMenu: 'Menus',
      labelMoreMenu: 'More Menu',
      labelMorning: 'morning',
      labelAfternoon: 'afternoon',
      labelEvening: 'evening',
      labelGood: 'Hi! Good',
      labelCoins: 'Coins',
      labelCoupons: 'Coupons',
      labelOurPartners: 'Our Partners',
      labelFindFascinatingDeal: 'Find fascinating deals from our partners',
      labelDeals: 'Deals',
      labelCollectCoupons: 'Collect your Coupons',
      labelCollectCouponsDescription: 'Join exciting challenges to get more coupons',
      labelWhatsOn: 'Explore More',
      labelSeeTheLatest: 'See the latest promo from Hyundai',
      labelSeeTheLatestUpdate: 'See the latest update from myHyundai',
      labelReadMore: 'Read More',
      labelAllApps: 'All Apps',
      labelMainApps: 'Main Apps',
      labelMy: 'my',
      labelHyundaiIndonesia: 'Hyundai Indonesia',
      labelIndonesia: 'Indonesia',
      buttonCaptionSeeMoreNews: 'See more news',
      buttonCaptionSeeAll: 'See All',
      buttonCaptionRegister: 'Register',
      buttonCaptionExclusiveDeals: 'Exclusive Benefits For You',
      buttonCaptionSeeAllChallenges: 'See all challenges',
      buttonCaptionChange: 'Change',
      labelMemberId: 'Member ID',
      buttonCaptionInputPromoCode: 'Input Promo Code',
      buttonCaptionGiiasChallage: 'GIIAS 2022 Challenges',
      buttonCaptionGiiasSmgChallenge: 'GIIAS Semarang 2022 Challenges',
      buttonCaptionGiiasSbyChallenge: 'GIIAS Surabaya 2022 Challenges',
      buttonCaptionGiiasMdnChallenge: 'GIIAS Medan 2022 Challenges',
      labelPartnerDeals: 'Rewards Redemption',
      labelLifestyleBenefit: 'Lifestyle Benefit',
      labelGetBestDeal: 'Get the best deal every time from our partners',
      labelWhileSupport: 'While supporting great cause with our community.',
      labelWhatsOnLife: "What's On Life Style",
      hyundaiServices: 'One-Stop Hyundai',
      promoCode: 'Promo Code',
      promoCodeDesc: 'Input Promo Code Here',
      validate: 'Validate',
      labelMember: 'Member',
      msgCoinsCouponsInfo: 'Member Info',
      campaignStory: "What's Going On ?",
      labelCarNotFound: 'Car not found',
      labelAddHyundaiCar: 'Add Hyundai Car',
      labelConnected: 'Connected',
      labelNotConnected: 'Not Connected',
      labelNotSupported: 'Not Supported'
    },
    forgetPasswordScreen: {
      title: 'Forget Password',
      labelEmail: 'Email',
      labelPhone: 'Phone',
      labelAlreadyHaveAccount: 'Already have an account? ',
      labelToSetUp: 'To set up your new password please input your {method} here',
      buttonCaptionSend: 'Send',
      buttonCaptionLoginHere: 'Login here',
      labelEmailWithStrip: 'E-mail',
      labelPhoneNumber: 'Phone Number',
      labelToSetUpPin: 'To set up your new PIN please input your {method} here',
      labelToSetUpPassword: 'To set up your new password please input your {method}.'
    },
    notificationScreen: {
      title: 'Notification Center',
      labelFilter: 'FILTER',
      buttonCaptionShowAll: 'Show All',
      buttonCaptionShowRead: 'Show Read',
      buttonCaptionShowUnread: 'Show Unread',
      buttonCaptionMarkAllRead: 'Mark All Read',
      buttonCaptionFilterAll: {
        id: 'all',
        label: 'All'
      },
      buttonCaptionFilterPromo: {
        id: 'promo',
        label: 'Promo'
      },
      buttonCaptionFilterCoins: {
        id: 'coins',
        label: 'Coins'
      },
      buttonCaptionFilterCampains: {
        id: 'campaign',
        label: 'Campaign'
      },
      buttonCaptionModalRead: {
        id: 'read',
        label: 'Read'
      },
      buttonCaptionModalUnread: {
        id: 'unread',
        label: 'Unread'
      },
      buttonCaptionModalAsc: {
        id: 'asc',
        label: 'Oldest'
      },
      buttonCaptionModalDsc: {
        id: 'desc',
        label: 'Latest'
      }
    },
    profileScreen: {
      title: 'Profile',
      labelCompanyData: 'Company Data',
      labelCompany: 'Company',
      labelBranch: 'Branch',
      labelBranchOfficeHour: 'Branch Office Hour',
      labelDepartment: 'Department',
      labelDivision: 'Division',
      labelManager: 'Manager',
      labelPersonalData: 'Personal Data',
      labelEmail: 'Email',
      labelPhoneNo: 'Phone No.',
      labelPreferenceOfficeHour: 'Preferred Office Hour',
      labelTo: 'to',
      labelPlus: '+',
      labelOldPassword: 'Old Password',
      labelNewPassword: 'New Password',
      labelConfirmNewPassword: 'Confirm New Password',
      labelLogoutAll: 'Logout From All Device',
      labelSalutation: 'Salutation',
      labelAddress: 'Address',
      labelPostalCode: 'Postal Code',
      labelCity: 'City',
      labelProvince: 'Province',
      labelFullName: 'Full Name',
      labelMr: 'Mr.',
      labelMs: 'Ms.',
      labelDateOfBirth: 'Date of Birth',
      buttonCaptionLogout: 'Log Out',
      buttonCaptionSeeAll: 'See All',
      buttonCaptionChangePassword: 'Change Password',
      buttonCaptionSave: 'Save',
      msgLogoutConfirmation: 'Are you sure you want to logout?',
      msgDeleteAccountConfirmation: 'Are you sure you want to delete your account?'
    },
    profileChangeEmailScreen: {
      title: 'Change Email',
      labelEmail: 'New Email',
      buttonCaptionUpdate: 'Update'
    },
    profileChangePhoneScreen: {
      title: 'Change Phone Number',
      labelPhone: 'New Phone Number',
      buttonCaptionUpdate: 'Update'
    },
    settingScreen: {
      labelAboutTheApp: 'About The App',
      labelV: 'V',
      buttonCaptionProfileSettings: 'Profile Settings',
      buttonCaptionChangePassword: 'Change Password',
      buttonCaptionChangeEmail: 'Change Email',
      buttonCaptionChangePhone: 'Change Phone Number',
      buttonCaptionPointBalanceHistory: 'Balance History',
      buttonCaptionCoinCouponExpiry: 'Expired Coin & Coupons',
      buttonCaptionSettings: 'Settings',
      buttonCaptionLanguageSetting: 'Language Setting',
      buttonCaptionTermsAndConditions: 'Terms and Conditions',
      buttonCaptionPrivacyPolicy: 'Privacy Policy',
      buttonCaptionFAQ: 'FAQ',
      buttonCaptionHelpCenter: 'Help Center',
      buttonCaptionLogOut: 'Sign out',
      buttonCaptionPaymentPin: 'Service & Promo Payment PIN',
      labelAccounts: 'Accounts',
      buttonCaptionChangePin: 'Change PIN',
      buttonCaptionLogOutDesc: 'Are You Sure You Want To Sign Out?',
      gffMember: 'Gff Member',
      title: 'Setting',
      faqTItle: 'FAQ',
      tabGeneral: 'General',
      tabEv: 'Ev Charger',
      faqGeneral: 'General Faqs',
      faqNotFound: 'Data not found',
      tabSupp: 'myHyundai CS',
      tabSales: 'After Sales CS',
      hyundaiIndo: 'myHyundai Indonesia',
      hyundaiMotor: 'Hyundai Motors Indonesia',
      socialMedia: 'Social Media',
      helpContactUs: 'Contact Us',
      myhundaiId: 'Myhyundai.Id',
      btnResetPhone: 'Reset Phone Number',
      btnResetPin: 'Reset PIN',
      modalInfoTitleEmail: 'Change Email',
      modalInfoTitlePhone: 'Change Phone Number',
      modalInfoDescEmail: 'Are You Sure Want To Change Email?',
      modalInfoDescPhone: 'Are You Sure Want To Change Phone Number?',
      modalInfoConfirm: 'Yes',
      modalInfoCancel: 'No',
      btnChangePassword: 'Change Password',
      modalPassTitle: 'Change Password',
      modalPassDesc: 'You Need To Re-Login To Set A New Password. Do You Want To Proceed?',
      modalDelTItle: 'Delete Account',
      modalDelDesc:
        'If You Terminate Your Account, You Will Lose All Existing Information, Including Vehicle Information. Are You Sure You Want To Terminate Your Account?',
      modalDelConfirm: 'Proceed',
      modalDelCancel: 'Cancel',
      btnDelAccount: 'Delete Account',
      loadingFaq: 'Please Wait, Searching...',
      modalConfirmResetPhoneTitle: 'Reset Phone Number',
      modalConfirmResetPhoneDesc:
        'We Are Going To Reset Your Phone Number. You Need To Re-Login To Set Up A New Phone Number. Do You Want To Proceed?',
      modalConfirmResetPhoneYes: 'Proceed',
      modalConfirmResetPhoneNo: 'Cancel',
      modalConfirmResetPinTitle: 'Reset PIN',
      modalConfirmResetPinDesc:
        'We are going to reset your PIN. You need to re-login to set up a new PIN. Do you want to proceed?',
      modalConfirmResetPinYes: 'Proceed',
      modalConfirmResetPinNo: 'Cancel',
      ptHyundaiMotorsId: 'PT Hyundai Motors Indonesia',
      helpCenterTitle: 'Customer Care'
    },
    editFavoriteScreen: {
      title: 'Edit Favorite',
      labelHelp: 'Click the change button to choose replacement app',
      labelChangeFavorite: 'Change'
    },
    chooseFavoriteScreen: {
      title: 'Choose Application'
    },
    allAppsScreen: {
      title: 'All Apps',
      labelSearch: 'Search'
    },
    approvalScreen: {
      title: 'My Approval',
      titleApproverDialog: 'Approver',
      labelActive: 'Active',
      labelHisotries: 'Histories',
      labelApproved: 'Approved',
      labelRejected: 'Rejected',
      labelRejectedReason: 'Rejected Reason:',
      labelReasonNotProvided: 'Reason not provided.',
      labelFindApproval: 'Find Approval',
      labelReject: 'Reject',
      labelReason: 'Reason',
      labelWaitingForApproval: 'Waiting For Approval',
      labelNeedInformation: 'Need Information',
      labelNoAnswer: 'No Answer',
      labelAll: 'All',
      labelCancelled: 'Cancelled',
      buttonCaptionApprove: 'Approve',
      buttonCaptionReject: 'Reject',
      buttonCaptionDetail: 'Detail',
      buttonCaptionCancel: 'Cancel',
      buttonCaptionCopyInfo: 'Copy Information',
      buttonCaptionSeeDetail: 'See Detail Event',
      msgApproveConfirmation: 'Are you sure you want to approve?'
    },
    onboardScreen: {
      titleFirstOnboard: 'Loyalty Benefit Just for You',
      titleSecondOnboard: 'Enjoy Exclusive Privilege',
      titleThirdOnboard: 'One-stop myHyundai Indonesia App for supporting Your Mobility',
      labelEng: 'EN',
      labelInd: 'ID',
      labelEnglish: 'English',
      labelIndonesia: 'Indonesia',
      labelFirstDescription:
        'Be special with our membership activity, various tier choices, and benefits for you to enjoy.',
      labelSecondDescription:
        'Not just to live up your lifestyle but also to support your mobility, myHyundai Indonesia app gives you many benefits that you can earn along the way.',
      labelThirdDescription: 'Get the latest info about your Hyundai community and services.',
      buttonCaptionSkip: 'Skip',
      buttonCaptionNext: 'Next',
      buttonCaptionPrevious: 'Previous',
      buttonCaptionGetStarted: 'Get Started'
    },
    profileEditDataScreen: {
      title: 'Edit Data',
      labelPersonalData: 'Personal Data',
      labelCompanyData: 'Company Data',
      labelAdditionalData: 'Additional Data',
      labelGender: 'Gender',
      labelPhone: 'Phone',
      labelPlaceholderPhone: 'Phone ex: 08123456789',
      labelGenderMale: 'Male',
      labelGenderFemale: 'Female',
      labelBloodType: 'Blood Type',
      labelRhesus: 'Rhesus',
      labelFacebook: 'Facebook',
      labelInstagram: 'Instagram',
      labelTwitter: 'Twitter',
      labelLinkedIn: 'LinkedIn',
      labelDateOfBirth: 'Date of Birth',
      labelDomicile: 'Domicile'
    },
    myVolunteerRegisterScreen: {
      title: 'Register Form',
      labelYourPersonalData: 'Your Personal Data',
      labelName: 'Name',
      labelBloodType: 'Blood Type',
      labelRhesus: 'Rhesus',
      labelAdditionalData: 'Additional Data',
      labelPosition: 'Position',
      labelReason: 'Reason choose this position',
      labelDescription: 'Description',
      labelFillBloodType: 'Please fill your "Blood Type" in your profile',
      labelError: 'Error',
      labelSuccess: 'Success',
      labelSuccessSubmit: 'Cool, your registration has been submitted, please wait for approval',
      labelSuccesRegister: 'Cool, you are now registered to this event',
      labelStart: 'Start:',
      labelEnd: 'End:',
      buttonCaptionGoToProfile: 'Go to Profile',
      buttonCaptionRegister: 'Register'
    },
    approvalCommentScreen: {
      title: 'Approval Comments',
      labelWriteYourComment: 'Write your comment',
      buttonCaptionPostComment: 'Post Comment'
    },
    otpScreen: {
      title: 'OTP',
      labelPleaseInputOtp: 'Please input OTP code that were sent to your {method} {sendTo}',
      labelOtpExpireIn: 'CODE EXPIRES IN:',
      labelOtpExpired: 'CODE EXPIRED',
      labelSuccess: 'Success',
      labelSuccesfullyChanged: '{method} successfully changed',
      labelHaventGotThePin: "Didn\\'t receive OTP Code?",
      labelYouCanResend: 'Request again in',
      labelPhoneNumber: 'Phone Number',
      buttonCaptionResendPin: 'Resend OTP',
      errMsgInvalidOtp: 'Invalid OTP Code'
    },
    newPasswordScreen: {
      title: 'Set Up New Password',
      labelPleaseInputNewPassword: 'Please enter new password',
      labelNewPassword: 'New Password',
      labelReNewPassword: 'Confirm New Password',
      labelNewPasswordSaved: 'New password successfully saved',
      labelSucces: 'Success',
      buttonCaptionLogin: 'LOGIN',
      buttonCaptionUpdate: 'Set Up New Password'
    },
    splashScreen: {
      labelPoweredByMII: 'Powered By NTT'
    },
    bannerScreen: {
      buttonCaptionShare: 'Share',
      labelSeen: 'Seen',
      labelTimes: 'Times',
      labelPeriod: 'Period'
    },
    bannersScreen: {
      title: 'Banners'
    },
    scanQrCodeScreen: {
      title: 'Scan QR Code'
    },
    discussionComponent: {
      buttonCaptionSend: 'Send',
      buttonCaptionCancel: 'Cancel',
      buttonCaptionSeeAll: 'See All',
      buttonCaptionReply: 'Reply',
      buttonCaptionSeeReplies: 'See {replyCount} Replies',
      buttonCaptionHideReplies: 'Hide {replyCount} Replies',
      buttonCaptionSeeMoreReplies: 'See more replies',
      buttonCaptionSeeMoreComments: 'See more comments',
      placeholderWriteYourComment: 'Write your comment',
      labelMinute: 'minutes ago',
      labelHours: 'hours ago',
      labelLike: 'Likes',
      labelSending: 'Sending..',
      labelSortBy: 'Sort By'
    },
    helpCenterScreen: {
      title: 'Help Center',
      labelCallCenter: 'Call Center',
      labelWhatsapp: 'WhatsApp',
      labelEmail: 'Email',
      labelSocialMeda: 'Social Media',
      labelContact:
        'Please contact us for any assistance regarding this app using these channel below:',
      CCHeader: 'Consumer Complaints',
      CCBody1: 'Directorate General of Consumer Protection and Orderly Commerce',
      CCBody2: 'Ministry of Trade of The Republic of Indonesia'
    },
    termsConditionsScreen: {
      title: 'Terms and Conditions'
    },
    privacyPolicyScreen: {
      title: 'Privacy Policy'
    },
    registrationPhoneScreen: {
      title: 'Phone Number Verification',
      labelPhone: 'Mobile Phone Number',
      labelValidatePhoneNumber:
        'Make sure you have inserted the correct phone number and verify it',
      buttonCaptionValidate: 'Verify'
    },
    languageSettingsScreen: {
      title: 'Settings',
      labelAppLanguage: 'Choose Language'
    },
    historyCoin: {
      title: 'Balance History',
      labelCoins: 'Coins Balance',
      labelReferral: 'Referral',
      filterAll: {
        id: 'All',
        label: 'All'
      },
      filterReferral: {
        id: 'Referral',
        label: 'Referral'
      },
      titleTransaction: 'Transaction History',
      labelAmount: 'Amount',
      labelTransactionNo: 'Transaction No',
      labelDate: 'Date',
      titleExpired: 'Expiry',
      messageCopy: 'Transaction number successfully copied',
      coinBalance: 'Coins Balance',
      referral: 'Referral'
    },
    evChargerScreen: {
      title: 'EV Charger',
      tabViewCarInfo: {
        id: 'first',
        label: 'Car & Information'
      },
      tabViewLocation: {
        id: 'second',
        label: 'Location'
      },
      yourLocationAddress: 'Your Location',
      avalaibleKWH: 'Avalaible kWh',
      yourEVCar: 'Your EV Car',
      addNewEVCar: 'Add New EV Car',
      connectYourEVCar: 'Connect your EV car to start charging, powered by myHyundai Indonesia.',
      chargingProses: 'Charging in Progress',
      action: 'Action',
      history: 'History',
      deleteCar: 'Delete Car',
      directionBtn: 'Direction',
      filterAll: {
        id: 'All',
        label: 'All'
      },
      filterNearMe: {
        id: 'nearme',
        label: 'Near Me'
      },
      filterHyundai: {
        id: 'hyundai',
        label: 'Hyundai'
      },
      filterNonHyundai: {
        id: 'nonhyundai',
        label: 'Non Hyundai'
      },
      filterPartner: {
        id: 'partner',
        label: 'partner'
      },
      sort: 'Sort',
      nearestLocation: 'Nearest Location',
      furthestLocation: 'Furthest Location',
      filterShell: {
        id: 'shell',
        label: 'shell'
      },
      labelMenuHome: 'Home',
      labelMenuLocation: 'Location',
      labelMenuHistory: 'History',
      addCar: 'Add Car',
      lastCharging: 'Last Charging',
      stopChargingBtn: 'Stop Charging',
      chargingProcessBtn: 'Charging In Progress',
      labelElapsedTime: 'Elapsed Time',
      labelNA: 'N/A',
      labelOutput: 'Output',
      labelkWh: 'kWh',
      carBrand: 'Car Brand',
      carModel: 'Car Model',
      labelCarBrand: 'Select Car Brand',
      labelCarModel: 'Select Car Model',
      titleHistory: 'History',
      historyEmpty: 'History Not Found',
      invalidURL: 'Invalid Url',
      availableKWH: 'Available kWh',
      labelDeleteCar: 'Delete Car',
      startChargingScanQRBtn: 'Start Charging By Scan QR',
      startChargingBtn: 'Start Charging',
      requestChargingBtn: 'Request To Partner',
      turnOnLocation: 'Turn On Location',
      turnOnLocationMessage:
        "To continue, turn on device location, which uses Google's location service.",
      turnOnLocationCancel: 'No, Thanks',
      turnOnLocationSubmit: 'Ok',
      buttonConfirmationTitle: 'Confirmation',
      buttonConfirmationCancel: 'Cancel',
      buttonAbort: 'No',
      buttonDescriptionDeleteCar: 'Are you sure to delete this EV Car ?',
      buttonDescriptionDeleteCarConfirm: 'Yes, Delete',
      buttonStopTitle: 'Stop Charging',
      buttonStopDescription: 'Do you want to stop charging?',
      buttonStopSubmit: 'Stop Charging',
      buttonCancelTitle: 'Cancel Request',
      buttonCancelDescription: 'Do you want to cancel request?',
      buttonCancelSubmit: 'Cancel Request',
      cancelRequestError: "Can't cancel the request. No transaction found.",
      chargingStoppedKwhLimit: 'Charging is stopped. kWh limit is reached.',
      chargingStopped: 'Charging is stopped.',
      stopSuccessTitle: 'Thank You',
      stopSuccessDescription: 'Your are finished charging your EV',
      stopSuccessCaption: 'See Transaction Detail',
      stationDetailPlace: 'Place',
      stationDetailFloor: 'Floor',
      stationDetailOperationalHours: 'Operational Hours',
      stationDetailYourQuota: 'Your Quota',
      stationDetailAtThisStation: 'At This Station',
      stationDetailKWhLimit: 'kWh Limit',
      stationDetailKWhAtThisStation: 'kWh At This Station',
      stationDetailChargerBox: 'Charger Box',
      stationDetailType: 'Type',
      stationDetailPower: 'Power',
      stationDetailWatt: 'Watt',
      stationDetailAvailable: 'Available',
      startChargingHowToStartQ: 'How To Start Charging ?',
      startChargingHowToStopQ: 'How To Stop Charging ?',
      startChargingReady: 'Cable Plugin, Charging Ready To Start',
      startChargingChargingIsRunning: 'Charging Is Running',
      startChargingStation: 'Charging Station',
      startChargingHowToStart: 'How To Start Charging',
      startChargingHowToStop: 'How To Stop Charging',
      startChargingInformation: 'Charging Information',
      startChargingStartCharging: 'Do You Want To Start Charging?',
      startChargingStopCharging: 'Do You Want To Stop Charging?',
      startChargingCancel: 'Cancel',
      startChargingRequestToPartner: 'Request To Partner',
      startChargingRequestToPartnerDescription: 'Do You Want To Request To Partner?',
      startChargingRequestToPartnerConfirm: 'Yes, Request',
      scanQRCodeInvalid: 'This QR Code Is Invalid',
      locationMenuPermissionTitle: 'Turn On Location',
      locationMenuPermissionMessage:
        "To continue, turn on device location, which uses Google's location service.",
      locationMenuPermissionCancel: 'No, Thanks',
      locationMenuPermissionSubmit: 'Ok',
      locationMenuPermissionDenied: 'Permission Denied',
      locationMenuScanQR: 'Scan QR Code',
      locationMenuMaps: 'Maps Direction',
      listEVGettingLocation: 'Getting Location ...',
      listEVYouAreHere: 'You Are Here',
      listEVResultInput: 'Input Result',
      listEVPlug: 'Plug',
      listEVSpeed: 'Speed',
      listEVRecordEnd: '--- Record End ---',
      listEVNoLocationFound: 'No location found',
      listEVSearchOtherLocation: 'Search other location to find station',
      listEVReset: 'Reset',
      listEVChooseCar: 'Choose Car',
      historyEVFailed: 'Failed to load history filter.',
      historyEVSuccessful: 'Successful',
      historyEVNoMoreData: 'No more data at the moment',
      historyEVChargingDate: 'Charging Date',
      historyEVKWhUsage: 'kWh Usage',
      historyEVKWh: 'kWh',
      historyEVChargingDuration: 'Charging Duration',
      historyEVType: 'Type',
      historyEVPower: 'Power',
      historyEVLocationDetail: 'Location Detail',
      historyEVHour: 'Hour',
      historyEVNotFound: 'History Not Found',
      evCarListCancel: 'Cancel Request',
      evCarListStop: 'Stop Charging',
      currentStation: 'Station',
      currentStationDetail: 'Detail',
      carInfoMenuTopUp: 'Topup',
      carInfoMenuSend: 'Send',
      labelHoursHistoryEv: 'hours',
      topup: 'Topup',
      chooseAmount: 'Choose an amount',
      continue: 'Continue',
      reload: 'Reload',
      paymentOptions: 'Payment Options',
      selectPaymentMethod: 'Select payment method',
      vaInfo: 'Payment with bank virtual account',
      walletInfo: 'Payment with wallet or credit card',
      paymentFeeInfo: 'You will be charged an amount of Rp{0} for the Topup Fee',
      transaction: 'Transaction',
      topupTransactions: 'Topup Transactions',
      trxDetail: 'Transaction Detail',
      paymentInfo: 'Payment Info',
      status: 'Status',
      expiredIn: 'Expired in',
      paymentMethod: 'Payment Method',
      trxId: 'Transaction ID',
      trxDate: 'Transaction date',
      tokenAmount: 'Token Amount',
      topupAmount: 'Topup Amount',
      topupFee: 'Topup Fee',
      grandTotal: 'Grand Total',
      availableToken: 'Available Token',
      vaNumber: 'Virtual account number',
      msgFailedLoadingTopupAmounts: 'Failed to load topup amounts.',
      msgFailedLoadingPaymentMethods: 'Failed to get payment methods.',
      msgFailedProcessingPayment: 'Failed to process payment.',
      processPayment: 'Please wait, we are processing your payment',
      viewTopupTrx: 'See Topup Transaction',
      areYouHyundaiOwner: 'Are you Hyundai EV Car owner?',
      hyundaiEvOwner: 'Hyundai EV Car Owner',
      otherEvBrands: 'Other EV Brands',
      hyundaiEvOwnerDesc: 'Add your Hyundai EV to access exclusive features and benefits',
      otherEvBrandsDesc: 'Another EV brands to get started with universal EV features',
      searchOtherBrand: 'Please search other brand',
      addCarBrandDesc:
        "You can only add one car brand. Once you've added a new brand, you won't be able to change or remove it.",
      confirmAddCarBrand: 'Are you sure to add {0} to EV charger?',
      successAddOtherCarBrand: 'Other car brand added successfully'
    },
    addEvCarScreen: {
      title: 'Add EV Car'
    },
    editProfileScreen: {
      labelFirstName: 'First Name',
      placeholderFirstName: 'First Name',
      labelLastName: 'Last Name',
      placeholderLastName: 'Last Name',
      labelPostalCode: 'Postal Code',
      placeholderPostalCode: 'Postal Code',
      placeholderAddress: 'Address',
      labelAddress: 'Address',
      title: 'Edit Profile',
      addPhoto: 'Add Photo',
      takePhoto: 'Take Photo',
      btnUpdateProfile: 'Update Profile',
      chooseFromGallery: 'Choose from gallery',
      btnDeleteMyAccount: 'Delete My Account'
    },
    changePin: {
      title: 'PIN',
      labelCurrentPin: 'Enter your current PIN',
      labelNewPin: 'Enter your new PIN',
      labelReenterPin: 'Reenter your new PIN',
      labelForgotPin: 'Forgot PIN ?',
      textInfo: 'Your pin is used for payment transactions',
      errCurrentPin: 'Your PIN Not Valid',
      errInvalidPin: 'Invalid OTP Code',
      errMatchPin: 'PIN Not Match',
      msgSuccess: 'PIN Reset Successfully',
      msgUpdateSuccess: 'your PIN has been successfully update',
      msgTitleSuccess: 'Thank you, your PIN Successfully reset',
      goToSetting: 'Go to Setting'
    },
    deleteAccount: {
      title: 'Delete Confirmation',
      desc: 'Do you sure to delete your myHyundai account?',
      confirmText: 'OK, Delete',
      closeText: 'Cancel',
      msgHeaderSuccess: 'Account Delete Successfully',
      msgTitleSuccess: 'Your account has been delete successfully',
      goToLogin: 'Go to Login'
    },
    dailyCheckIn: {
      title: 'Daily Check-In',
      headerTitle: 'Daily Check-In Special for you.',
      programPeriod: 'Program period',
      endsIn: 'Ends in',
      consecutiveMission: 'Consecutive Mission',
      consecutiveMissionDesc: 'Check-in {0} in a row and get interesting rewards!',
      checkIn: 'Check-in',
      checkInTomorrow: 'Check-in Again Tomorrow',
      checkInCompleted: 'Check-in Completed',
      getVouchers: 'Get Vouchers',
      voucher: 'Voucher',
      dayLeft: '{0} day left',
      daysLeft: '{0} days left',
      success: 'Daily check-in success',
      rewardSuccess: 'Completed check-in {0}, get the rewards!'
    },
    shareCar: {
      share: 'Share',
      shared: 'Shared',
      carSharing: 'Car Sharing',
      shareYourCar: 'Share your car',
      shareYourCarDesc: 'You can add new user to enjoy this feature',
      shareCar: 'Share Car',
      shareCarDesc: 'Lets other users to charge your car!',
      addUser: 'Add User',
      updateUser: 'Update User',
      addCarSharing: 'Add Car Sharing',
      deleteUser: 'Delete User',
      searchMemberId: 'Search the person by Member ID',
      searchInput: 'Input member ID',
      userLoading: 'Please wait, loading member...',
      searchLoading: 'Please wait, finding member ID...',
      searchNotFound: 'Member ID not found!',
      memberId: 'Member ID',
      confirmUser: 'Is this the person you are looking for?',
      confirmDeleteUser: 'Are you sure to delete {0}?',
      confirmNo: 'No, Search Again',
      confirmYesContinue: 'Yes, Continue',
      confirmYesShare: 'Yes, Share',
      confirmYesDelete: 'Yes, Delete',
      confirmSubmit: 'Are you sure that you want to share your car to {0}?',
      cancel: 'Cancel',
      sharingType: 'Sharing Type',
      permanent: 'Permanent',
      temporary: 'Temporary',
      from: 'From',
      to: 'To',
      date: 'Date',
      invalidDates: 'Please fill in all dates',
      successShared: 'Successfully Shared',
      successDelete: 'User successfully delete',
      successDesc: 'You have successfully share your car to {0}.',
      errorShareSelf: "You can't share cars with yourself.",
      gotoMyCar: 'Go to My Car'
    },
    transaction: {
      transactionType: 'Transaction Type',
      transactionStatus: 'Transaction Status',
      transactionDate: 'Transaction Date',
      sortNewest: 'Newest',
      sortOldest: 'Oldest',
      sortAsc: 'Ascending',
      sortDesc: 'Descending'
    },
    explore: {
      openInMaps: 'Open In Maps',
      permissionTitle: 'Turn On Location',
      permissionMsg: "To continue, turn on device location, which uses Google's location service.",
      permissionBtnOK: 'Ok',
      permissionBtnCancel: 'No, Thanks',
      filterProvice: 'Province',
      filterCity: 'City',
      sortNearest: 'Nearest',
      sortFurthest: 'Furthest',
      placeholderSearch: 'Search',
      labelGetLocation: 'Getting your location',
      notFoundTitle: 'No Data Available',
      notFoundDesc: 'Explore All Myhyundai Features Now',
      locationFound: '{0} Location Found.',
      locationsFound: '{0} Locations Found.',
      nothingFound: 'Nothing Found.'
    },
    finance: {
      hyundaiFinance: 'Hyundai Finance',
      checkoutServices: 'Checkout Other Hyundai Services',
      testDrive: 'Test Drive',
      buyCar: 'Buy a Car',
      getCreditPackageArea: 'Get Credit Package In Your Area',
      benefits: 'Benefits',
      financingProducts: 'Financing Products',
      seeIllustration: 'See Illustration',
      illustration: 'Illustration',
      getCreditPackage: 'Get Credit Package'
    },
    servicesAccessoriesScreen: {
      servicePromo: 'Service & Promo',
      servicePromoDesc: 'Explore Service & Genuine Accessories Features',
      serviceMyCar: 'Service My Car',
      genAcc: 'Genuine Accessories',
      contactUs: 'Contact Us',
      specialOffer: 'Special Offer',
      specialOfferDesc: 'Explore Service & Genuine Accessories Features',
      title: 'Service & Accessories',
      latestDateService: 'Latest Date Service',
      latestMileage: 'Latest Mileage',
      serviceHistory: 'Service History',
      serviceReminder: 'Service Reminder',
      bookService: 'Book Service',
      bookCarService: 'Book Car Service',
      booking: 'Booking',
      confirmation: 'Confirmation',
      quotation: 'Quotation',
      payment: 'Payment',
      addService: 'Add Service',
      generalRepair: 'General Repair',
      mobileService: 'Mobile Service',
      bodyPaint: 'Body & Paint',
      gotoDealer: 'Visit Dealer',
      pickupOnly: 'Pickup Only',
      deliveryOnly: 'Delivery Only',
      pickupDelivery: 'Pickup & Delivery',
      insurance: 'Insurance',
      cash: 'Cash',
      repairSelection: 'Repair Selection',
      selectRepairSelection: 'Select Repair Selection',
      kmOdometer: 'KM Odometer',
      kmOdometerInfo: 'This KM Odometer Can Only Display For Vehicle With Bluelink Connected',
      service: 'Service',
      addServiceDetail: 'Add Services Detail',
      deliveryMethod: 'Delivery Method',
      selectDeliveryMethod: 'Select Delivery Method',
      intervalSelection: 'Interval Selection',
      note: 'Note',
      generalService: 'General Service',
      accessories: 'Accessories',
      additional: 'Additional',
      total: 'Total',
      pickupLocation: 'Pickup Location',
      addPickupLocation: 'Choose your location',
      serviceLocation: 'Location',
      customerType: 'Customer Type',
      selectCustomerType: 'Select Customer Type',
      insuranceName: 'Insurance Name',
      notesDealer: 'Notes For Dealer',
      dealerLocationDate: 'Dealer Location & Date',
      addDealerLocationDate: 'Add Dealer Location & Date',
      takePhoto: 'Take Photo',
      chooseFromGallery: 'Choose from gallery',
      attachment: 'Attachment',
      addPhoto: 'Add Photo',
      date: 'Date',
      time: 'Time',
      province: 'Province',
      city: 'City',
      detailDealer: 'Detail Dealer',
      generalDetails: 'General Details',
      dealerName: 'Dealer Name',
      bookingService: 'Booking Service',
      estimationComplete: 'Estimation Complete',
      contact: 'Contact',
      otherContact: 'Other Contact',
      name: 'Name',
      phoneNumber: 'Phone Number',
      bookNow: 'Book Now',
      bookBodyPaintConfirmDesc: 'Are You Sure For Booking Body And Paint Repair?',
      cancel: 'Cancel',
      yesBookNow: 'Yes, Book Now',
      summary: 'Summary',
      laborDetail: 'Labor Detail',
      labor: 'Labor',
      hour: 'Hour',
      hours: 'Hours',
      price: 'Price',
      partDetail: 'Part Detail',
      part: 'Part',
      qty: 'Qty',
      pcs: 'Pcs',
      accDetail: 'Accessories Detail',
      subTotal: 'Sub Total',
      ppn: 'Ppn',
      deliveryCharge: 'Delivery Charge',
      promo: 'Promo',
      grandTotal: 'Grand Total',
      totalTime: 'Total Time',
      gotoPayment: 'Go To Payment',
      idOrderService: 'Id Order Service',
      customerName: 'Customer Name',
      totalPrice: 'Total Price',
      totalPriceDesc:
        'Total price is estimated payment, and payment adjustments will be made at the dealer.',
      selectPaymentMethod: 'Select Payment Method',
      processPayment: 'Process Payment',
      enterPin: 'Enter Your Secure Pin',
      problemDetecting: 'Problem Detecting',
      periodicMaintenance: 'Periodic Maintenance',
      serviceCampaign: 'Service Campaign',
      problemDetectingDesc:
        'This feature can only display the information for vehicle with Bluelink',
      status: 'Status',
      dtcCode: 'Dtc Code',
      detectedDate: 'Detected Date',
      description: 'Description',
      notFixed: 'Not Fixed',
      fixed: 'Fixed',
      discount: 'Discount',
      estimationTime: 'Estimation Time',
      periodicMaintenanceDesc: 'The prices is estimate, may very depend on chosen dealer.',
      repairSuggestion: 'Repair Suggestion',
      parts: 'Parts',
      items: 'Items',
      createdDate: 'Created Date',
      estimationPrice: 'Estimation Price',
      estimationTotalPrice: 'Estimation Total Price',
      estimationTotalTime: 'Estimation Total Time',
      repairSuggestionDesc: 'The prices is estimate, may very depend on chosen dealer.',
      repairSuggestionConfirm: 'Are You Sure?',
      repairSuggestionConfirmDesc: 'Book now for service your car.',
      subject: 'Subject',
      code: 'Code',
      validFrom: 'Valid From',
      validTo: 'Valid To',
      onProgress: 'On Progress',
      history: 'History',
      titlePaymentInfo: 'Payment Information',
      labelOrderId: 'Order Id',
      labelExpired: 'Expired',
      labelWaitPayment: 'Waiting For Payment',
      labelPaid: 'Paid',
      labelExpiredIn: 'Expired In',
      lebelMinutes: 'Minutes',
      labelSeconds: 'Seconds',
      labelPaymentMethod: 'Payment Method',
      labelTransId: 'Transaction Id',
      labelVANumber: 'Virtual Account Number',
      labelHowToPay: 'How To Pay',
      labelReschedule: 'Reschedule',
      labelCancelation: 'Cancelation',
      labelAvailableDealer: 'Available Dealer',
      infoNotAvailable: 'Sorry, No slot available. Please select other date or other dealer.',
      labelSeeDelalerDetail: 'See Dealer Detail',
      labelPaymentSucceed: 'Payment Succeed',
      labelPaymentDate: 'Payment Date',
      infoPayment:
        'If you make a payment using an e-wallet or a credit/debit card, you have completed your payment',
      infoPayment1: 'If you made a payment via bank transfer, please continue the payment process.',
      labelPaymentFailed: 'Payment failed',
      labelReqTimeOut: 'Request Time Out!',
      msgCancelAppointment: 'Cancel Appointment Successfully',
      descPaymentNotFound: 'Payment Was Not Found',
      labelBookingDate: 'Booking Services Date',
      titleDisclaimer: 'Disclaimer!',
      descDisclaimer: 'Maximal Reschedule Is 1X24 Hour Before Services.',
      confirmReschedule: 'Ok, Reschedule',
      labelOrderService: 'Order Service',
      labelServicesInfo: 'Services Info',
      labelDuration: 'Time Duration',
      labelScheduleDetail: 'Schedule Detail',
      labelRescheduleDate: 'Reschedule Date',
      labelAddToCart: 'Add To Cart',
      labelMyCart: 'My Cart',
      cartNotFound: 'My Cart is still empty',
      descCartNotFound: 'Browse our awesome accessories now!',
      labelCheckOut: 'Check Out',
      labelHelpCenter: 'Help Center',
      textNotsubscribtion: "You don't have any subscription",
      btnBuyVoucher: 'Buy Voucher',
      textIsSubscribtion: 'You have subscribed to Pick Up & Delivery Service',
      btnSubscription: 'Subscribe',
      titleTypeSubscription: 'Type',
      textVoucherSubscription: 'Voucher',
      titleSuccessSubscription: 'Subscription Success',
      descSuccessSubscription: 'You can find your active vouchers in My Rewards page.',
      btnSuccessSubscription: 'Back to car service',
      viewSubscription: "You don't have any subscription.",
      btnSeeVoucher: 'See Voucher',
      infoSubscriptionTitle: 'Information',
      infoSubscriptionDesc:
        'You dont have any subscription for this car. Subscribe to our Pick Up & Delivery services and discover a more convenient way to service your car!',
      infoSubscriptionCancel: 'Cancel',
      infoSubscriptionSubscribe: 'Subscribe',
      btnReedemVoucher: 'Redeem Voucher',
      paymentFeeInfo: 'You will be charged an amount of {0} for the admin fee',
      paymentFeePercentInfo:
        'You will be charged an amount of {0} of the total transaction for the admin fee',
      createBooking: 'Create Booking'
    },
    verifyCarOwner: {
      title: 'Verify Car Ownership',
      labelSubTitle: 'As the legal owner of a Hyundai vehicle with detailed data as follows.',
      labelProfile: 'Profile',
      labelCarDetail: 'Car Details',
      labelOtherDoc: 'Other Document',
      labelSalutation: 'Salutation',
      labelFullName: 'Full Name',
      labelDateOfBirth: 'Date Of Birth',
      labelCountryCode: 'Country Code',
      labelPhone: 'Phone Number',
      labelEmail: 'Email',
      labelProvince: 'Province',
      labelCity: 'City',
      labelDistrict: 'District',
      labelPostalCode: 'Postal Code',
      labelAddress: 'Address',
      labelVINNumber: 'Vin (Vehicle Identity Number)',
      labelModel: 'Model',
      labelTrim: 'Trim',
      labelColor: 'Color',
      labelYear: 'Year',
      labelCarRegisNm: 'Car Registration Letter Name',
      placeholderCarRegisNm: 'Input Car Registration Letter Name',
      labelSTNK: 'Stnk/Stck Name',
      labelCarRegisDate: 'Car Registration Letter Valid Date',
      labelSTNK_Date: 'Stnk/Stck Name Valid Date',
      labelCarRegisNum: 'Car Registration Letter Number',
      placeholderCarRegisNum: 'Input Car Registration Letter Number',
      labelSTNK_Num: 'Stnk/Stck Number',
      labelLicensePlateNumb: 'License Plate Number',
      placeholderLicensePlateNumb: 'E.G. H 1234 Idn',
      labelCarRegisPhoto: 'Car Registration Letter Photo (Optional)',
      labeleSlectPhoto: 'Select A Photo',
      labelSTNK_Poto: 'Stnk/Stck Photo',
      labelKTP_Photo: 'Identity Card Photo (Ktp/Kitas)',
      labelOwner_Proof: 'Ownership Proof (Optional)',
      labelCarUnderCompanyName: 'This car under company name ?',
      labelFooter:
        'Thus, This Vehicle Ownership Statement Is Filled Out With Accurate Data In A Conscious, Voluntary And Without Coercion State. And By Checking The “Agree” Mark, I Declare That I Am The Vehicle’S Legal Owner From The Vin (Vehicle Frame) Number As Stated In This Form.',
      labelAgree: 'I Agree',
      labelEmployeeIDPhohto: 'Employee Id Photo',
      labelBusinessCardPhoto: 'Business Card Photo (Optional)',
      labelSubTitleValide:
        'Please insert the registered profile according to the purchase form you have when you purchase the car.',
      placeholderModel: 'Select Model',
      placeholderTrim: 'Select Trim',
      placeholderColor: 'Select Color',
      placeholderYear: 'Select Year',
      successRegisTitle: 'Registration Successfully',
      successWaitingTitle: 'Waiting For Approval',
      successRegisSubTitle: 'Your car is successfully registered',
      successWaitSubTitle: 'Your car registration is Pending',
      buttonTitle: 'Go To My Car',
      validationFormTitle: 'Validation Form',
      LicensePlatePlaceholder: 'E.G. H1234Idn'
    },
    exclusiveDealScreen: {
      labelTabBenefits: 'Member Benefits',
      labelTabRewards: 'New Car Rewards',
      confirmTitle: 'Confirmation',
      confirmDesc: 'Are you sure want to choose',
      confirmYes: 'Yes, Choose',
      confirmCancel: 'Cancel',
      labelButtonAddThisPackeage: 'Add This Package',
      labelButtonDetail: 'See Details',
      labelPackageByCategory: 'Package By Category',
      labelPackageByBrand: 'Package By Brand',
      labelNotFoundTitle: 'No package available',
      labelNotFoundDesc: 'Package just available for special car',
      labelNotFoundTitle1: 'No Result Found',
      labelNotFoundCategory: 'Package By Category was not found',
      labelNotFoundBrand: 'Package By Brand was not found',
      labelButtonChoosed: 'You already choose this package',
      labelPackage: 'Package ?',
      titleyourPackage: 'Your Package',
      labelButtonSeeBenefits: 'See Benefits',
      labelDetailBenefits: 'Detail Benefits',
      labelBestChoice: 'Best Choice',
      titleExclusiveBenefits: 'Exclusive Benefits',
      buttonChoosePackage: 'Choose Package',
      labelNotFoundSelected: 'No package selected',
      labelNotFoundDescSelected: 'Choose your package, to get special offer.',
      labelWaitLoading: 'Please wait, loading...'
    },
    updateStnkScreen: {
      title: 'Update Data Stnk/Stck',
      labelLicense: 'License Plate Number',
      labelCarRegisName: 'Car Registration Letter Name',
      stnkName: 'Stnk/Stck Name',
      placeHolderRegisName: 'Input Car Registration Letter Name',
      labelCarRegisDate: 'Car Registration Letter Valid Date',
      placeHolderRegisDate: 'Dd-Mm-Yy',
      stnkDate: 'Stnk/Stck Name Valid Date',
      labelCarRegisNumber: 'Car Registration Letter Number',
      stnkNumber: 'Stnk/Stck Number',
      placeHolderRegisNumber: 'Input Car Registration Letter Number',
      labelCarRegisPhoto: 'Car Registration Letter Photo',
      stnkPhoto: 'Stnk/Stck Photo',
      btnSave: 'Save',
      titleModal: 'Upload Photo',
      labelTakePhoto: 'Take Photo',
      labelGalery: 'Choose From Gallery',
      infoStnkName: 'Stnk/Stck Name',
      infoStnkDate: 'Stnk/Stck Name Valid Date',
      infoStnkNumber: 'Stnk/Stck Number',
      infoStnkFoto: 'Stnk/Stck Photo'
    },
    myCarScreen: {
      title: 'My Car',
      labelVinNumber: 'Vin Number',
      labelModelYear: 'Model Year',
      labelNotes: 'Notes',
      labelEngineNumber: 'Engine Number',
      labelPurchaseDate: 'Purchase Date',
      modalHeadRegis: 'Register A Car',
      textModalVin:
        'VIN Number is the same as your car (chassis number), length must be 17 characters.',
      buttonModalRegis: 'Validate',
      buttonCaptionCS: 'Myhyundai Customer Care',
      labelCaptionCsPhone: 'Call Customer Care',
      labelCaptionCsWa: 'Whatsapp Customer Care',
      headerLabelDetailCar: 'Car Detail',
      myCarTitle: 'No Registered Car Yet',
      myCarDesc: 'Get special perks and various benefits when you registered a Hyundai Car',
      buttonMyCar: 'Register A Hyundai Car',
      otherInfomationDetailCar: 'Other Information',
      carWarrantyDetailCar: 'Car Warranty',
      batteryWarrantyDetailCar: 'Battery Warranty',
      carRegistrationLetterDetailCar: 'Car Registration Letter',
      modalHeadDetailCar: 'Registration Data',
      licensePlateNumberDetailCar: 'License Plate Number',
      carRegisStnkNameDetailCar: 'Car Registration Letter (Stnk/Stck) Name',
      carRegisStnkDateDetailCar: 'Car Registration Letter (Stnk/Stck) Valid Date',
      carRegisStnkNumberDetailCar: 'Car Registration Letter (Stnk/Stck) Number',
      carRegisStnkFotoDetailCar: 'Car Registration Letter Photo',
      buttonUploadModalDetailCar: 'Update Data',
      modalInfoTItle1: 'Vin Not Found',
      modalInfoDesc1: 'Sorry, vehicle with VIN',
      modalInfoDescNext:
        'cannot be found on our system. Do you want to continue registering this VIN number ?',
      modalInfoConfirm1: 'Yes',
      modalInfoClose1: 'No',
      modalInfoTItle2: 'Verify Ownership',
      modalInfoDesc2: 'Verify your ownership by submitting information in your purchase form.',
      modalInfoConfirm2: 'I’ll Verify',
      modalInfoClose2: 'Maybe Later',
      modalInfoTItle3: 'Are your sure?',
      modalInfoDesc3: 'You are missing out a chance to get remarkable rewards.',
      modalInfoConfirm3: 'I’ll Verify',
      modalInfoClose3: 'Yes',
      modalInfoRegisTItle: 'Cancel Registration',
      modalInfoRegisDesc: 'Are you sure cancel this car registration ?',
      modalInfoRegisConfirm: 'Yes, Cancel',
      modalInfoRegisClose: 'No',
      labelIsYourCar: 'Is This Your Car?',
      textMoreInfo: 'More Information',
      modalCancelSucces: 'Your car registration successfully cancel',
      labelModalCarDetail: 'Registration Data',
      buttonConatctCS: 'Contact Customer Services',
      buttonCancelRegis: 'Cancel Registration',
      labelTabRegisStatus: 'Registration Status',
      filterAll: 'All',
      filterPending: 'Pending',
      filterApproved: 'Approved',
      labelManualBook: 'Manual Book',
      labelBookServices: 'Book Service',
      labelDeliveryDate: 'Delivery Date',
      carOwnershipData: 'Car Ownership Data'
    },
    servicesDetail: {
      title: 'Service Detail',
      serviceInfoTitle: 'Service Info',
      statusInfo: 'Status',
      bookingInfoCode: 'Booking Code',
      invoiceInfo: 'Invoice No.',
      bookingInfoDate: 'Booking Service Date',
      receiveInfoDate: 'Receive Date',
      repairInfo: 'Repair Selection',
      deliveryInfo: 'Delivery Method',
      platInfo: 'License Plate',
      oddoMeterInfo: 'Registered Odometer',
      detailDealerTitle: 'Detail Dealer',
      dealerName: 'Dealer Name',
      dealerPhone: 'Dealer Phone',
      servicesAdvisorName: 'Service Advisor Name',
      detailServicesTitle: 'Detail Service',
      laborDetail: 'Labor Detail',
      labor: 'Labor',
      hour: 'Hour',
      price: 'Price',
      partDetail: 'Part Detail',
      part: 'Part',
      qty: 'Qty',
      accDetail: 'Accessories Detail',
      bookingCode: 'Booking Code',
      noInvoice: 'Invoice No.',
      bookingDate: 'Booking Service Date',
      receiveDate: 'Receive Date',
      repairSelection: 'Repair Selection',
      deliveryMethod: 'Delivery Method',
      platNumber: 'License Plate',
      odoMeter: 'Registered Odometer',
      subTotal: 'Sub Total',
      ppn: 'Ppn 11%',
      total: 'Total',
      promo: 'Promo',
      grandTotal: 'Grand Total',
      totalTime: 'Total Time',
      deliveryCharge: 'Delivery Charge',
      event: 'Event',
      repairNumber: 'Repair Number'
    },
    partnerDealsScreen: {
      title: 'Rewards Redemption',
      sortAsc: 'Ascending By Partner Name',
      sortDesc: 'Descending By Partner Name',
      sortHigh: 'Highest Coins',
      sortLow: 'Lowest Coins',
      notFoundTitle: 'No Result Found',
      notFoundDesc: 'Please Search Other Partner Deals',
      titleLoadingSearch: 'Please Wait, Searching...',
      noMoreData: 'No more data at the moment',
      titleRenderEmpty: 'No Result Found',
      descRenderEmpty: 'Please search other partner deals',
      tabInformation: 'Information',
      tabTC: 'T & C',
      btnReedem: 'Redeem',
      modalInfoTitle: 'Confirmation',
      modalInfoDesc: 'Are you sure want to redeem this voucher ?',
      modalInfoConfirm: 'Yes',
      modalInfoCancel: 'No',
      succesInfoTitle: 'Redeem Success',
      succesInfoDesc: 'You can find your active vouchers in My Rewards page.',
      succesInfoCaption: 'See My Rewards',
      succesInfoBack: 'Back To Partner Deals',
      titleDetailDeals: 'Detail Deals',
      detailDealsCoins: 'Coins',
      detailDealsReedem: 'Redeem',
      detailDealsPeriod: 'Deals Period',
      detailDealsYourCoins: 'Your Coins'
    },
    myRewardsScreen: {
      title: 'My Rewards',
      tabActive: 'Active',
      tabClaimed: 'Claimed & Expired',
      buttonUseVoucher: 'Use Voucher',
      confirmTitle: 'Are You Sure',
      confirmDesc:
        'Make sure you already use the voucher! If you mark this voucher as used, you will not be able to see the voucher again.',
      confirmDesc1: 'Vouchers can be exchanged at the cashier.',
      confirmDesc2: 'Your Voucher Code :',
      buttonCancel: 'Cancel',
      buttonYes: 'Yes',
      confirmTitlePackage: 'Confirmation',
      confirmDescPackage: 'Are you sure want to choose Family Package ?',
      confirmDescPackage1: 'You cannot change or choose other package on feature process.',
      buttonYesPackage: 'Yes, Choose',
      buttonCancelPackage: 'Cancel',
      confirmDescOthers: 'Are you sure want to redeem this voucher ?'
    },
    successScreen: {
      titleConfirmation: 'Success',
      titleRedeemSuccess: 'Redeem Success',
      titleMember: 'Congratulation',
      titlePackage: 'Choose Package Success',
      titleDetailDeals: 'Claim Voucher Successfully',
      labelSuccessfully: 'Successfully',
      descConfirmation: 'Body And Paint Success For Booking.',
      descRedeem: 'You can find your active vouchers in My Rewards page.',
      descMember: 'Your are black member now',
      descDetailDeals: 'The voucher move to Claim & Expired',
      captionBtnConfirmation: 'See Progress',
      captionBtnRedeem: 'Go To My Rewards',
      captionBtnMember: 'Ok, Go To Homepage',
      captionBtnDetailDeals: 'Go To Claimed & Expired',
      captionBtnRedeem1: 'Back To Exclusive Benefits'
    },
    whatsOnScreen: {
      categoryHighlight: 'Highlight',
      categoryTrending: 'Trending',
      categoryAll: 'All Categories'
    },
    bluelinkScreen: {
      lastUpdated: 'Last Updated',
      fuel: 'Fuel',
      distanceToEmpty: 'Distance To Empty',
      km: 'KM',
      engine: 'Engine',
      climateControlStatus: 'Climate Control Status',
      doorStatus: 'Door Status',
      others: 'Others',
      vehicleHealthReport: 'Vehicle Health Report',
      informYouRegular: 'Inform you of the regular vehicle inspections results.',
      receivingVehicleStatus: 'Receiving Vehicle Status.',
      batteryStatus: 'Battery Status',
      connected: 'Connected',
      locked: 'Locked',
      Unlocked: 'Unlocked',
      detail: 'Detail',
      opened: 'Opened',
      closed: 'Closed',
      normal: 'Normal',
      warning: 'Warning',
      airConditioning: 'Air Conditioning',
      windscreenDefogger: 'Windscreen Defogger',
      tailgate: 'Tailgate',
      bonnet: 'Bonnet',
      door: 'Door',
      battery: 'Battery',
      odometer: 'Odometer',
      totalDistanceTravelled: 'Total Distance Travelled',
      numberEngineStarts: 'Number Of Engine Starts',
      times: 'Times',
      totalOnTime: 'Total - On Time',
      totalDrivingTime: 'Total Driving Time',
      totalIdleTime: 'Total Idle Time',
      vehicleStatus: 'Vehicle Status',
      airbagControlSystem: 'Airbag Control System',
      tirePressureMonitorySystem: 'Tire Pressure Monitory System',
      driverAssistanceSystem: 'Driver Assistance System',
      integratedBrakingBody: 'Integrated Braking And Body Control System',
      reportHistory: 'Vehicle Health Report History',
      receivingVehicleReport: 'Receiving Vehicle Report.',
      charging: 'Charging',
      notPluggedIn: 'Not Plugged In'
    },
    trxHistAirPortScreen: {
      title: 'Airport Vip Assistance',
      msgRescheduleFailed: 'Reschedule Booking  Airport Vip Assistance Failed',
      msgCancelFailed: 'Cancel Booking Airport Vip Assistance Failed'
    },
    trxHistCityLoungeScreen: {
      msgRescheduleSuccess: 'Reschedule Booking Successfully',
      msgRescheduleFailed: 'Reschedule Booking City Lounge Failed',
      msgCancelBooking: 'Cancel Booking Successfully',
      msgCancelFailed: 'Cancel City Lounge Failed',
      title: 'City Lounge',
      labelTermCondition: 'Terms & Condition'
    },
    trxHistEVScreen: {
      labelChargingDate: 'Charging Date',
      labelKWHUsage: 'kWh Usage',
      labelChargingDuration: 'Charging Duration',
      labelPlugType: 'Plug Type',
      labelSpeed: 'Speed',
      labelLocationDetail: 'Location Detail',
      labelPlace: 'Place',
      labelFloor: 'Floor',
      labelOperatioHours: 'Operation Hours',
      titleEVCharging: 'Ev Charging',
      descNotFound: 'Transaction EV Charging was not found'
    },
    trxHistGenAccScreen: {
      labelTotalPayment: 'Total Payment',
      labelPurchaseDetail: 'Purchasedetail',
      labelDetailPayment: 'Detail Payment',
      labelCancelOrder: 'Cancel Order',
      msgCancelSuccess: 'Transaction Successfully Canceled',
      labelInvoice: 'Invoice',
      confirmCancelDesc: 'Are you sure to cancel this order?'
    },
    trxHistLoungeScreen: {
      labelLoungeAirport: 'Lounge & Airport Vip Assistance',
      labelType: 'Type',
      labelBookingDetail: 'Booking Detail',
      labelLounge: 'Lounge',
      labelMeeting: 'Meeting Point & Time',
      buttonCancel: 'Cancel',
      buttonReschedule: 'Reschedule',
      confirmResTitle: 'Confirmation',
      confirmResDesc: 'Are your sure reschedule your booking at',
      confirmCancelDesc: 'Are your sure cancel your booking at',
      confirmCancelOK: 'Ok, Cancel'
    },
    trxHistParking: {
      title: 'Parking & Pickup',
      msgRescheduleFailed: 'Reschedule Booking Escort Failed',
      msgCancelFailed: 'Cancel Booking Escort Failed',
      labelCheckIn: 'Checkin',
      labelCheckOut: 'Checkout'
    },
    trxHistVoucherScreen: {
      labelDeelsPeriod: 'Deels Period'
    },
    idMigration: {
      title: 'Id Migration Process',
      labelSearchYourAccount: 'Search Your Account',
      labelSearchResults: 'Search Results',
      labelChooseIDToUse: 'Choose Id To Use',
      errMessageNext: 'Please select an account',
      loadingDesc: 'Please wait, waiting for the process to be finished...',
      titleCancelIdMigration: 'Cancel Integration Process',
      descCancelIdMigration: 'Are you sure to cancel all process Hyundai One ID?',
      labelYesCancelIdMigration: 'Yes',
      labelNoCancelIdMigration: 'No, Continue',
      labelHi: 'Hi, ',
      labeldescIdMigration:
        'We will connect your all Hyundai accounts to become one, please input your valid phone number to start migration.',
      labelPhoneNumber: 'Phone Number',
      labelBtnPleaseWait: 'Please Wait',
      labelBtnContinue: 'Continue',
      titleOtpCode: 'Otp Code Verification',
      descOtpCodeA: 'A code is sent to your phone number',
      descOtpCodeB: 'Please check your inbox and copy the OTP Code here.',
      btnVerifyOtp: 'Verify Otp',
      labelOtpExpiredIn: 'OTP expired in',
      labelMin: 'Min',
      labelSecond: 'Second',
      textDidntReceiveCode: "Didn't recieve code?",
      textResendAnOtpCodeIn: 'Resend An Otp Code In',
      btnResendOtp: 'Resend Otp',
      labelPleaseWait: 'Please Wait',
      labelVerifyOtp: 'Verify Otp',
      labelWelcomeTo: 'Welcome To',
      labelHyundaiOneId: 'Hyundai One Id',
      labelSignIn: 'Sign In',
      descSignIn:
        'Sign in using your email, you can also use "Bluelink" or "Click To Buy" account to continue.',
      placeholderEmailAddress: 'Email Address',
      labelLoadingPleaseWait: 'Please Wait...',
      btnFindID: 'Find Id',
      labelNotMember: 'Not A Member?',
      btnRegister: 'Register',
      labelForgotYourId: 'Forgot Your Id?',
      descForgotYourId: 'Please filled the form below to get your ID.',
      labelPhoneNumberMandatory: 'Phone Number *',
      labelVerificationCodeMust: 'Verification code must be filled in 5 minutes.',
      labelNotYetReceiveVerificationCode:
        'If your not yet receive verification code or get limit time, please click “Resend Code”.',
      labelBtnSendVerificationCode: 'Send Verification Code',
      labelResendCodeIn: 'Resend Code In',
      placeholderVerificationCode: 'Verification Code',
      labelBtnSubmit: 'Submit',
      labelSuccess: 'Success',
      descPleaseFindYourID: 'Please find your ID to access your account.',
      btnGotoLogin: 'Go To Login',
      errUserWasNotFound: 'The user was not found',
      infoFoundSomeEmails: 'We found some emails that use email:',
      infoAndMobilePhone: 'and mobile phone:',
      infoPleaseVerifyAdditionalAccount:
        'Please verify additional account to integrate, you can choose one email ID per platform.',
      infoLetsHighlightOneId:
        'Let\'s highlight "one ID" per platform. All of the benefits from each myHyundai ID you verified will be combined with your Hyundai One ID.',
      labelPlatform: 'Platform',
      infoAccountPlatformNotFound: 'Account for this platform not found!',
      infoDoYouUserEmailForOtherPlatforms: 'Do you user email for other platforms ?',
      labelSearchAdditionalAccount: 'Search Additional Account',
      labelVerified: 'Verified',
      titleDeleteEmailID: 'Delete Email Id',
      descDeleteEmailID: 'Are you sure to delete additional account ?',
      btnNo: 'No',
      btnYesDelete: 'Yes, Delete',
      labelAddAdditionalAccount: 'Add Additional Account',
      titleAddAccountConfirmation: 'Add Account Confirmation',
      descAddAccountConfirmation: 'Are you sure to continue add account ?',
      btnYesAddAccount: 'Yes, Add Account',
      errFailedToAddYourAccount: 'Failed To Add Your Account',
      titleCancelAddingAccount: 'Cancel Adding Account',
      descCancelAddingAccount: 'Are you sure to cancel searching for additional accounts?',
      btnYesCancel: 'Yes, Cancel',
      labelVerificationSucceded: 'Verification Succeded',
      labelPLeaseChooseAccountsToIntegrate: 'Please choose accounts to integrate.',
      labelMyHyundai: 'Myhyundai',
      labelClickToBuy: 'Click To Buy',
      labelBluelink: 'Bluelink',
      labelCancelAdditionalAccount: 'Cancel Additional Account',
      labelAddSearchedAccount: 'Add The Searched Account',
      labelNotVerified: 'Not Verified',
      descEmailA: 'A code is sent to your email',
      descEmailB: '. Please check your inbox and copy the OTP Code here.',
      btnOk: 'Ok',
      labelInsertYourEmailOrPhone: 'Insert your email or phone number to search',
      placeholderEmailOrPhoneNumber: 'Email Or Phone Number',
      labelSearch: 'Search',
      labelPleaseChooseRepresentativeEmailForLogin:
        "Please choose a representative e-mail to use for log-in. This e-mail address will be your ID for all Hyundai's digital platforms - MyHyundai / Bluelink / Click to Buy. Also, all service data under the following e-mail addresses will be automatically connected to your ID.",
      labelYouCanAlsoUseIDFromPlatformCreateHyundaiOneID:
        'You can also use an ID from one Platform to create Hyundai One ID. This process will only be conducted once and you will not be required to redo this process again.',
      labelCongratulations: 'Congratulations',
      labelYouHaveFinishedIDMigrationProcess: 'You have finished the ID Migration process',
      labelYears: 'Years',
      labelYear: 'Year',
      labelMonth: 'Month',
      labelDay: 'Day',
      labelMakeAProfile: 'Make A Profile',
      labelYouAreCurrentlyMigratingBluelinkID:
        'You are currently migrating a Bluelink ID, you will be able to change your profile information later after the Migration process.',
      labelFullName: 'Full Name',
      placeholderYourFullName: 'Your Full Name',
      errYourNameIsRequired: 'Your Name Is Required',
      placeholderDOB: 'Dob',
      labelDateOfBirth: 'Date Of Birth',
      placeholderSelectADate: 'Select A Date',
      placeholderPhoneNumber: 'Phone Number',
      errPhoneNumberRequired: 'Your Phone Number Is Required',
      labelEmail: 'Email',
      placeholderEmail: 'Email',
      labelCreateNewPassword: 'Create New Password *',
      placeholderPassword: 'Password',
      labelText: 'Text',
      infoUppercaseLowercaseNumberSpecialCharacter:
        'Your password needs to be stronger. It must have at least 8 characters, including 1 number.',
      labelConfirmPassword: 'Confirm Password *',
      placeholderConfirmPassword: 'Confirm Password',
      labelPasswordDoesntMatch: "Password Doesn'T Match",
      labelPIN: 'Pin *',
      placeholderPIN: 'Pin - 4 Digits Number',
      labelInput4DigitsPIN: 'Input 4 Digits Pin',
      labelConfirmPIN: 'Confirm Pin *',
      placeholderConfirmPIN: 'Confirm Pin',
      errConfirmPIN: "Pin Doesn'T Match",
      labelIAgreeTo: 'I Agree To',
      labelPrivacyPolicy: 'Privacy Policy',
      labelMyHundaiIndonesia: 'Myhyundai Indonesia',
      labelWeFoundSomeAccountsThatUsingEmail: 'We found some accounts that using email',
      labelPleaseVerifyAdditionalAccountToIntegrate: 'Please verify additional account to integrate'
    },
    'idMigration ': {
      labelPrivacyPolicy: 'Privacy Policy'
    },
    shareCoins: {
      coinsDetail: 'Coins Detail',
      shareCoins: 'Share Coins',
      shareSuccess: 'Successfully Shared',
      shareSuccessDesc: 'You have successfully share your {0} coins to {1}.',
      goHome: 'Go to Home',
      shareConfirmDesc: 'Are you sure that you want to share your {0} coins to {1}?',
      yesShare: 'Yes, Share',
      shareFailedNoMember: 'Cannot share coins. No member selected.',
      shareFailed: 'Failed to share coins.',
      share: 'Share',
      cancel: 'Cancel',
      searchNotFound: 'Member ID not found!',
      errorShareSelf: "You can't share coins with yourself.",
      searchMemberId: 'Search the person by Member ID',
      searchInput: 'Input member ID',
      searchLoading: 'Please wait, finding member ID...',
      memberId: 'Member Id',
      confirmUser: 'Is this the person you are looking for?',
      confirmNo: 'No, Search Again',
      confirmYesContinue: 'Yes, Continue',
      coinsBalance: 'Coins Balance',
      amountToShare: 'Amount to share',
      shareFailedInsufficientAmount: 'You have insufficient coins balance'
    },
    testDrive: {
      title: 'Test Drive',
      'labelSwitch ': 'This Data For Other Person?',
      labelInstagram: 'Instagram (optional)',
      labelCarModel: 'Car Model',
      labelLicence: 'Driver Licence',
      titleReffCode: 'Do You Have Referral Code ?',
      labelSales: 'Sales Consultant',
      labelReffCode: 'Referral Code  (optional) ',
      labelEvent: 'Event',
      titleSelectCity: 'Select City',
      titleSucces: 'Congratulation',
      descSucces: 'Your Test Drive Booking Has Been Created',
      BtnSucces: 'See Detail Transaction',
      labelAddOther: 'Add Other',
      textInstagram: 'Instagram',
      btnComplete: 'Complete',
      titleModalCancel: 'Cancel Test Drive',
      descModalCancel: 'Are You Sure To Cancel This Test Drive ?',
      cancelSucces: 'Test Drive successfully canceled',
      TitleCompleteDrive: 'Complete Test Drive',
      textVenue: 'Venue',
      labelSelfie: 'Selfie with Car',
      labelRating: 'Rating',
      modalCompleteTitle: 'Test Drive Completed',
      modalCOmpleteDesc: 'Add Your Survey Now To Completed Your Test Drive',
      modalCompleteBtn: 'Add Survey Now',
      warnSuccesComplete: 'Test Drive Has Been Completed',
      titleAddSurvey: 'Add Survey Now'
    },
    questionnaire: {
      title: 'Questionnaire',
      select: 'Select',
      termsCondition: 'Terms and Condition',
      term1:
        'I consent to the use of data for Hyundai Service Point reservation activities by the EO appointed by Hyundai Motors Indonesia',
      term2: 'I am willing to take part in customer satisfaction surveys',
      term3:
        'I am willing to receive information from Hyundai Motors Indonesia regarding products and services',
      term4: 'For more information please check',
      term5: 'Privacy Policy',
      term6: 'I agree to the Terms and Conditions applicable to Vehicle Service',
      answer: 'Answer',
      titleQues: 'Questionnaire',
      check1: 'You have completed the Questionnaire',
      check2: 'You have accepted our Terms and Condition',
      check3:
        'Kindly complete the questionnaire and accept our Terms and Conditions prior to proceeding with the service booking.',
      quesSuccesTitle: 'Questionnaire Submitted',
      quesSuccesDesc: 'Thank you for participating',
      quesSuccesBtn: 'Service Booking Event'
    },
    serviceBookEvent: {
      captionBtnBookEvent: 'Continue',
      titleBookEvent: 'Service Booking Event',
      regisHyundaiCarNo: 'No Registered Car Yet',
      regisHyundaiCarNoDesc:
        'Get special perks and various benefits when you registered a Hyundai Car',
      regisHyundaiTitle: 'Register a Hyundai Car',
      regisNonHyundaiTitle: 'Register Non-Hyundai Car',
      licensePlate: 'License Plate',
      vin: 'Vin',
      errVin: 'This field cannot contain white space and special character',
      modelYear: 'Model Year',
      carBrandTitle: 'Car Brand',
      carBrandSelect: 'Select Car Brand',
      carBrandSelectErr: 'Please select Car Brand',
      carModelTitle: 'Car Model',
      carModelSelect: 'Select Car Model',
      carModelSelectErr: 'carModelSelectErr',
      addCarNonHyundaiSuccesTitle: 'Register Succesfully',
      addCarNonHyundaiSuccesDesc: 'Your car is successfully registered',
      btnMyCarList: 'My Car List',
      event: 'Event',
      vehicle: 'Vehicle',
      repairSelection: 'Repair Selection',
      kmOdometer: 'Odometer KM',
      OrText: 'Or'
    }
  },
  ID: {
    common: {
      alertTitleInfo: 'INFO',
      alertTitleError: 'Oops...',
      alertTitleWarning: 'PERINGATAN',
      alertTitleConfirmation: 'KONFIRMASI',
      alertTitleCameraPermission: 'Izin menggunakan kamera',
      buttonCaptionOK: 'OK',
      buttonCaptionCancel: 'BATAL',
      buttonCaptionDetail: 'DETAIL',
      buttonCaptionYes: 'YA',
      buttonCaptionNo: 'TIDAK',
      buttonCaptionSave: 'SIMPAN',
      buttonCaptionEdit: 'UBAH',
      buttonCaptionSubmit: 'KIRIM',
      buttonCaptionNext: 'LANJUT',
      buttonCaptionPrev: 'SEBELUMNNYA',
      buttonCaptionBack: 'KEMBALI',
      buttonCaptionClose: 'TUTUP',
      buttonCaptionReset: 'Atur Ulang',
      buttonCaptionApply: 'Terapkan',
      buttonCaptionSelectAll: 'Pilih Semua',
      buttonCaptionDeselectAll: 'Hapus Semua Pilihan',
      buttonCaptionClear: 'Hapus',
      buttonCaptionClockIn: 'Absen Masuk',
      buttonCaptionClockOut: 'Absen Keluar',
      buttonCaptionTakePhoto: 'Ambil Foto',
      buttonCaptionChooseFromLibrary: 'Pilih dari Galeri',
      buttonCaptionUpdateApp: 'Update Aplikasi',
      buttonCaptionLater: 'Nanti',
      buttonCaptionLikes: 'Suka',
      buttonCaptionDislikes: 'Tidak Suka',
      buttonCaptionContinue: 'Lanjut',
      buttonCaptionVerify: 'Verifikasi',
      buttonCaptionMaybeLater: 'Lain Kali',
      placeholderEmail: 'email-anda@domain.com',
      placeholderAppSearch: 'Cari Nama Aplikasi',
      errMsgEmptyRequiredFields: 'Tidak boleh ada field yang kosong',
      errMsgInvalidEmailFormat: 'Format email yang Anda masukkan salah',
      errMsgInvalidPhoneNumberFormat: 'Format nomor telepon yang Anda masukkan salah',
      errMsgMinPasswordLength: '`Minimal panjang password adalah ${PASSWORD_MIN_LENGTH} karakter`',
      errMsgPasswordDoesNotMatch: 'Kedua kata sandi tidak cocok',
      errMsgCannotOpenUrl: 'Tidak bisa membuka URL',
      errMsgRequired: 'Harus diisi',
      errMsgNoResultFound: 'Hasil tidak ditemukan',
      errMsgSessionExpired: 'Sesi Anda telah kedaluwarsa. Silakan Keluar lalu Masuk kembali.',
      errMsgNoCameraFound: 'Tidak ditemukan kamera pada perangkat ini',
      errMsgCameraPermissionRequired:
        'Izin kamera belum diaktifkan .Silakan aktifkan izin kamera untuk aplikasi ini pada pengaturan aplikasi',
      errMsgUserPhoneNotSet: 'Silakan daftarkan nomor telepon Anda',
      errMsgExceedMaxPurchased: 'Anda telah melebihi jumlah maksimum pembelian',
      errMsgInsufficientMinimumAchievement:
        'Nilai pencapaian Anda tidak memenuhi batas minimum voucher ini',
      errMsgInsufficientMaximumAchievement:
        'Nilai pencapaian Anda tidak memenuhi batas maksimum voucher ini',
      errMsgInsufficientBalanceToPurchaseVoucher: 'Saldo tidak mencukupi',
      errMsgExpiredChallenge: 'Tantangan telah kedaluwarsa',
      errMsgCodeNotFound: 'Kode tidak ditemukan',
      errMsgScanLimitExceeded: 'Batas jumlah pemindaian telah terlampaui',
      errMsgHourlyScanLimitExceeded: 'Batas jumlah pemindaian per jam telah terlampaui',
      errMsgDailyScanLimitExceeded: 'Batas jumlah pemindaian per hari telah terlampaui',
      errMsgWeeklyScanLimitExceeded: 'Batas jumlah pemindaian per minggu telah terlampaui',
      errMsgMonthlyScanLimitExceeded: 'Batas jumlah pemindaian per bulan telah terlampaui',
      errMsgVinNotFound: 'VIN tidak ditemukan',
      errMsgInvalidOldPassword: 'Kata sandi lama salah',
      errMsgInvalidUsernameOrPassword: 'Nama pengguna atau kata sandi salah',
      errMsgPhoneNumberAlreadyExist: 'Nomor telepon telah digunakan orang lain',
      errMsgEmailAlreadyExist: 'Email telah digunakan orang lain',
      errMsgInvalidReferralCode: 'Email telah digunakan orang lain',
      errMsgPinExpired: 'PIN telah kedaluwarsa',
      errMsgPinHasBeenUsed: 'PIN telah digunakan sebelumnya',
      errMsgInvalidPin: 'PIN salah',
      errMsgCarAlreadyOwned: 'Anda telah memiliki mobil ini',
      errMsgPasswordComplexityRequirements:
        "Kata sandi Anda tidak memenuhi syarat kompleksitas. Kata sandi harus terdiri dari minimum 8 karakter, minimum 1 karakter angka, 1 huruf kecil, 1 huruf besar, dan 1 simbol',",
      errMsgNotRegisteredOnTanamera:
        'Email Anda belum terdaftar di Tanamera. Silakan buat akun di aplikasi Tanamera menggunakan email Anda',
      errMsgVoucherNotAvailable: 'Maaf, voucher tidak tersedia',
      errMsgFileUploadedTooLarge: 'Ukuran file yang diunggah terlalu besar',
      errMsgResourceNotFound: 'Sumber tidak ditemukan',
      errMsgResourceNotAvailable: 'Sumber tidak tersedia',
      errMsgTimeoutContactingService: 'Waktu habis ketika menghubungi layanan',
      errMsgMaxNameLength:
        '`Maksimum karakter gabungan Nama Depan dan Name Belakang ${MAX_FULLNAME_LENGTH} karakter seperti KTP`',
      errMsgYouHaveScannedThisCode: 'Anda telah memindai kode ini sebelumnya',
      errMsgInvalidNik: 'Masukkan data NIK (KTP) atau Permit Number (KITAS) yang berlaku',
      errMsgFailedTakePic: 'Gagal mengambil gambar: ',
      msgTitleFakeLocationDetected: 'Lokasi Palsu Ditemukan',
      msgRequestDisableFakeLocationApp:
        'Mohon tidak menggunakan aplikasi pengubah lokasi untuk menggunakan aplikasi ini.',
      msgCameraPermission: 'Aplikasi ini membutuhkan perizinan untuk menggunakan kamera',
      msgRootedDevice: 'Perangkat Anda tidak diperbolehkan untuk menjalankan aplikasi.',
      headerTitleMMP: 'myHyundai Indonesia',
      labelHome: 'Beranda',
      labelDownloading: 'Mengunduh...',
      labelFilter: 'Filter',
      labelEventDate: 'Tanggal Acara',
      labelEventCategory: 'Kategori Acara',
      labelStatus: 'Status',
      labelTo: 'Sampai',
      labelFrom: 'Dari',
      labelEventCityLocation: 'Lokasi Kota Acara',
      labelBranchCompany: 'Cabang Perusahaan',
      labelVersion: 'Versi',
      labelRecentSearch: 'Pencarian Terbaru',
      labelOlderAppVersion: 'Versi Aplikasi Lama Terdeteksi!',
      labelUpdateApp:
        'Mohon perbarui aplikasi Anda ke versi terbaru.\\nVersi Anda: {currentAppVersion}\\nVersi Terbaru: {latestAppVersion}',
      labeLoggingOut: 'Keluar...',
      labelWelcomeTo: 'Selamat Datang di',
      labelMmp: 'myHyundai',
      labelYes: 'Ya',
      labelNo: 'Tidak',
      labelUpload: 'Unggah',
      labelSelectPhoto: 'Pilih Sebuah Foto',
      labelLikedBy: 'Disukai',
      labelDislikedBy: 'Tidak Disukai',
      labelStarBy: 'Bintang {starRate} Oleh',
      labelYourpasswordIs: 'Kata sandi Anda',
      labelWeak: 'lemah',
      labelMedium: 'sedang',
      labelStrong: 'kuat',
      labelComment: 'Komentar',
      labelMember: 'Anggota',
      labelPasswordComplexity:
        'Password Anda harus berisi 1 huruf kecil, 1 huruf besar, 1 angka dan minimal 8 karakter. Contoh: MyH9nD4!.1d',
      labelInformation: 'Informasi',
      msgTextCopied: 'Teks Disalin',
      errMsgEmptyRequired: 'Mohon menginput {fieldname}',
      labelMmpIndo: 'Indonesia',
      msgTakePhotoInBox: 'Silakan ambil foto di kotak yang tersedia',
      msgCoinsCouponsInfo: 'Info Anggota',
      msgHasBalance: 'myHyundai Indonesia memiliki 2 saldo',
      msg1Coins: '1. Coins',
      msgCoinsValue: 'Nilai Coin setara dengan Rupiah (Cth. 200,000 coins = Rp. 200,000,-)',
      msgYouCanEarnCoins:
        'Kamu bisa mendapatkan lebih banyak coin dengan mendaftarkan mobil Hyundai kamu',
      msgYouCanRedeemCoins: 'Kamu bisa menukarkan coin dengan hadiah dari kami (Voucher)',
      msg2Coupons: '2. Coupons',
      msgYouCanGetCoupons:
        ' - Kamu bisa mendapatkan coupon dari aktivitas di aplikasi ini, seperti dengan sering membuka aplikasi, mengikuti myHyundai Challenge, dan mengunjungi dealer atau pameran kami',
      msgYouCanRedeemCoupons:
        ' - Kamu juga bisa menukarkan coupon dengan hadiah dari kami (Voucher)',
      labelRp: 'Rp ',
      labelHours: 'Jam',
      labelMinutes: 'Menit',
      labelSeconds: 'Detik',
      errMsgNotChoose: 'Mohon memilih opsi {fieldname}',
      labelCountryCode: 'Kode negara" di {registrationInfoScreen}',
      alertLoginProg: 'Login sedang proses',
      alertLoginSucc: 'Login berhasil',
      alertPleaseWait: 'Mohon Tunggu',
      alertVerif: 'Verifikasi',
      alertCaptchaError: 'Captcha Salah',
      alertCaptchaExp: 'Captcha Kedaluwarsa',
      lblResetPass: 'Reset Password',
      lblRegisPass: 'Registrasi',
      lblLoginSucc: 'Login sekarang untuk masuk ke aplikasi',
      labelSort: 'Urutkan',
      lblDelAccount: 'Hapus Akun',
      msgExitApps: 'Silakan tekan lagi untuk menutup!',
      internetAlert: {
        title: 'Masalah Koneksi Internet',
        message: 'Silakan periksa koneksi jaringan Anda'
      },
      loading: 'Memuat',
      search: 'Mencari',
      filterSort: 'Filter & Sortir',
      carModel: 'Model Mobil',
      labelExplore: 'Locator',
      youreHere: 'Anda disini',
      direction: 'Arah',
      confirmation: 'Konfirmasi',
      noMoreData: 'Tidak ada lagi data saat ini',
      labelTransaction: 'Transaksi',
      labelSetting: 'Pengaturan',
      labelContactUs: 'Hubungi Kami',
      recentSearch: 'Pencarian Terbaru',
      inputPin: 'Masukkan PIN aman Anda',
      inputPinInfo: 'Masukkan PIN Anda untuk melanjutkan pembayaran',
      setupBiometric: 'Pengaturan Biometrik',
      msgValidating: 'Memvalidasi',
      msgFailedValidatingPin: 'Gagal memvalidasi pin.',
      inputFingerprint: 'Verifikasi identitas Anda',
      inputFingerprintInfo: 'Pastikan sidik jari Anda dapat dibaca',
      cancel: 'Membatalkan',
      authRequired: 'Diperlukan otentikasi',
      msgFailedAuth: 'Gagal mengotentikasi.',
      msgTypeToSearch: 'Ketik kata kunci apa pun untuk memulai',
      msgTryOtherKeyword: 'Coba gunakan kata kunci lain',
      msgSwipeToRefresh: 'Geser ke bawah untuk menyegarkan',
      msgFailedLoadingHistory: 'Gagal memuat riwayat.',
      stsWaitingPayment: 'Waiting for payment',
      stsPaid: 'Dibayar',
      stsExpire: 'Expired',
      stsDeny: 'Payment failed',
      stsPending: 'Pending',
      msgFailedLoadingTrxDetail: 'Failed to load transaction detail.',
      msgFailedLoadingData: 'Failed to load data.',
      successCopyText: 'Text copied.',
      stsCompleted: 'Completed',
      errMsgNoSlotAvailable:
        'Maaf, tidak ada slot yang tersedia pada waktu yang dipilih. Silakan pilih waktu lain.',
      errMsgItemNotFound: 'Barang tidak ditemukan',
      reload: 'Muat Ulang',
      okAddCar: 'OK, Tambah Mobil'
    },
    landingScreen: {
      labelWelcome: 'Selamat datang di',
      labelMmpLifestyle: 'myHyundai\\nIndonesia',
      labelNotMember: 'Bukan anggota?',
      labelExperienceExciting:
        'Anda hanya berjarak satu ketukan untuk meningkatkan momen hidup Anda',
      buttonCaptionLoginWithEmail: 'Masuk dengan email',
      buttonCaptionRegisterHere: 'Daftar di sini'
    },
    loginScreen: {
      title: 'Masuk',
      labelGoodToSeeYou: 'Halo, Senang bertemu Anda!',
      labelEmail: 'Email',
      labelPassword: 'Kata Sandi',
      labelPlaceholderEmail: 'Masuk dengan email',
      labelPlaceholderPassword: 'Kata sandi',
      buttonCaptionForgotPassword: 'Lupa Pin ?',
      buttonCaptionRegisterHere: 'Daftar di sini',
      buttonCaptionResetByPhone: 'Atur ulang dengan no. hp',
      buttonCaptionResetByEmail: 'Atur ulang dengan email',
      errEmptyUsernamePassword: 'Email dan kata sandi tidak boleh kosong'
    },
    emailSubmissionScreen: {
      errMsgEmailCannotBeEmpty: 'Alamat email tidak boleh kosong'
    },
    registerEmailScreen: {
      title: 'Pendaftaran',
      description:
        'Tulis email Anda di sini. Kami akan mengirim PIN ke email Anda yang bisa digunakan untuk melanjutkan proses registrasi'
    },
    pinVerificationScreen: {
      title: 'Verifikasi PIN',
      subTitle: 'Masukkan PIN yang baru saja kami kirim ke email Anda',
      labelExpiration: 'PIN akan kedaluwarsa dalam',
      labelHaventGotThePin: 'Belum mendapatkan kode OTP?',
      labelYouCanResend: 'Kirim ulang dalam waktu',
      labelPinHasExpired: 'Kode telah kedaluwarsa',
      labelCodeSentTo: 'Kode dikirimkan ke:',
      labelPleaseCheckInbox: 'Silakan periksa kotak masuk Anda dan salin kode OTP di sini',
      buttonCaptionResendPin: 'Kirim Ulang Kode',
      msgCancelConfirmation: 'Anda yakin akan membatalkan proses ini?',
      errMsgPinMustBeSixDigits: 'PIN harus terdiri dari 6 angka',
      labelPleaseCheckOTP: 'Silakan periksa kotak masuk Anda dan salin Kode OTP di sini.'
    },
    resetPasswordScreen: {
      title: 'Lupa Kata Sandi',
      subtitle: 'Apakah Anda lupa kata sandi Anda?',
      description:
        'Masukkan alamat email yang Anda gunakan untuk akun Anda di bawah ini dan masukkan kata sandi baru',
      labelBackTo: 'Kembali ke ',
      labelEmail: 'Email',
      labelNewPassword: 'Kata Sandi Baru',
      labelRepeatPassword: 'Ulangi Kata Sandi Baru',
      labelPlaceholderEmail: 'ex: yourname@ntt.co.id',
      labelPlaceholderPassword:
        'Min. 8 karakter, setidaknya berisi angka, huruf kecil, huruf besar, dan simbol',
      buttonCaptionSubmit: 'Kirim',
      buttonCaptionSignIn: 'Masuk'
    },
    registrationInfoScreen: {
      title: 'Pendaftaran',
      description: 'Mohon isi data di bawah',
      labelFullName: 'Nama Lengkap',
      labelPhone: 'Nomor Telepon',
      labelJobTitle: 'Jabatan',
      labelPassword: 'Sandi',
      labelConfirmPassword: 'Ulangi Sandi',
      labelReferralCode: 'Kode Referral',
      labelEmail: 'Email',
      labelIAgree: 'Saya menyetujui ',
      labelAnd: ' dan ',
      labelMyHyundai: ' myHyundai Indonesia',
      labelSalutation: 'Panggilan',
      labelDateOfBirth: 'Tanggal Lahir',
      labelFirstName: 'Nama Depan',
      labelLastName: 'Nama Belakang',
      msgPhoneNumberRule: 'Silakan masukkan nomor telepon dengan format 0812xxxxxx',
      msgFullNameRule:
        '`Maksimum karakter gabungan Nama Depan dan Name Belakang ${MAX_FULLNAME_LENGTH} karakter seperti KTP. Jika lebih panjang, nama dapat disingkat dengan huruf pertama. Contoh: Ramayana Kresna Mahabharata => Ramayana K. M.`',
      buttonCaptionRegister: 'Daftar',
      buttonCaptionTermsConditions: 'Syarat dan Ketentuan',
      buttonCaptionPrivacyPolicy: 'Kebijakan Pengunaan',
      labelProvince: 'Provinsi',
      labelCity: 'Kota',
      labelDistrict: 'Kecamatan',
      labelHaveRefCode: 'Apakah anda mempunya kode referal ?',
      placeholderSalutation: 'Select Salutation',
      placeholderDateOfBirth: 'dd/mm/yyyy',
      placeholderFirstName: 'Nama Depan ',
      placeholderLastName: 'Nama Belakang',
      placeholderProvince: 'Pilih Provinsi',
      placeholderCity: 'Pilih Kota',
      placeholderDistrict: 'Pilih Kecamatan',
      placeholderPhone: 'Nomer Telp',
      placeholderPassword: 'Sandi',
      placeholderConfirmPassword: 'Konfirmasi Sandi',
      placeholderReferralCode: 'Referral Code',
      placeholderEmail: 'Email',
      labelComplexityPass: 'Min 1 huruf besar, 1 huruf kecil, karater spesial'
    },
    homeScreen: {
      title: 'myHyundai Indonesia',
      labelShowQR: 'Lihat',
      labelMoreApps: 'Apps Lain',
      labelApplication: 'Aplikasi',
      labelNewsUpdate: 'Berita terbaru',
      labelforgetPassword: 'Lupa Kata Sandi',
      labelBanner: 'Banner',
      labelSeeMore: 'Selengkapnya',
      labelMenu: 'Menu',
      labelMoreMenu: 'Menu Lain',
      labelMorning: 'pagi',
      labelAfternoon: 'siang',
      labelEvening: 'sore',
      labelGood: 'Hai! Selamat',
      labelCoins: 'Koin',
      labelCoupons: 'Coupons',
      labelOurPartners: 'Mitra Kami',
      labelFindFascinatingDeal: 'Temukan penawaran menarik dari mitra kami',
      labelDeals: 'Penawaran',
      labelCollectCoupons: 'Kumpulkan Coupons Anda',
      labelCollectCouponsDescription:
        'Ikuti berbagai tantangan menarik untuk mendapatkan tambahan Coupons',
      labelSeeTheLatest: 'See the latest promo from Hyundai',
      labelSeeTheLatestUpdate: 'Temukan promo terbaru dari Hyundai',
      labelReadMore: 'Baca Selengkapnya',
      labelAllApps: 'Semua Aplikasi',
      labelMainApps: 'Aplikasi Utama',
      labelMy: 'my',
      labelHyundaiIndonesia: 'Hyundai Indonesia',
      labelIndonesia: 'Indonesia',
      buttonCaptionSeeMoreNews: 'Lihat lebih banyak berita',
      buttonCaptionSeeAll: 'Lihat Semua',
      buttonCaptionRegister: 'Daftar',
      buttonCaptionExclusiveDeals: 'Manfaat Eksklusif Untuk Anda',
      buttonCaptionSeeAllChallenges: 'Lihat semua tantangan',
      buttonCaptionChange: 'Ubah',
      labelMemberId: 'ID Anggota',
      buttonCaptionInputPromoCode: 'Masukkan Kode Promo',
      buttonCaptionGiiasChallage: 'Tantangan GIIAS 2022',
      buttonCaptionGiiasSmgChallenge: 'Tantangan GIIAS Semarang 2022',
      buttonCaptionGiiasSbyChallenge: 'Tantangan GIIAS Surabaya 2022',
      buttonCaptionGiiasMdnChallenge: 'Tantangan GIIAS Medan 2022',
      labelPartnerDeals: 'Penukaran Hadiah',
      labelLifestyleBenefit: 'Lifestyle Benefit',
      labelGetBestDeal: 'Dapatkan penawaran meranarik dari partner kami',
      labelWhileSupport: 'Mendukung tujuan besar untuk komunitas kami.',
      labelWhatsOnLife: "What's On Life Style",
      hyundaiServices: 'One-Stop Hyundai',
      promoCode: 'Kode Promo',
      promoCodeDesc: 'Masukkan Kode Promo Disini',
      validate: 'Validasi',
      labelMember: 'Anggota',
      msgCoinsCouponsInfo: 'Info Anggota',
      labelWhatsOn: 'Jelajah Lebih Banyak',
      campaignStory: 'Apa yang sedang terjadi ?',
      labelCarNotFound: 'Mobil tidak ditemukan',
      labelAddHyundaiCar: 'Tambahkan Mobil Hyundai',
      labelConnected: 'Terhubung',
      labelNotConnected: 'Tidak Terhubung',
      labelNotSupported: 'Tidak Didukung'
    },
    forgetPasswordScreen: {
      title: 'Lupa Kata Sandi',
      labelEmail: 'Email',
      labelPhone: 'No. Telp',
      labelAlreadyHaveAccount: 'Sudah punya akun? ',
      labelToSetUp:
        'Untuk mengatur kata sandi Anda yang baru silakan masukan {method} Anda di sini',
      buttonCaptionSend: 'Kirim',
      buttonCaptionLoginHere: 'Masuk di sini',
      labelEmailWithStrip: 'E-mail',
      labelPhoneNumber: 'Nomor Telepon',
      labelToSetUpPin: 'Untuk mengatur PIN Anda yang baru silakan masukkan {method} Anda di sini',
      labelToSetUpPassword:
        'Untuk mengatur kata sandi Anda yang baru silakan masukkan {method} Anda di sini'
    },
    notificationScreen: {
      title: 'Pusat Pemberitahuan',
      labelFilter: 'SARING',
      buttonCaptionShowAll: 'Tampilkan Semua',
      buttonCaptionShowRead: 'Tampilkan Terbaca',
      buttonCaptionShowUnread: 'Tampilkan Belum Dibaca',
      buttonCaptionMarkAllRead: 'Tandai sudah dibaca',
      buttonCaptionFilterAll: {
        id: 'all',
        label: 'Semua'
      },
      buttonCaptionFilterPromo: {
        id: 'promo',
        label: 'Promo'
      },
      buttonCaptionFilterCoins: {
        id: 'coins',
        label: 'Koin'
      },
      buttonCaptionFilterCampains: {
        id: 'campaign',
        label: 'Kampanye'
      },
      buttonCaptionModalRead: {
        id: 'read',
        label: 'Terbaca'
      },
      buttonCaptionModalUnread: {
        id: 'unread',
        label: 'Belum Dibaca'
      },
      buttonCaptionModalAsc: {
        id: 'asc',
        label: 'Lama'
      },
      buttonCaptionModalDsc: {
        id: 'desc',
        label: 'Terbaru'
      }
    },
    profileScreen: {
      title: 'Profil',
      labelCompanyData: 'Data Perusahaan',
      labelCompany: 'Perusahaan',
      labelBranch: 'Cabang',
      labelBranchOfficeHour: 'Jam Kantor Cabang',
      labelDepartment: 'Departemen',
      labelDivision: 'Divisi',
      labelManager: 'Manajer',
      labelPersonalData: 'Data Pribadi',
      labelEmail: 'Email',
      labelPhoneNo: 'No. Telepon',
      labelPreferenceOfficeHour: 'Jam Kantor Pilihan',
      labelTo: 'sampai',
      labelPlus: '+',
      labelOldPassword: 'Kata Sandi Lama',
      labelNewPassword: 'Kata Sandi Baru',
      labelConfirmNewPassword: 'Konfirmasi Kata Sandi Baru',
      labelLogoutAll: 'Keluar Dari Semua Perangkat',
      labelSalutation: 'Panggilan',
      labelAddress: 'Alamat',
      labelPostalCode: 'Kode Pos',
      labelCity: 'Kota',
      labelProvince: 'Provinsi',
      labelFullName: 'Nama Lengkap',
      labelMr: 'Bapak',
      labelMs: 'Ibu',
      labelDateOfBirth: 'Tanggal Lahir',
      buttonCaptionLogout: 'Keluar',
      buttonCaptionSeeAll: 'Lihat Semua',
      buttonCaptionChangePassword: 'Ubah Kata Sandi',
      buttonCaptionSave: 'Simpan',
      msgLogoutConfirmation: 'Apa Anda yakin ingin keluar?',
      msgDeleteAccountConfirmation: 'Apa Anda yakin ingin menghapus akun Anda?'
    },
    profileChangeEmailScreen: {
      title: 'Ubah Email',
      labelEmail: 'Email Baru',
      buttonCaptionUpdate: 'Perbarui'
    },
    profileChangePhoneScreen: {
      title: 'Ubah Nomor Telepon',
      labelPhone: 'Nomor Telepon Baru',
      buttonCaptionUpdate: 'Perbarui'
    },
    settingScreen: {
      labelAboutTheApp: 'Tentang Aplikasi',
      labelV: 'V',
      buttonCaptionProfileSettings: 'Pengaturan Profil',
      buttonCaptionChangePassword: 'Ubah Kata Sandi',
      buttonCaptionChangeEmail: 'Ubah Email',
      buttonCaptionChangePhone: 'Ubah No. Telepon',
      buttonCaptionPointBalanceHistory: 'Riwayat Saldo',
      buttonCaptionCoinCouponExpiry: 'Coin & Coupons Kedaluwarsa',
      buttonCaptionSettings: 'Konfigurasi',
      buttonCaptionLanguageSetting: 'Pengaturan Bahasa',
      buttonCaptionTermsAndConditions: 'Syarat dan Ketentuan',
      buttonCaptionPrivacyPolicy: 'Kebijakan Privasi',
      buttonCaptionFAQ: 'FAQ',
      buttonCaptionHelpCenter: 'Pusat Bantuan',
      buttonCaptionLogOut: 'Keluar',
      buttonCaptionPaymentPin: 'PIN Pembayaran Servis & Promo',
      labelAccounts: 'Akun',
      buttonCaptionChangePin: 'Ganti PIN',
      buttonCaptionLogOutDesc: 'Apakah Anda Yakin Ingin Keluar?',
      gffMember: 'Anggota Gff',
      title: 'Pengaturan',
      faqTItle: 'FAQ',
      tabGeneral: 'Umum',
      tabEv: 'Ev Charger',
      faqGeneral: 'Faq Umum',
      faqNotFound: 'Data tidak ditemukan',
      tabSupp: 'myHyundai CS',
      tabSales: 'After Sales CS',
      hyundaiIndo: 'myHyundai Indonesia',
      hyundaiMotor: 'Hyundai Motors Indonesia',
      socialMedia: 'Social Media',
      helpContactUs: 'Hubungi Kami',
      myhundaiId: 'Myhyundai.Id',
      btnResetPhone: 'Setel Ulang Nomor Telepon',
      btnResetPin: 'Setel Ulang PIN',
      modalInfoTitleEmail: 'Ganti E-Mail',
      modalInfoTitlePhone: 'Ubah Nomor Telepon',
      modalInfoDescEmail: 'Apakah Anda Yakin Ingin Mengubah Email?',
      modalInfoDescPhone: 'Apakah Anda Yakin Ingin Mengubah Nomor Telepon?',
      modalInfoConfirm: 'Ya',
      modalInfoCancel: 'TIDAK',
      btnChangePassword: 'Ganti Kata Sandi',
      modalPassTitle: 'Ubah Kata Sandi',
      modalPassDesc:
        'Anda Perlu Masuk Kembali Untuk Mengatur Kata Sandi Baru. Apakah Anda Ingin Melanjutkan?',
      modalDelTItle: 'Hapus Akun',
      modalDelDesc:
        'Jika Anda Mengakhiri Akun Anda, Anda Akan Kehilangan Semua Informasi Yang Ada, Termasuk Informasi Kendaraan. Apakah Anda Yakin Ingin Mengakhiri Akun Anda?',
      modalDelConfirm: 'Melanjutkan',
      modalDelCancel: 'Membatalkan',
      btnDelAccount: 'Hapus Akun',
      loadingFaq: 'Harap Tunggu, Mencari ...',
      modalConfirmResetPhoneTitle: 'Setel ulang nomor telepon',
      modalConfirmResetPhoneDesc:
        'Kami akan mengatur ulang nomor telepon Anda. Anda perlu masuk kembali untuk mengatur nomor telepon baru. Apakah Anda ingin melanjutkan?',
      modalConfirmResetPhoneYes: 'Melanjutkan',
      modalConfirmResetPhoneNo: 'Membatalkan',
      modalConfirmResetPinTitle: 'Reset PIN',
      modalConfirmResetPinDesc:
        'Kami akan mengatur ulang pin Anda. Anda perlu masuk kembali untuk mengatur pin baru. Apakah Anda ingin melanjutkan?',
      modalConfirmResetPinYes: 'Melanjutkan',
      modalConfirmResetPinNo: 'Membatalkan',
      ptHyundaiMotorsId: 'PT Hyundai Motors Indonesia',
      helpCenterTitle: 'Layanan Pengaduan Konsumen'
    },
    editFavoriteScreen: {
      title: 'Ubah Favorit',
      labelHelp: 'Ketuk tombol ubah untuk mengganti aplikasi pengganti',
      labelChangeFavorite: 'Ubah'
    },
    chooseFavoriteScreen: {
      title: 'Pilih Aplikasi'
    },
    allAppsScreen: {
      title: 'Semua Aplikasi',
      labelSearch: 'Cari'
    },
    approvalScreen: {
      title: 'My Approval',
      titleApproverDialog: 'Penyetuju',
      labelActive: 'Aktif',
      labelHisotries: 'Riwayat',
      labelApproved: 'Diterima',
      labelRejected: 'Ditolak',
      labelRejectedReason: 'Alasan Penolakan:',
      labelReasonNotProvided: 'Alasan tidak disebutkan.',
      labelFindApproval: 'Temukan Approval',
      labelReject: 'Tolak',
      labelReason: 'Alasan',
      labelWaitingForApproval: 'Menunggu Persetujuan',
      labelNeedInformation: 'Butuh Info Tambahan',
      labelNoAnswer: 'Tidak Merespon',
      labelAll: 'Semua',
      labelCancelled: 'Dibatalkan',
      buttonCaptionApprove: 'Setuju',
      buttonCaptionReject: 'Tolak',
      buttonCaptionDetail: 'Rincian',
      buttonCaptionCancel: 'Batal',
      buttonCaptionCopyInfo: 'Salin Informasi',
      buttonCaptionSeeDetail: 'Lihat Detail Acara',
      msgApproveConfirmation: 'Apakah Anda yakin untuk menyetujui?'
    },
    onboardScreen: {
      titleFirstOnboard: 'Manfaat Loyalitas Hanya untuk Anda',
      titleSecondOnboard: 'Nikmati Hak Istimewa Eksklusif',
      titleThirdOnboard: 'Aplikasi myHyundai Indonesia untuk Mendukung Mobilitas Anda',
      labelEng: 'EN',
      labelInd: 'ID',
      labelEnglish: 'English',
      labelIndonesia: 'Indonesia',
      labelFirstDescription:
        'Jadilah spesial dengan aktivitas keanggotaan kami, beragam pilihan tingkatan, dan keuntungan yang dapat Anda nikmati.',
      labelSecondDescription:
        'Tidak hanya untuk menyesuaikan gaya hidup Anda tapi juga untuk mendukung mobilitas Anda, aplikasi myHyundai Indonesia dapat memberikan Anda beragam keuntungan yang dapat diperoleh selama penggunaan.',
      labelThirdDescription: 'Dapatkan info terbaru mengenai komunitas dan layanan Hyundai Anda.',
      buttonCaptionSkip: 'Lewati',
      buttonCaptionNext: 'Selanjutnya',
      buttonCaptionPrevious: 'Sebelumnya',
      buttonCaptionGetStarted: 'Mulai'
    },
    profileEditDataScreen: {
      title: 'Edit Data',
      labelPersonalData: 'Data Pribadi',
      labelCompanyData: 'Data Perusahaan',
      labelAdditionalData: 'Data Tambahan',
      labelGender: 'Gender',
      labelPhone: 'Nomor Telepon',
      labelPlaceholderPhone: 'Nomor Telepon contoh: 08123456789',
      labelGenderMale: 'Laki-Laki',
      labelGenderFemale: 'Perempuan',
      labelBloodType: 'Gol. Darah',
      labelRhesus: 'Resus',
      labelFacebook: 'Facebook',
      labelInstagram: 'Instagram',
      labelTwitter: 'Twitter',
      labelLinkedIn: 'LinkedIn',
      labelDateOfBirth: 'Tanggal Lahir',
      labelDomicile: 'Domisili'
    },
    myVolunteerRegisterScreen: {
      title: 'Form Pendaftaran',
      labelYourPersonalData: 'Data Pribadi Anda',
      labelName: 'Nama',
      labelBloodType: 'Tipe Darah',
      labelRhesus: 'Resus',
      labelAdditionalData: 'Data Tambahan',
      labelPosition: 'Posisi',
      labelReason: 'Alasan memilih posisi ini',
      labelDescription: 'Deskripsi',
      labelFillBloodType: 'Mohon isi nilai "Tipe Darah" Anda di profil',
      labelError: 'Gagal',
      labelSuccess: 'Sukses',
      labelSuccessSubmit: 'Keren, registrasi Anda telah dikirimkan, mohon tunggu persetujuannya',
      labelSuccesRegister: 'Keren, sekarang Anda sudah terdaftar di event ini',
      labelStart: 'Mulai:',
      labelEnd: 'Selesai:',
      buttonCaptionGoToProfile: 'Pergi ke Profil',
      buttonCaptionRegister: 'Daftar'
    },
    approvalCommentScreen: {
      title: 'Komentar Persetujuan',
      labelWriteYourComment: 'Tulis komentar Anda',
      buttonCaptionPostComment: 'Kirim Komentar'
    },
    otpScreen: {
      title: 'OTP',
      labelPleaseInputOtp: 'Silakan masukkan kode OTP yang dikirim ke {method} Anda {sendTo}',
      labelOtpExpireIn: 'KODE KEDALUWARSA:',
      labelOtpExpired: 'KODE KEDALUWARSA',
      labelSuccess: 'Sukses',
      labelSuccesfullyChanged: '{method} berhasil diperbarui',
      labelHaventGotThePin: 'Belum mendapatkan kode OTP?',
      labelYouCanResend: 'Kirim ulang dalam waktu',
      labelPhoneNumber: 'Nomor HP',
      buttonCaptionResendPin: 'Kirim Ulang OTP',
      errMsgInvalidOtp: 'Kode OTP tidak valid'
    },
    newPasswordScreen: {
      title: 'Atur Kata Sandi Baru',
      labelPleaseInputNewPassword: 'Silakan masukkan kata sandi baru',
      labelNewPassword: 'Kata Sandi Baru',
      labelReNewPassword: 'Konfirmasi Kata Sandi Baru',
      labelNewPasswordSaved: 'Kata sandi baru berhasil disimpan',
      labelSucces: 'Sukses',
      buttonCaptionLogin: 'MASUK',
      buttonCaptionUpdate: 'Atur Kata Sandi Baru'
    },
    splashScreen: {
      labelPoweredByMII: 'Powered By NTT'
    },
    bannerScreen: {
      buttonCaptionShare: 'Bagikan',
      labelSeen: 'Dilihat',
      labelTimes: 'Kali',
      labelPeriod: 'Periode'
    },
    bannersScreen: {
      title: 'Banners'
    },
    scanQrCodeScreen: {
      title: 'Pindai kode QR'
    },
    discussionComponent: {
      buttonCaptionSend: 'Kirim',
      buttonCaptionCancel: 'Batal',
      buttonCaptionSeeAll: 'Lihat Semua',
      buttonCaptionReply: 'Balas',
      buttonCaptionSeeReplies: 'Lihat {replyCount} Balasan',
      buttonCaptionHideReplies: 'Tutup {replyCount} Balasan',
      buttonCaptionSeeMoreReplies: 'Lihat lebih banyak balasan',
      buttonCaptionSeeMoreComments: 'Lihat lebih banyak komentar',
      placeholderWriteYourComment: 'Tulis komentar Anda',
      labelMinute: 'Menit',
      labelHours: 'Jam',
      labelLike: 'Suka',
      labelSending: 'Mengirim..',
      labelSortBy: 'Urutkan'
    },
    helpCenterScreen: {
      title: 'Pusat Bantuan',
      labelCallCenter: 'Telepon',
      labelWhatsapp: 'WhatsApp',
      labelEmail: 'Email',
      labelSocialMeda: 'Media Sosial',
      labelContact:
        'Silakan hubungi kami untuk bantuan apa pun mengenai aplikasi ini menggunakan channel di bawah ini:',
      CCHeader: 'Layanan Pengaduan Konsumen',
      CCBody1: 'Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga',
      CCBody2: 'Kementerian Perdagangan Republik Indonesia'
    },
    termsConditionsScreen: {
      title: 'Syarat dan Ketentuan'
    },
    privacyPolicyScreen: {
      title: 'Kebijakan Privasi'
    },
    registrationPhoneScreen: {
      title: 'Verifikasi Nomor Telepon',
      labelPhone: 'Nomor Telepon',
      labelValidatePhoneNumber: 'Pastikan Anda telah memasukkan nomor yang benar dan verifikasi',
      buttonCaptionValidate: 'Verifikasi'
    },
    languageSettingsScreen: {
      title: 'Konfigurasi',
      labelAppLanguage: 'Pilih Bahasa'
    },
    historyCoin: {
      title: 'Riwayat Saldo',
      labelCoins: 'Jumlah Koin',
      labelReferral: 'Rujukan',
      filterAll: {
        id: 'All',
        label: 'Semua'
      },
      filterReferral: {
        id: 'Referral',
        label: 'Rujukan'
      },
      titleTransaction: 'Histori Transaksi',
      labelAmount: 'Jumlah',
      labelTransactionNo: 'Nomor Transaksi',
      labelDate: 'Tanggal',
      titleExpired: 'Kadaluwarsa',
      messageCopy: 'Nomor transaksi berhasil tersalin',
      coinBalance: 'Saldo Coin',
      referral: 'Rujukan'
    },
    evChargerScreen: {
      title: 'EV Charger',
      tabViewCarInfo: {
        id: 'first',
        label: 'Mobil dan Informasi'
      },
      tabViewLocation: {
        id: 'second',
        label: 'Lokasi'
      },
      yourLocationAddress: 'Lokasi Anda',
      avalaibleKWH: 'Tersedia kWh',
      yourEVCar: 'EV Car Anda',
      addNewEVCar: 'Tambahkan EV Car Baru',
      connectYourEVCar:
        'Hubungkan EV car anda untuk mengisi daya, didukung oleh myHyundai Indonesia.',
      chargingProses: 'Mengisi Daya Dalam Proses',
      action: 'Aksi',
      history: 'Riwayat',
      deleteCar: 'Hapus Mobil',
      directionBtn: 'Arah',
      filterAll: {
        id: 'All',
        label: 'All'
      },
      filterNearMe: {
        id: 'nearme',
        label: 'Dekat saya'
      },
      filterHyundai: {
        id: 'hyundai',
        label: 'Hyundai'
      },
      filterNonHyundai: {
        id: 'nonhyundai',
        label: 'Non Hyundai'
      },
      filterPartner: {
        id: 'partner',
        label: 'partner'
      },
      sort: 'Urutkan',
      nearestLocation: 'Lokasi Terdekat',
      furthestLocation: 'Lokasi Terjauh',
      filterShell: {
        id: 'shell',
        label: 'shell'
      },
      labelMenuHome: 'Rumah',
      labelMenuLocation: 'Lokasi',
      labelMenuHistory: 'Sejarah',
      addCar: 'Tambahkan Mobil',
      lastCharging: 'Pengisian Terakhir',
      stopChargingBtn: 'Berhenti Mengisi Daya',
      chargingProcessBtn: 'Pengisian Sedang Berlangsung',
      labelElapsedTime: 'Waktu Berlalu',
      labelNA: 'N/A',
      labelOutput: 'Keluaran',
      labelkWh: 'kWh',
      carBrand: 'Merek Mobil',
      carModel: 'Model Mobil',
      labelCarBrand: 'Pilih Merek Mobil',
      labelCarModel: 'Pilih Model Mobil',
      titleHistory: 'Sejarah',
      historyEmpty: 'Sejarah Tidak Ditemukan',
      invalidURL: 'Url Tidak Valid',
      availableKWH: 'Tersedia kWh',
      labelDeleteCar: 'Hapus Mobil',
      startChargingScanQRBtn: 'Mulailah Mengisi Dengan Pindai QR',
      startChargingBtn: 'Mulailah Mengisi',
      requestChargingBtn: 'Meminta Mitra',
      turnOnLocation: 'Nyalakan Lokasi',
      turnOnLocationMessage:
        'Untuk melanjutkan, nyalakan lokasi perangkat, yang menggunakan layanan lokasi Google.',
      turnOnLocationCancel: 'Tidak, Terima Kasih',
      turnOnLocationSubmit: 'Oke',
      buttonConfirmationTitle: 'Konfirmasi',
      buttonConfirmationCancel: 'Membatalkan',
      buttonAbort: 'Tidak',
      buttonDescriptionDeleteCar: 'Apakah Anda pasti akan menghapus mobil EV ini?',
      buttonDescriptionDeleteCarConfirm: 'Ya, Hapus',
      buttonStopTitle: 'Berhenti Mengisi Daya',
      buttonStopDescription: 'Apakah Anda ingin berhenti mengisi daya?',
      buttonStopSubmit: 'Berhenti Mengisi Daya',
      buttonCancelTitle: 'Batalkan Permintaan',
      buttonCancelDescription: 'Apakah Anda ingin membatalkan permintaan?',
      buttonCancelSubmit: 'Batalkan Permintaan',
      cancelRequestError: 'Tidak dapat membatalkan permintaan. Tidak ada transaksi yang ditemukan.',
      chargingStoppedKwhLimit: 'Pengisian daya dihentikan. batas kWh tercapai.',
      chargingStopped: 'Pengisian daya dihentikan.',
      stopSuccessTitle: 'Terima Kasih',
      stopSuccessDescription: 'Anda selesai mengisi EV Anda',
      stopSuccessCaption: 'Lihat Detail Transaksi',
      stationDetailPlace: 'Tempat',
      stationDetailFloor: 'Lantai',
      stationDetailOperationalHours: 'Jam Operasional',
      stationDetailYourQuota: 'Kuota Anda',
      stationDetailAtThisStation: 'Di Stasiun Ini',
      stationDetailKWhLimit: 'Batas kWh',
      stationDetailKWhAtThisStation: 'kWh Di Stasiun Ini',
      stationDetailChargerBox: 'Kotak Charger',
      stationDetailType: 'Jenis',
      stationDetailPower: 'Kekuatan',
      stationDetailWatt: 'Watt',
      stationDetailAvailable: 'Tersedia',
      startChargingHowToStartQ: 'Bagaimana Cara Mulai Mengisi Daya?',
      startChargingHowToStopQ: 'Bagaimana Cara Berhenti Mengisi Daya?',
      startChargingReady: 'Plugin Kabel, Pengisian Daya Siap Untuk Memulai',
      startChargingChargingIsRunning: 'Pengisian Daya Berjalan',
      startChargingStation: 'Stasiun Pengisian',
      startChargingHowToStart: 'Bagaimana Mulai Mengisi',
      startChargingHowToStop: 'Cara Berhenti Mengisi',
      startChargingInformation: 'Informasi Pengisian Daya',
      startChargingStartCharging: 'Apakah Anda Ingin Mulai Mengisi Daya?',
      startChargingStopCharging: 'Apakah Anda Ingin Berhenti Mengisi Daya?',
      startChargingCancel: 'Membatalkan',
      startChargingRequestToPartner: 'Meminta Mitra',
      startChargingRequestToPartnerDescription: 'Apakah Anda Ingin Meminta Mitra?',
      startChargingRequestToPartnerConfirm: 'Ya, Permintaan',
      scanQRCodeInvalid: 'Kode QR Ini Tidak Valid',
      locationMenuPermissionTitle: 'Nyalakan Lokasi',
      locationMenuPermissionMessage:
        'Untuk melanjutkan, nyalakan lokasi perangkat, yang menggunakan layanan lokasi Google.',
      locationMenuPermissionCancel: 'Tidak, Terima Kasih',
      locationMenuPermissionSubmit: 'Oke',
      locationMenuPermissionDenied: 'Izin Ditolak',
      locationMenuScanQR: 'Pindai Kode QR',
      locationMenuMaps: 'Arah Peta',
      listEVGettingLocation: 'Mendapatkan Lokasi ...',
      listEVYouAreHere: 'Kamu Di Sini',
      listEVResultInput: 'Hasil Input',
      listEVPlug: 'Steker',
      listEVSpeed: 'Kecepatan',
      listEVRecordEnd: '--- Rekam Akhir ---',
      listEVNoLocationFound: 'Tidak ada lokasi yang ditemukan',
      listEVSearchOtherLocation: 'Cari lokasi lain untuk menemukan stasiun',
      listEVReset: 'Mengatur Ulang',
      listEVChooseCar: 'Pilih Mobil',
      historyEVFailed: 'Gagal memuat filter riwayat.',
      historyEVSuccessful: 'Sukses',
      historyEVNoMoreData: 'Tidak ada lagi data saat ini',
      historyEVChargingDate: 'Tanggal Pengisian Daya',
      historyEVKWhUsage: 'Penggunaan kWh',
      historyEVKWh: 'kWh',
      historyEVChargingDuration: 'Durasi Pengisian Daya',
      historyEVType: 'Jenis',
      historyEVPower: 'Kekuatan',
      historyEVLocationDetail: 'Detail Lokasi',
      historyEVHour: 'Jam',
      historyEVNotFound: 'Sejarah Tidak Ditemukan',
      evCarListCancel: 'Batalkan Permintaan',
      evCarListStop: 'Berhenti Mengisi Daya',
      currentStation: 'Stasiun',
      currentStationDetail: 'Detail',
      carInfoMenuTopUp: 'Topup',
      carInfoMenuSend: 'Mengirim',
      labelHoursHistoryEv: 'jam',
      topup: 'Topup',
      chooseAmount: 'Pilih Jumlah',
      continue: 'Melanjutkan',
      reload: 'Muat ulang',
      paymentOptions: 'Opsi pembayaran',
      selectPaymentMethod: 'Pilih metode pembayaran',
      vaInfo: 'Pembayaran dengan rekening virtual bank',
      walletInfo: 'Pembayaran dengan dompet atau kartu kredit',
      paymentFeeInfo: 'Anda akan dikenakan sejumlah RP {0} untuk biaya topup',
      transaction: 'Transaksi',
      topupTransactions: 'Transaksi Topup',
      trxDetail: 'Detail Transaksi',
      paymentInfo: 'Informasi pembayaran',
      status: 'Status',
      expiredIn: 'Kedaluwarsa',
      paymentMethod: 'Cara Pembayaran',
      trxId: 'ID transaksi',
      trxDate: 'Tanggal Transaksi',
      tokenAmount: 'Jumlah Token',
      topupAmount: 'Jumlah Topup',
      topupFee: 'Biaya Topup',
      grandTotal: 'Hasil Akhir',
      availableToken: 'Token yang tersedia',
      vaNumber: 'Nomor virtual account',
      msgFailedLoadingTopupAmounts: 'Failed to load topup amounts.',
      msgFailedLoadingPaymentMethods: 'Failed to get payment methods.',
      msgFailedProcessingPayment: 'Failed to process payment.',
      processPayment: 'Please wait, we are processing your payment',
      viewTopupTrx: 'Lihat Transaksi Topup',
      areYouHyundaiOwner: 'Apakah Anda pemilik mobil EV Hyundai?',
      hyundaiEvOwner: 'Pemilik Mobil EV Hyundai',
      otherEvBrands: 'Merek EV Lainnya',
      hyundaiEvOwnerDesc: 'Tambahkan Hyundai EV Anda untuk mengakses fitur dan manfaat eksklusif',
      otherEvBrandsDesc: 'Merek EV lain untuk mulai menggunakan fitur EV universal',
      searchOtherBrand: 'Silakan cari merk lain',
      addCarBrandDesc:
        'Anda hanya dapat menambahkan satu merek mobil. Setelah menambahkan merek baru, Anda tidak dapat mengubah atau menghapusnya.',
      confirmAddCarBrand: 'Apakah Anda yakin untuk menambahkan {0} ke pengisi daya EV?',
      successAddOtherCarBrand: 'Merek mobil lain berhasil ditambahkan'
    },
    addEvCarScreen: {
      title: 'Menambahkan EV Car'
    },
    editProfileScreen: {
      labelFirstName: 'Nama Depan',
      placeholderFirstName: 'Nama Depan',
      labelLastName: 'Nama Belakang',
      placeholderLastName: 'Nama Belakang',
      labelPostalCode: 'Kode Pos',
      placeholderPostalCode: 'Kode Pos',
      placeholderAddress: 'Alamat',
      labelAddress: 'Alamat',
      btnUpdateProfile: 'Ubah Profil',
      chooseFromGallery: 'Ambil dari gallery',
      takePhoto: 'Ambil Gambar',
      addPhoto: 'Tambah foto',
      title: 'Ubah Profil',
      btnDeleteMyAccount: 'Hapus Akun Saya'
    },
    changePin: {
      title: 'PIN',
      labelCurrentPin: 'Input PIN lama',
      labelNewPin: 'Input PIN baru',
      labelReenterPin: 'Input ulang PIN baru',
      labelForgotPin: 'Lupa PIN ?',
      textInfo: 'Pin Anda digunakan untuk transaksi pembayaran',
      errCurrentPin: 'PIN anda tidak valid',
      errInvalidPin: 'kode OTP salah',
      errMatchPin: 'PIN tidak sama',
      msgSuccess: 'Reset PIN berhasil',
      msgUpdateSuccess: 'PIN anda berhasil terupdate',
      msgTitleSuccess: 'Terima kasih, PIN berhasil terupdate',
      goToSetting: 'pergi ke Setting'
    },
    deleteAccount: {
      title: 'Hapus Konfirmasi',
      desc: 'Apakah anda yakin hapus akun myHyundai ?',
      confirmText: 'OK, Hapus',
      closeText: 'Batal',
      msgHeaderSuccess: 'Berhasil hapus akun',
      msgTitleSuccess: 'Akun anda berhasil terhapus',
      goToLogin: 'pergi ke Login'
    },
    dailyCheckIn: {
      title: 'Check-In Harian',
      headerTitle: 'Check-In Harian Spesial untuk Anda.',
      programPeriod: 'Periode program',
      endsIn: 'Berakhir pada',
      consecutiveMission: 'Misi Berturut-turut',
      consecutiveMissionDesc: 'Check-in {0} berturut-turut dan dapatkan hadiah menarik!',
      checkIn: 'Check-in',
      checkInTomorrow: 'Check-in Lagi Besok',
      checkInCompleted: 'Check-in Selesai',
      getVouchers: 'Dapatkan Voucher',
      voucher: 'Voucher',
      dayLeft: 'tersisa {0} hari',
      daysLeft: 'tersisa {0} hari',
      success: 'Sukses check-in harian',
      rewardSuccess: 'Selesai check-in {0}, dapatkan hadiahnya!'
    },
    shareCar: {
      share: 'Bagikan',
      shared: 'Dibagikan',
      carSharing: 'Berbagi Mobil',
      shareYourCar: 'Bagikan mobilmu',
      shareYourCarDesc: 'Anda dapat menambahkan pengguna baru untuk menikmati fitur ini',
      shareCar: 'Bagikan Mobil',
      shareCarDesc: 'Izinkan pengguna lain mengisi daya mobil Anda!',
      addUser: 'Tambah Pengguna',
      updateUser: 'Ubah Pengguna',
      addCarSharing: 'Tambah Berbagi Mobil',
      deleteUser: 'Hapus Pengguna',
      searchMemberId: 'Cari orang berdasarkan ID Anggota',
      searchInput: 'Masukkan ID anggota',
      userLoading: 'Harap tunggu, memuat anggota...',
      searchLoading: 'Harap tunggu, mencari ID anggota...',
      searchNotFound: 'ID anggota tidak ditemukan!',
      memberId: 'ID Anggota',
      confirmUser: 'Apakah ini orang yang Anda cari?',
      confirmDeleteUser: 'Apakah Anda yakin akan menghapus {0}?',
      confirmNo: 'Tidak, Cari Lagi',
      confirmYesContinue: 'Ya, Lanjutkan',
      confirmYesShare: 'Ya, Bagikan',
      confirmYesDelete: 'Ya, Hapus',
      confirmSubmit: 'Anda yakin ingin membagikan mobil Anda ke {0}?',
      cancel: 'Batal',
      sharingType: 'Jenis Berbagi',
      permanent: 'Permanen',
      temporary: 'Sementara',
      from: 'Dari',
      to: 'Ke',
      date: 'Tanggal',
      invalidDates: 'Harap isi semua tanggal',
      successShared: 'Berhasil Dibagikan',
      successDelete: 'Pengguna berhasil dihapus',
      successDesc: 'Anda telah berhasil membagikan mobil Anda ke {0}.',
      errorShareSelf: 'Anda tidak dapat berbagi mobil dengan diri sendiri.',
      gotoMyCar: 'Pergi ke Mobil Saya'
    },
    transaction: {
      transactionType: 'Tipe Transaksi',
      transactionStatus: 'Status Transaksi',
      transactionDate: 'Tanggal Transaksi',
      sortNewest: 'Terbaru',
      sortOldest: 'Terlama',
      sortAsc: 'Menaik',
      sortDesc: 'Menurun'
    },
    explore: {
      openInMaps: 'Buka Di Peta',
      permissionTitle: 'Nyalakan Lokasi',
      permissionMsg:
        'Untuk melanjutkan, nyalakan lokasi perangkat, yang menggunakan layanan lokasi Google.',
      permissionBtnOK: 'Oke',
      permissionBtnCancel: 'Tidak, Terima Kasih',
      filterProvice: 'Propinsi',
      filterCity: 'Kota',
      sortNearest: 'Terdekat',
      sortFurthest: 'Terjauh',
      placeholderSearch: 'Mencari',
      labelGetLocation: 'Mendapatkan lokasi Anda',
      notFoundTitle: 'Tidak Ada Data Yang Tersedia',
      notFoundDesc: 'Jelajahi Semua Fitur Myhyundai Sekarang',
      locationFound: '{0} Lokasi Ditemukan.',
      locationsFound: '{0} Lokasi Ditemukan.',
      nothingFound: 'Tidak Ada Yang Ditemukan.'
    },
    finance: {
      hyundaiFinance: 'Keuangan Hyundai',
      checkoutServices: 'Lihat Layanan Hyundai Lainnya',
      testDrive: 'Test Drive',
      buyCar: 'Buy a Car',
      getCreditPackageArea: 'Dapatkan Paket Kredit Di Daerah Anda',
      benefits: 'Keuntungan',
      financingProducts: 'Produk Pembiayaan',
      seeIllustration: 'Lihat Ilustrasi',
      illustration: 'Ilustrasi',
      getCreditPackage: 'Dapatkan Paket Kredit'
    },
    servicesAccessoriesScreen: {
      servicePromo: 'Layanan & Promo',
      servicePromoDesc: 'Jelajahi Fitur Layanan & Aksesoris Asli',
      serviceMyCar: 'Melayani Mobil Saya',
      genAcc: 'Aksesori Asli',
      contactUs: 'Hubungi Kami',
      specialOffer: 'Penawaran Istimewa',
      specialOfferDesc: 'Jelajahi Fitur Layanan & Aksesoris Asli',
      title: 'Layanan & Aksesori',
      latestDateService: 'Layanan Tanggal Terbaru',
      latestMileage: 'Jarak Tempuh Terbaru',
      serviceHistory: 'Riwayat Layanan',
      serviceReminder: 'Pengingat Layanan',
      bookService: 'Layanan Buku',
      bookCarService: 'Pesan Layanan Mobil',
      booking: 'Pemesanan',
      confirmation: 'Konfirmasi',
      quotation: 'Kutipan',
      payment: 'Pembayaran',
      addService: 'Tambahkan Layanan',
      generalRepair: 'Perbaikan Umum',
      mobileService: 'Layanan Seluler',
      bodyPaint: 'Tubuh & Cat',
      gotoDealer: 'Kunjungi Dealer',
      pickupOnly: 'Hanya Untuk Jemputan',
      deliveryOnly: 'Hanya Pengiriman',
      pickupDelivery: 'Pickup & Pengiriman',
      insurance: 'Pertanggungan',
      cash: 'Uang Tunai',
      repairSelection: 'Seleksi Perbaikan',
      selectRepairSelection: 'Pilih Pilihan Perbaikan',
      kmOdometer: 'Odometer KM',
      kmOdometerInfo:
        'Odometer KM Ini Hanya Dapat Ditampilkan Untuk Kendaraan Dengan Bluelink Terhubung',
      service: 'Melayani',
      addServiceDetail: 'Tambahkan Detail Layanan',
      deliveryMethod: 'Metode Pengiriman',
      selectDeliveryMethod: 'Pilih Metode Pengiriman',
      intervalSelection: 'Seleksi Interval',
      note: 'Catatan',
      generalService: 'Layanan Umum',
      accessories: 'Aksesoris',
      additional: 'Tambahan',
      total: 'Total',
      pickupLocation: 'Lokasi Penjemputan',
      addPickupLocation: 'Pilih lokasi Anda',
      serviceLocation: 'Lokasi',
      customerType: 'Tipe Pelanggan',
      selectCustomerType: 'Pilih Jenis Pelanggan',
      insuranceName: 'Nama Asuransi',
      notesDealer: 'Catatan Untuk Dealer',
      dealerLocationDate: 'Lokasi & Tanggal Dealer',
      addDealerLocationDate: 'Tambahkan Lokasi & Tanggal Dealer',
      takePhoto: 'Memotret',
      chooseFromGallery: 'Pilih dari galeri',
      attachment: 'Lampiran',
      addPhoto: 'Tambahkan Foto',
      date: 'Tanggal',
      time: 'Waktu',
      province: 'Propinsi',
      city: 'Kota',
      detailDealer: 'Dealer Detail',
      generalDetails: 'Detail Umum',
      dealerName: 'Nama Dealer',
      bookingService: 'Layanan Pemesanan',
      estimationComplete: 'Estimasi Selesai',
      contact: 'Kontak',
      otherContact: 'Kontak Lainnya',
      name: 'Nama',
      phoneNumber: 'Nomor Telepon',
      bookNow: 'Pesan Sekarang',
      bookBodyPaintConfirmDesc: 'Apakah Anda Yakin Untuk Memesan Perbaikan Tubuh Dan Cat?',
      cancel: 'Membatalkan',
      yesBookNow: 'Ya, Pesan Sekarang',
      summary: 'Ringkasan',
      laborDetail: 'Detail Tenaga Kerja',
      labor: 'Tenaga Kerja',
      hour: 'Jam',
      hours: 'Jam',
      price: 'Harga',
      partDetail: 'Bagian Detail',
      part: 'Bagian',
      qty: 'Qty',
      pcs: 'Pc',
      accDetail: 'Detail Aksesori',
      subTotal: 'Sub Total',
      ppn: 'Ppn',
      deliveryCharge: 'Biaya Pengiriman',
      promo: 'Promo',
      grandTotal: 'Hasil Akhir',
      totalTime: 'Total Waktu',
      gotoPayment: 'Pergi Ke Pembayaran',
      idOrderService: 'Layanan Pesanan Id',
      customerName: 'Nama Pelanggan',
      totalPrice: 'Total Harga',
      totalPriceDesc:
        'Total harga diperkirakan pembayaran, dan penyesuaian pembayaran akan dilakukan di dealer.',
      selectPaymentMethod: 'Pilih Metode Pembayaran',
      processPayment: 'Proses Pembayaran',
      enterPin: 'Masukkan Pin Aman Anda',
      problemDetecting: 'Mendeteksi Masalah',
      periodicMaintenance: 'Perawatan Berkala',
      serviceCampaign: 'Kampanye Layanan',
      problemDetectingDesc:
        'Fitur ini hanya dapat menampilkan informasi untuk kendaraan dengan Bluelink',
      status: 'Status',
      dtcCode: 'Kode Dtc',
      detectedDate: 'Tanggal Yang Terdeteksi',
      description: 'Keterangan',
      notFixed: 'Tidak Tetap',
      fixed: 'Tetap',
      discount: 'Diskon',
      estimationTime: 'Waktu Estimasi',
      periodicMaintenanceDesc:
        'Harga diperkirakan, mungkin sangat bergantung pada dealer yang dipilih.',
      repairSuggestion: 'Saran Perbaikan',
      parts: 'Bagian',
      items: 'Item',
      createdDate: 'Tanggal Yang Dibuat',
      estimationPrice: 'Harga Estimasi',
      estimationTotalPrice: 'Estimasi Total Harga',
      estimationTotalTime: 'Estimasi Total Waktu',
      repairSuggestionDesc:
        'Harga diperkirakan, mungkin sangat bergantung pada dealer yang dipilih.',
      repairSuggestionConfirm: 'Apa Kamu Yakin?',
      repairSuggestionConfirmDesc: 'Pesan sekarang untuk melayani mobil Anda.',
      subject: 'Subjek',
      code: 'Kode',
      validFrom: 'Berlaku Dari',
      validTo: 'Valid Ke',
      onProgress: 'Sedang Diproses',
      history: 'Riwayat',
      titlePaymentInfo: 'Informasi Pembayaran',
      labelOrderId: 'Id Pemesanan',
      labelExpired: 'Kedaluwarsa',
      labelWaitPayment: 'Menunggu Pembayaran',
      labelPaid: 'Dibayar',
      labelExpiredIn: 'Kedaluwarsa',
      lebelMinutes: 'Menit',
      labelSeconds: 'Detik',
      labelPaymentMethod: 'Cara Pembayaran',
      labelTransId: 'Id Transaksi',
      labelVANumber: 'Nomor Akun Virtual',
      labelHowToPay: 'Bagaimana Cara Membayar',
      labelReschedule: 'Menjadwalkan Ulang',
      labelCancelation: 'Pembatalan',
      labelAvailableDealer: 'Dealer Yang Tersedia',
      infoNotAvailable: 'Maaf, tidak ada slot yang tersedia. Pilih tanggal lain atau dealer lain.',
      labelSeeDelalerDetail: 'Lihat Detail Dealer',
      labelPaymentSucceed: 'Pembayaran Berhasil',
      labelPaymentDate: 'Tanggal Pembayaran',
      infoPayment:
        'Jika Anda melakukan pembayaran menggunakan e-wallet atau kartu kredit/debit, Anda telah menyelesaikan pembayaran Anda',
      infoPayment1:
        'Jika Anda melakukan pembayaran melalui transfer bank, silakan lanjutkan proses pembayaran.',
      labelPaymentFailed: 'Pembayaran gagal',
      labelReqTimeOut: 'Minta Waktu Keluar!',
      msgCancelAppointment: 'Batalkan Janji Dengan Sukses',
      descPaymentNotFound: 'Pembayaran Tidak Ditemukan',
      labelBookingDate: 'Tanggal Layanan Pemesanan',
      titleDisclaimer: 'Penafian!',
      descDisclaimer: 'Penjebak Ulang Maksimal Adalah 1X24 Jam Sebelum Layanan.',
      confirmReschedule: 'Oke, Penjadwalan Ulang',
      labelOrderService: 'Layanan Pesanan',
      labelServicesInfo: 'Info Layanan',
      labelDuration: 'Durasi Waktu',
      labelScheduleDetail: 'Jadwalkan Detail',
      labelRescheduleDate: 'Tanggal Penjadwalan Ulang',
      labelAddToCart: 'Masukkan Ke Keranjang',
      labelMyCart: 'Keranjang Saya',
      cartNotFound: 'Keranjang saya masih kosong',
      descCartNotFound: 'Jelajahi aksesori kami yang luar biasa sekarang!',
      labelCheckOut: 'Periksa',
      labelHelpCenter: 'Pusat Bantuan',
      textNotsubscribtion: 'Anda tidak memiliki langganan',
      btnBuyVoucher: 'Beli Voucher',
      textIsSubscribtion: 'Anda telah berlangganan untuk mengambil & layanan pengiriman',
      btnSubscription: 'Langganan',
      titleTypeSubscription: 'Jenis',
      textVoucherSubscription: 'Voucher',
      titleSuccessSubscription: 'Langganan Anda telah berhasil',
      descSuccessSubscription: 'Anda dapat menemukan voucher aktif Anda di halaman hadiah saya.',
      btnSuccessSubscription: 'Layanan Kembali ke Mobil',
      viewSubscription: 'Anda belum berlangganan',
      btnSeeVoucher: 'See Voucher',
      infoSubscriptionTitle: 'Information',
      infoSubscriptionDesc:
        'Anda tidak memiliki langganan apa pun untuk mobil ini. Berlangganan layanan Pick Up & Delivery dan temukan cara yang lebih nyaman untuk perbaikan mobil Anda!',
      infoSubscriptionCancel: 'Kembali',
      infoSubscriptionSubscribe: 'Langganan',
      btnReedemVoucher: 'Tukarkan Voucher',
      paymentFeeInfo: 'Anda akan dikenakan biaya admin sebesar {0}',
      paymentFeePercentInfo: 'Anda akan dikenakan biaya admin sebesar {0} dari total transaksi',
      createBooking: 'Buat Booking'
    },
    verifyCarOwner: {
      title: 'Verifikasi Kepemilikan Mobil',
      labelSubTitle:
        'Sebagai pemilik hukum kendaraan Hyundai dengan data terperinci sebagai berikut.',
      labelProfile: 'Profil',
      labelCarDetail: 'Detail Mobil',
      labelOtherDoc: 'Dokumen Lainnya',
      labelSalutation: 'Sapaan',
      labelFullName: 'Nama Lengkap',
      labelDateOfBirth: 'Tanggal Lahir',
      labelCountryCode: 'Kode Negara',
      labelPhone: 'Nomor Telepon',
      labelEmail: 'Surel',
      labelProvince: 'Propinsi',
      labelCity: 'Kota',
      labelDistrict: 'Daerah',
      labelPostalCode: 'Kode Pos',
      labelAddress: 'Alamat',
      labelVINNumber: 'Vin (Nomor Identitas Kendaraan)',
      labelModel: 'Model',
      labelTrim: 'Memangkas',
      labelColor: 'Warna',
      labelYear: 'Tahun',
      labelCarRegisNm: 'Nama Surat Pendaftaran Mobil',
      placeholderCarRegisNm: 'Masukan Nama Surat Pendaftaran Mobil',
      labelSTNK: 'Nama Stnk/Stck',
      labelCarRegisDate: 'Surat Pendaftaran Mobil Tanggal Valid',
      labelSTNK_Date: 'Nama Stnk/Stck Tanggal Yang Valid',
      labelCarRegisNum: 'Nomor Surat Pendaftaran Mobil',
      placeholderCarRegisNum: 'Nomor Surat Registrasi Mobil Mobil',
      labelSTNK_Num: 'Nomor Stnk/Stck',
      labelLicensePlateNumb: 'Nomor Plat Nomor',
      placeholderLicensePlateNumb: 'Misalnya H 1234 Idn',
      labelCarRegisPhoto: 'Foto Surat Pendaftaran Mobil (Opsional)',
      labeleSlectPhoto: 'Pilih Foto',
      labelSTNK_Poto: 'Foto Stnk/Stck',
      labelKTP_Photo: 'Foto Kartu Identitas (Ktp/Kitas)',
      labelOwner_Proof: 'Bukti Kepemilikan (Opsional)',
      labelCarUnderCompanyName: 'Mobil ini dengan nama perusahaan?',
      labelFooter:
        'Dengan Demikian, Pernyataan Kepemilikan Kendaraan Ini Diisi Dengan Data Yang Akurat Dalam Keadaan Sadar, Sukarela Dan Tanpa Negara Paksaan. Dan Dengan Memeriksa Tanda "Setuju", Saya Menyatakan Bahwa Saya Adalah Pemilik Hukum Kendaraan Dari Nomor Vin (Bingkai Kendaraan) Sebagaimana Dinyatakan Dalam Formulir Ini.',
      labelAgree: 'Saya Setuju',
      labelEmployeeIDPhohto: 'Foto Id Karyawan',
      labelBusinessCardPhoto: 'Foto Kartu Nama (Opsional)',
      labelSubTitleValide:
        'Harap masukkan profil terdaftar sesuai dengan formulir pembelian yang Anda miliki saat membeli mobil.',
      placeholderModel: 'Pilih Model',
      placeholderTrim: 'Pilih Trim',
      placeholderColor: 'Pilih Warna',
      placeholderYear: 'Pilih Tahun',
      successRegisTitle: 'Berhasil Pendaftaran',
      successWaitingTitle: 'Menunggu Persetujuan',
      successRegisSubTitle: 'Mobil Anda berhasil terdaftar',
      successWaitSubTitle: 'Pendaftaran mobil Anda sedang menunggu',
      buttonTitle: 'Pergi Ke Mobil Saya',
      validationFormTitle: 'Formulir Validasi',
      LicensePlatePlaceholder: 'Misalnya: H1234Idn'
    },
    exclusiveDealScreen: {
      labelTabBenefits: 'Manfaat Anggota',
      labelTabRewards: 'Hadiah Mobil Baru',
      confirmTitle: 'Konfirmasi',
      confirmDesc: 'Apakah Anda yakin ingin memilih',
      confirmYes: 'Ya, Pilih',
      confirmCancel: 'Membatalkan',
      labelButtonAddThisPackeage: 'Tambahkan Paket Ini',
      labelButtonDetail: 'Lihat Detailnya',
      labelPackageByCategory: 'Paket Berdasarkan Kategori',
      labelPackageByBrand: 'Paket Berdasarkan Merek',
      labelNotFoundTitle: 'Tidak ada paket yang tersedia',
      labelNotFoundDesc: 'Paket hanya tersedia untuk mobil khusus',
      labelNotFoundTitle1: 'Tidak Ada Hasil Yang Ditemukan',
      labelNotFoundCategory: 'Paket berdasarkan kategori tidak ditemukan',
      labelNotFoundBrand: 'Paket berdasarkan merek tidak ditemukan',
      labelButtonChoosed: 'Anda sudah memilih paket ini',
      labelPackage: 'Paket ?',
      titleyourPackage: 'Paket Anda',
      labelButtonSeeBenefits: 'Lihat Manfaat',
      labelDetailBenefits: 'Manfaat Detail',
      labelBestChoice: 'Pilihan Terbaik',
      titleExclusiveBenefits: 'Manfaat Eksklusif',
      buttonChoosePackage: 'Pilih Paket',
      labelNotFoundSelected: 'Tidak ada paket yang dipilih',
      labelNotFoundDescSelected: 'Pilih paket Anda, untuk mendapatkan penawaran khusus.',
      labelWaitLoading: 'Harap tunggu, memuat ...'
    },
    updateStnkScreen: {
      title: 'Perbarui Data Stnk/Stck',
      labelLicense: 'Nomor Plat Nomor',
      labelCarRegisName: 'Nama Surat Pendaftaran Mobil',
      stnkName: 'Nama Stnk/Stck',
      placeHolderRegisName: 'Masukan Nama Surat Pendaftaran Mobil',
      labelCarRegisDate: 'Surat Pendaftaran Mobil Tanggal Valid',
      placeHolderRegisDate: 'Dd-Mm-Yy',
      stnkDate: 'Nama Stnk/Stck Tanggal Yang Valid',
      labelCarRegisNumber: 'Nomor Surat Pendaftaran Mobil',
      stnkNumber: 'Nomor Stnk/Stck',
      placeHolderRegisNumber: 'Nomor Surat Registrasi Mobil Mobil',
      labelCarRegisPhoto: 'Foto Surat Pendaftaran Mobil',
      stnkPhoto: 'Foto Stnk/Stck',
      btnSave: 'Menyimpan',
      titleModal: 'Mengunggah Foto',
      labelTakePhoto: 'Memotret',
      labelGalery: 'Pilih Dari Galeri',
      infoStnkName: 'Nama Stnk/Stck',
      infoStnkDate: 'Nama Stnk/Stck Tanggal Yang Valid',
      infoStnkNumber: 'Nomor Stnk/Stck',
      infoStnkFoto: 'Foto Stnk/Stck'
    },
    myCarScreen: {
      title: 'Mobil Saya',
      labelVinNumber: 'Nomor Vin',
      labelModelYear: 'Tahun Model',
      labelNotes: 'Catatan',
      labelEngineNumber: 'Nomor Mesin',
      labelPurchaseDate: 'Tanggal Pembelian',
      modalHeadRegis: 'Daftar Mobil',
      textModalVin: 'Nomor VIN sama dengan mobil Anda (nomor sasis), panjang harus 17 karakter.',
      buttonModalRegis: 'Mengesahkan',
      buttonCaptionCS: 'Layanan Pelanggan Myhyundai',
      labelCaptionCsPhone: 'Hubungi Layanan Pelanggan',
      labelCaptionCsWa: 'Layanan Pelanggan Whatsapp',
      headerLabelDetailCar: 'Detail Mobil',
      myCarTitle: 'Belum Ada Mobil Terdaftar',
      myCarDesc:
        'Dapatkan fasilitas khusus dan berbagai manfaat saat Anda mendaftarkan mobil Hyundai',
      buttonMyCar: 'Daftarkan Mobil Hyundai',
      otherInfomationDetailCar: 'Informasi Lainnya',
      carWarrantyDetailCar: 'Garansi Mobil',
      batteryWarrantyDetailCar: 'Garansi Baterai',
      carRegistrationLetterDetailCar: 'Surat Pendaftaran Mobil',
      modalHeadDetailCar: 'Data Pendaftaran',
      licensePlateNumberDetailCar: 'Nomor Plat Nomor',
      carRegisStnkNameDetailCar: 'Surat Pendaftaran Mobil (Stnk/Stck) Nama',
      carRegisStnkDateDetailCar: 'Surat Pendaftaran Mobil (Stnk/Stck) Tanggal Yang Valid',
      carRegisStnkNumberDetailCar: 'Nomor Surat Pendaftaran Mobil (Stnk/Stck)',
      carRegisStnkFotoDetailCar: 'Foto Surat Pendaftaran Mobil',
      buttonUploadModalDetailCar: 'Perbaharui Data',
      modalInfoTItle1: 'Vin Tidak Ditemukan',
      modalInfoDesc1: 'Maaf, kendaraan dengan Vin',
      modalInfoDescNext:
        'tidak dapat ditemukan di sistem kami. Apakah Anda ingin terus mendaftarkan nomor VIN ini?',
      modalInfoConfirm1: 'Ya',
      modalInfoClose1: 'Tidak',
      modalInfoTItle2: 'Verifikasi Kepemilikan',
      modalInfoDesc2:
        'Verifikasi kepemilikan Anda dengan mengirimkan informasi dalam formulir pembelian Anda.',
      modalInfoConfirm2: 'Saya Akan Memverifikasi',
      modalInfoClose2: 'Mungkin Nanti',
      modalInfoTItle3: 'Apa kamu yakin?',
      modalInfoDesc3: 'Anda kehilangan kesempatan untuk mendapatkan hadiah yang luar biasa.',
      modalInfoConfirm3: 'Saya Akan Memverifikasi',
      modalInfoClose3: 'Ya',
      modalInfoRegisTItle: 'Batalkan Pendaftaran',
      modalInfoRegisDesc: 'Apakah Anda yakin membatalkan pendaftaran mobil ini?',
      modalInfoRegisConfirm: 'Ya, Batalkan',
      modalInfoRegisClose: 'Tidak',
      labelIsYourCar: 'Apakah Ini Mobilmu?',
      textMoreInfo: 'Informasi Lebih Lanjut',
      modalCancelSucces: 'Pendaftaran mobil Anda berhasil membatalkan',
      labelModalCarDetail: 'Data Pendaftaran',
      buttonConatctCS: 'Hubungi Layanan Pelanggan',
      buttonCancelRegis: 'Batalkan Pendaftaran',
      labelTabRegisStatus: 'Status Pendaftaran',
      filterAll: 'Semua',
      filterPending: 'Tertunda',
      filterApproved: 'Disetujui',
      labelManualBook: 'Buku Panduan',
      labelBookServices: 'Layanan Buku',
      labelDeliveryDate: 'Tanggal Pengiriman',
      carOwnershipData: 'Data Kepemilikan Mobil'
    },
    servicesDetail: {
      title: 'Detail Layanan',
      serviceInfoTitle: 'Info Layanan',
      statusInfo: 'Status',
      bookingInfoCode: 'Kode Pemesanan',
      invoiceInfo: 'Nomor Faktur.',
      bookingInfoDate: 'Tanggal Layanan Pemesanan',
      receiveInfoDate: 'Tanggal Menerima',
      repairInfo: 'Seleksi Perbaikan',
      deliveryInfo: 'Metode Pengiriman',
      platInfo: 'Pelat',
      oddoMeterInfo: 'Odometer Terdaftar',
      detailDealerTitle: 'Dealer Detail',
      dealerName: 'Nama Dealer',
      dealerPhone: 'Telepon Dealer',
      servicesAdvisorName: 'Nama Penasihat Layanan',
      detailServicesTitle: 'Layanan Detail',
      laborDetail: 'Detail Tenaga Kerja',
      labor: 'Tenaga Kerja',
      hour: 'Jam',
      price: 'Harga',
      partDetail: 'Bagian Detail',
      part: 'Bagian',
      qty: 'Qty',
      accDetail: 'Detail Aksesori',
      bookingCode: 'Kode Pemesanan',
      noInvoice: 'Nomor Faktur.',
      bookingDate: 'Tanggal Layanan Pemesanan',
      receiveDate: 'Tanggal Menerima',
      repairSelection: 'Seleksi Perbaikan',
      deliveryMethod: 'Metode Pengiriman',
      platNumber: 'Pelat',
      odoMeter: 'Odometer Terdaftar',
      subTotal: 'Sub Total',
      ppn: 'Ppn 11%',
      total: 'Total',
      promo: 'Promo',
      grandTotal: 'Hasil Akhir',
      totalTime: 'Total Waktu',
      deliveryCharge: 'Biaya Pengiriman',
      event: 'Acara',
      repairNumber: 'Nomor Perbaikan'
    },
    partnerDealsScreen: {
      title: 'Penukaran Hadiah',
      sortAsc: 'Urutan Menaik Berdasarkan Nama Mitra',
      sortDesc: 'Urutan Menurun Berdasarkan Nama Mitra',
      sortHigh: 'Koin Tertinggi',
      sortLow: 'Koin Terendah',
      notFoundTitle: 'Tidak Ada Hasil Yang Ditemukan',
      notFoundDesc: 'Harap Cari Penawaran Mitra Lainnya',
      titleLoadingSearch: 'Harap Tunggu, Mencari ...',
      noMoreData: 'Tidak ada lagi data saat ini',
      titleRenderEmpty: 'Tidak Ada Hasil Yang Ditemukan',
      descRenderEmpty: 'Harap cari penawaran mitra lainnya',
      tabInformation: 'Informasi',
      tabTC: 'T & C',
      btnReedem: 'Menukarkan',
      modalInfoTitle: 'Konfirmasi',
      modalInfoDesc: 'Apakah Anda yakin ingin menebus voucher ini?',
      modalInfoConfirm: 'Ya',
      modalInfoCancel: 'Tidak',
      succesInfoTitle: 'Tebus Kesuksesan',
      succesInfoDesc: 'Anda dapat menemukan voucher aktif Anda di halaman hadiah saya.',
      succesInfoCaption: 'Lihat Imbalan Saya',
      succesInfoBack: 'Kembali Ke Penawaran Mitra',
      titleDetailDeals: 'Penawaran Detail',
      detailDealsCoins: 'Koin',
      detailDealsReedem: 'Menukarkan',
      detailDealsPeriod: 'Periode Penawaran',
      detailDealsYourCoins: 'Koin Anda'
    },
    myRewardsScreen: {
      title: 'Imbalan Saya',
      tabActive: 'Aktif',
      tabClaimed: 'Diklaim & Kedaluwarsa',
      buttonUseVoucher: 'Gunakan Voucher',
      confirmTitle: 'Apa Kamu Yakin',
      confirmDesc:
        'Pastikan Anda sudah menggunakan voucher! Jika Anda menandai voucher ini seperti yang digunakan, Anda tidak akan dapat melihat voucher lagi.',
      confirmDesc1: 'Voucher dapat ditukar di kasir.',
      confirmDesc2: 'Kode Voucher Anda:',
      buttonCancel: 'Membatalkan',
      buttonYes: 'Ya',
      confirmTitlePackage: 'Konfirmasi',
      confirmDescPackage: 'Apakah Anda yakin ingin memilih paket keluarga?',
      confirmDescPackage1: 'Anda tidak dapat mengubah atau memilih paket lain pada proses fitur.',
      buttonYesPackage: 'Ya, Pilih',
      buttonCancelPackage: 'Membatalkan',
      confirmDescOthers: 'Apakah Anda yakin ingin menebus voucher ini?'
    },
    successScreen: {
      titleConfirmation: 'Berhasil',
      titleRedeemSuccess: 'Penukaran Berhasil',
      titleMember: 'Selamat',
      titlePackage: 'Pilih Sukses Paket',
      titleDetailDeals: 'Klaim Voucher Berhasil',
      labelSuccessfully: 'Berhasil',
      descConfirmation: 'Keberhasilan Tubuh Dan Cat Untuk Pemesanan.',
      descRedeem: 'Anda dapat menemukan voucher aktif Anda di halaman hadiah saya.',
      descMember: 'Anda anggota kulit hitam sekarang',
      descDetailDeals: 'Langkah voucher untuk mengklaim & kedaluwarsa',
      captionBtnConfirmation: 'Lihat Kemajuan',
      captionBtnRedeem: 'Pergi Ke Imbalan Saya',
      captionBtnMember: 'Oke, Pergi Ke Beranda',
      captionBtnDetailDeals: 'Pergi Ke Klaim & Kedaluwarsa',
      captionBtnRedeem1: 'Kembali Ke Manfaat Eksklusif'
    },
    whatsOnScreen: {
      categoryHighlight: 'Menyorot',
      categoryTrending: 'Tren',
      categoryAll: 'Semua Kategori'
    },
    bluelinkScreen: {
      lastUpdated: 'Terakhir Diperbarui',
      fuel: 'Bahan Bakar',
      distanceToEmpty: 'Jarak Menjadi Kosong',
      km: 'KM',
      engine: 'Mesin',
      climateControlStatus: 'Status Kontrol Iklim',
      doorStatus: 'Status Pintu',
      others: 'Yang Lain',
      vehicleHealthReport: 'Laporan Kesehatan Kendaraan',
      informYouRegular: 'Memberitahu Anda tentang hasil inspeksi kendaraan biasa.',
      receivingVehicleStatus: 'Menerima Status Kendaraan.',
      batteryStatus: 'Status Baterai',
      connected: 'Terhubung',
      locked: 'Terkunci',
      Unlocked: 'Tidak Terkunci',
      detail: 'Detail',
      opened: 'Dibuka',
      closed: 'Tertutup',
      normal: 'Normal',
      warning: 'Peringatan',
      airConditioning: 'Ac',
      windscreenDefogger: 'Defogger Kaca Depan',
      tailgate: 'Tailgate',
      bonnet: 'Kap Mesin',
      door: 'Pintu',
      battery: 'Baterai',
      odometer: 'Odometer',
      totalDistanceTravelled: 'Total Jarak Yang Ditempuh',
      numberEngineStarts: 'Jumlah Mesin Mulai',
      times: 'Waktu',
      totalOnTime: 'Total - Tepat Waktu',
      totalDrivingTime: 'Total Waktu Mengemudi',
      totalIdleTime: 'Total Waktu Idle',
      vehicleStatus: 'Status Kendaraan',
      airbagControlSystem: 'Sistem Kontrol Airbag',
      tirePressureMonitorySystem: 'Sistem Monitori Tekanan Ban',
      driverAssistanceSystem: 'Sistem Bantuan Pengemudi',
      integratedBrakingBody: 'Sistem Pengereman Dan Kontrol Tubuh Terpadu',
      reportHistory: 'Riwayat Laporan Kendaraan',
      receivingVehicleReport: 'Menerima Laporan Kendaraan.',
      charging: 'Pengisian Daya',
      notPluggedIn: 'Tidak Terhubung'
    },
    trxHistAirPortScreen: {
      title: 'Bantuan Vip Bandara',
      msgRescheduleFailed: 'Penjadwalkan Jadwal Pemesanan Bandara Bantuan Vip Gagal',
      msgCancelFailed: 'Batalkan Bantuan Bantuan Bandara Vip Gagal'
    },
    trxHistCityLoungeScreen: {
      msgRescheduleSuccess: 'Pemesanan Penjadwalan Ulang Dengan Sukses',
      msgRescheduleFailed: 'Penjadwalan Penjadwalkan Ulang Lounge Kota Gagal',
      msgCancelBooking: 'Batalkan Pemesanan Dengan Sukses',
      msgCancelFailed: 'Batalkan City Lounge Gagal',
      title: 'Lounge Kota',
      labelTermCondition: 'Syarat & Kondisi'
    },
    trxHistEVScreen: {
      labelChargingDate: 'Tanggal Pengisian Daya',
      labelKWHUsage: 'Penggunaan kWh',
      labelChargingDuration: 'Durasi Pengisian Daya',
      labelPlugType: 'Jenis Plug',
      labelSpeed: 'Kecepatan',
      labelLocationDetail: 'Detail Lokasi',
      labelPlace: 'Tempat',
      labelFloor: 'Lantai',
      labelOperatioHours: 'Jam Operasi',
      titleEVCharging: 'Ev Pengisian',
      descNotFound: 'Transaksi EV pengisian tidak ditemukan'
    },
    trxHistGenAccScreen: {
      labelTotalPayment: 'Total Pembayaran',
      labelPurchaseDetail: 'Dibeli',
      labelDetailPayment: 'Pembayaran Detail',
      labelCancelOrder: 'Batalkan Pesanan',
      msgCancelSuccess: 'Transaksi Berhasil Dibatalkan',
      labelInvoice: 'Faktur',
      confirmCancelDesc: 'Apakah Anda pasti akan membatalkan pesanan ini?'
    },
    trxHistLoungeScreen: {
      labelLoungeAirport: 'Bantuan Lounge & Bandara Vip',
      labelType: 'Jenis',
      labelBookingDetail: 'Detail Pemesanan',
      labelLounge: 'Ruang Santai',
      labelMeeting: 'Titik & Waktu Pertemuan',
      buttonCancel: 'Membatalkan',
      buttonReschedule: 'Menjadwalkan Ulang',
      confirmResTitle: 'Konfirmasi',
      confirmResDesc: 'Apakah Anda pasti menjadwal ulang pemesanan Anda di',
      confirmCancelDesc: 'Apakah Anda yakin membatalkan pemesanan Anda di',
      confirmCancelOK: 'Oke, Batal'
    },
    trxHistParking: {
      title: 'Parkir & Pickup',
      msgRescheduleFailed: 'Escort Pemesanan Jadwal Jadwalkan Gagal',
      msgCancelFailed: 'Batalkan Escort Pemesanan Gagal',
      labelCheckIn: 'Mendaftar',
      labelCheckOut: 'Periksa'
    },
    trxHistVoucherScreen: {
      labelDeelsPeriod: 'Periode Deel'
    },
    idMigration: {
      title: 'Proses Migrasi Id',
      labelSearchYourAccount: 'Cari Akun Anda',
      labelSearchResults: 'Hasil Pencarian',
      labelChooseIDToUse: 'Pilih Id Untuk Digunakan',
      errMessageNext: 'Pilih akun',
      loadingDesc: 'Harap tunggu, tunggu proses selesai ...',
      titleCancelIdMigration: 'Batalkan Proses Integrasi',
      descCancelIdMigration: 'Apakah Anda pasti akan membatalkan semua proses Hyundai One ID?',
      labelYesCancelIdMigration: 'Ya',
      labelNoCancelIdMigration: 'Tidak, Lanjutkan',
      labelHi: 'Hai,',
      labeldescIdMigration:
        'Kami akan menghubungkan semua akun Hyundai Anda menjadi satu, silakan masukkan nomor telepon Anda yang valid untuk memulai migrasi.',
      labelPhoneNumber: 'Nomor Telepon',
      labelBtnPleaseWait: 'Harap Tunggu',
      labelBtnContinue: 'Melanjutkan',
      titleOtpCode: 'Verifikasi Kode Otp',
      descOtpCodeA: 'Kode dikirim ke nomor telepon Anda',
      descOtpCodeB: 'Silakan periksa kotak masuk Anda dan salin kode OTP di sini.',
      btnVerifyOtp: 'Verifikasi Otp',
      labelOtpExpiredIn: 'OTP kedaluwarsa',
      labelMin: 'Min',
      labelSecond: 'Kedua',
      textDidntReceiveCode: 'Tidak menerima kode?',
      textResendAnOtpCodeIn: 'Mengirim Ulang Kode Otp Di',
      btnResendOtp: 'Resend Otp',
      labelPleaseWait: 'Harap Tunggu',
      labelVerifyOtp: 'Verifikasi Otp',
      labelWelcomeTo: 'Selamat Datang Di',
      labelHyundaiOneId: 'Hyundai One Id',
      labelSignIn: 'Masuk',
      descSignIn:
        'Masuk menggunakan email Anda, Anda juga dapat menggunakan akun "Bluelink" atau "klik untuk membeli" untuk melanjutkan.',
      placeholderEmailAddress: 'Alamat Email',
      labelLoadingPleaseWait: 'Harap Tunggu...',
      btnFindID: 'Temukan Id',
      labelNotMember: 'Bukan Seorang Anggota?',
      btnRegister: 'Daftar',
      labelForgotYourId: 'Lupa Id Anda?',
      descForgotYourId: 'Harap isi formulir di bawah ini untuk mendapatkan ID Anda.',
      labelPhoneNumberMandatory: 'Nomor Telepon *',
      labelVerificationCodeMust: 'Kode verifikasi harus diisi dalam 5 menit.',
      labelNotYetReceiveVerificationCode:
        'Jika Anda belum menerima kode verifikasi atau mendapatkan waktu batas, silakan klik "Kirim Ulang Kode".',
      labelBtnSendVerificationCode: 'Kirim Kode Verifikasi',
      labelResendCodeIn: 'Mengirimkan Kode Masuk',
      placeholderVerificationCode: 'Kode Verifikasi',
      labelBtnSubmit: 'Kirim',
      labelSuccess: 'Kesuksesan',
      descPleaseFindYourID: 'Silakan temukan ID Anda untuk mengakses akun Anda.',
      btnGotoLogin: 'Pergi Ke Login',
      errUserWasNotFound: 'Pengguna tidak ditemukan',
      infoFoundSomeEmails: 'Kami menemukan beberapa email yang menggunakan email:',
      infoAndMobilePhone: 'dan ponsel:',
      infoPleaseVerifyAdditionalAccount:
        'Harap verifikasi akun tambahan untuk diintegrasikan, Anda dapat memilih satu ID email per platform.',
      infoLetsHighlightOneId:
        'Mari kita sorot "satu ID" per platform. Semua manfaat dari setiap ID Myhyundai yang Anda diverifikasi akan dikombinasikan dengan Hyundai One ID Anda.',
      labelPlatform: 'Platform',
      infoAccountPlatformNotFound: 'Akun untuk platform ini tidak ditemukan!',
      infoDoYouUserEmailForOtherPlatforms: 'Apakah Anda pengguna email untuk platform lain?',
      labelSearchAdditionalAccount: 'Cari Akun Tambahan',
      labelVerified: 'Diverifikasi',
      titleDeleteEmailID: 'Hapus Id Email',
      descDeleteEmailID: 'Apakah Anda pasti akan menghapus akun tambahan?',
      btnNo: 'Tidak',
      btnYesDelete: 'Ya, Hapus',
      labelAddAdditionalAccount: 'Tambahkan Akun Tambahan',
      titleAddAccountConfirmation: 'Tambahkan Konfirmasi Akun',
      descAddAccountConfirmation: 'Apakah Anda pasti akan melanjutkan menambahkan akun?',
      btnYesAddAccount: 'Ya, Tambahkan Akun',
      errFailedToAddYourAccount: 'Gagal Menambahkan Akun Anda',
      titleCancelAddingAccount: 'Batalkan Akun Penambahan',
      descCancelAddingAccount: 'Apakah Anda pasti akan membatalkan mencari akun tambahan?',
      btnYesCancel: 'Ya, Batalkan',
      labelVerificationSucceded: 'Verifikasi Berhasil',
      labelPLeaseChooseAccountsToIntegrate: 'Pilih akun untuk diintegrasikan.',
      labelMyHyundai: 'Myhyundai',
      labelClickToBuy: 'Klik Untuk Membeli',
      labelBluelink: 'Bluelink',
      labelCancelAdditionalAccount: 'Batalkan Akun Tambahan',
      labelAddSearchedAccount: 'Tambahkan Akun Yang Dicari',
      labelNotVerified: 'Tidak Diverifikasi',
      descEmailA: 'Kode dikirim ke email Anda',
      descEmailB: '. Silakan periksa kotak masuk Anda dan salin kode OTP di sini.',
      btnOk: 'Oke',
      labelInsertYourEmailOrPhone: 'Masukkan email atau nomor telepon Anda untuk mencari',
      placeholderEmailOrPhoneNumber: 'Email Atau Nomor Telepon',
      labelSearch: 'Mencari',
      labelPleaseChooseRepresentativeEmailForLogin:
        'Pilih email yang representatif untuk digunakan untuk masuk. Alamat email ini akan menjadi ID Anda untuk semua platform digital Hyundai - Myhyundai / Bluelink / klik untuk membeli. Juga, semua data layanan di bawah alamat email berikut akan secara otomatis terhubung ke ID Anda.',
      labelYouCanAlsoUseIDFromPlatformCreateHyundaiOneID:
        'Anda juga dapat menggunakan ID dari satu platform untuk membuat Hyundai One ID. Proses ini hanya akan dilakukan sekali dan Anda tidak akan diminta untuk mengulang proses ini lagi.',
      labelCongratulations: 'Selamat',
      labelYouHaveFinishedIDMigrationProcess: 'Anda telah menyelesaikan proses migrasi ID',
      labelYears: 'Bertahun-Tahun',
      labelYear: 'Tahun',
      labelMonth: 'Bulan',
      labelDay: 'Hari',
      labelMakeAProfile: 'Membuat Profil',
      labelYouAreCurrentlyMigratingBluelinkID:
        'Anda saat ini sedang memigrasi ID Bluelink, Anda akan dapat mengubah informasi profil Anda nanti setelah proses migrasi.',
      labelFullName: 'Nama Lengkap',
      placeholderYourFullName: 'Nama Lengkap Anda',
      errYourNameIsRequired: 'Nama Anda Diperlukan',
      placeholderDOB: 'Dob',
      labelDateOfBirth: 'Tanggal Lahir',
      placeholderSelectADate: 'Pilih Tanggal',
      placeholderPhoneNumber: 'Nomor Telepon',
      errPhoneNumberRequired: 'Nomor Telepon Anda Diperlukan',
      labelEmail: 'Surel',
      placeholderEmail: 'Surel',
      labelCreateNewPassword: 'Buat Kata Sandi Baru *',
      placeholderPassword: 'Kata Sandi',
      labelText: 'Teks',
      infoUppercaseLowercaseNumberSpecialCharacter:
        'Kata sandi Anda harus lebih kuat. Itu harus memiliki setidaknya 8 karakter, termasuk 1 angka.',
      labelConfirmPassword: 'Konfirmasi Sandi *',
      placeholderConfirmPassword: 'Konfirmasi Sandi',
      labelPasswordDoesntMatch: 'Kata Sandi Tidak Cocok',
      labelPIN: 'Pin *',
      placeholderPIN: 'Pin - 4 Digit Nomor',
      labelInput4DigitsPIN: 'Masukkan Pin 4 Digit',
      labelConfirmPIN: 'Konfirmasi Pin *',
      placeholderConfirmPIN: 'Konfirmasi Pin',
      errConfirmPIN: 'Pin Tidak Cocok',
      labelIAgreeTo: 'Saya Setuju',
      labelPrivacyPolicy: 'Kebijakan Pribadi',
      labelMyHundaiIndonesia: 'Myhyundai Indonesia',
      labelWeFoundSomeAccountsThatUsingEmail: 'Kami menemukan beberapa akun yang menggunakan email',
      labelPleaseVerifyAdditionalAccountToIntegrate:
        'Harap verifikasi akun tambahan untuk mengintegrasikan'
    },
    'idMigration ': {
      labelPrivacyPolicy: 'Kebijakan Privasi'
    },
    shareCoins: {
      coinsDetail: 'Detail Koin',
      shareCoins: 'Koin Share',
      shareSuccess: 'Berhasil Dibagikan',
      shareSuccessDesc: 'Anda telah berhasil membagikan koin {0} Anda ke {1}.',
      goHome: 'Pergi ke rumah',
      shareConfirmDesc: 'Apakah Anda yakin ingin berbagi koin {0} Anda ke {1}?',
      yesShare: 'Ya, Bagikan',
      shareFailedNoMember: 'Tidak dapat berbagi koin. Tidak ada anggota yang dipilih.',
      shareFailed: 'Gagal berbagi koin.',
      share: 'Membagikan',
      cancel: 'Membatalkan',
      searchNotFound: 'ID Anggota tidak ditemukan!',
      errorShareSelf: 'Anda tidak dapat berbagi koin dengan diri Anda sendiri.',
      searchMemberId: 'Cari orang dengan ID Anggota',
      searchInput: 'ID Anggota Input',
      searchLoading: 'Harap tunggu, temukan ID Anggota ...',
      memberId: 'Tanda Anggota',
      confirmUser: 'Apakah ini orang yang Anda cari?',
      confirmNo: 'Tidak, Cari Lagi',
      confirmYesContinue: 'Ya, Lanjutkan',
      coinsBalance: 'Keseimbangan Koin',
      amountToShare: 'Jumlah untuk dibagikan',
      shareFailedInsufficientAmount: 'Anda memiliki keseimbangan koin yang tidak mencukupi'
    },
    testDrive: {
      title: 'Uji jalan',
      'labelSwitch ': 'Data Ini Untuk Orang Lain?',
      labelInstagram: 'Instagram (opsional)',
      labelCarModel: 'Model mobil',
      labelLicence: 'Surat ijin Mengemudi',
      titleReffCode: 'Apakah Anda Memiliki Kode Rujukan?',
      labelSales: 'Konsultan Penjualan',
      labelReffCode: 'Kode rujukan (opsional)',
      labelEvent: 'Peristiwa',
      titleSelectCity: 'Pilih Kota',
      titleSucces: 'Selamat',
      descSucces: 'Pemesanan Test Drive Anda Telah Dibuat',
      BtnSucces: 'Lihat Transaksi Detail',
      labelAddOther: 'Tambahkan orang lain',
      textInstagram: 'Instagram',
      btnComplete: 'Menyelesaikan',
      titleModalCancel: 'Batalkan test drive',
      descModalCancel: 'Apakah Anda Pasti Akan Membatalkan Test Drive Ini?',
      cancelSucces: 'Test drive berhasil dibatalkan',
      TitleCompleteDrive: 'Test drive lengkap',
      textVenue: 'Lokasi',
      labelSelfie: 'Selfie dengan mobil',
      labelRating: 'Peringkat',
      modalCompleteTitle: 'Test drive selesai',
      modalCOmpleteDesc: 'Tambahkan Survei Anda Sekarang Untuk Menyelesaikan Test Drive Anda',
      modalCompleteBtn: 'Tambahkan survei sekarang',
      warnSuccesComplete: 'Test Drive Telah Selesai',
      titleAddSurvey: 'Tambahkan survei sekarang'
    },
    questionnaire: {
      title: 'Kuisioner',
      select: 'Pilih',
      termsCondition: 'Syarat dan Ketentuan',
      term1:
        'Saya menyetujui penggunaan data untuk kegiatan reservasi Hyundai Service Point oleh EO yang ditunjuk oleh Hyundai Motors Indonesia',
      term2: 'Saya bersedia untuk mengikuti survey kepuasan pelanggan',
      term3:
        'Saya bersedia untuk menerima informasi dari Hyundai Motors Indonesia terkait produk dan layanan',
      term4: 'Untuk informasi lebih lanjut silahkan cek',
      term5: 'Kebijakan Privasi',
      term6: 'Saya menyetujui Persyaratan dan Ketentuan yang berlaku untuk Servis Kendaraan',
      answer: 'Jawaban',
      titleQues: 'Kuesioner',
      check1: 'Anda telah mengisi Kuesioner',
      check2: 'Anda telah menerima Syarat dan Ketentuan kami',
      check3:
        'Mohon lengkapi kuesioner dan terima Syarat dan Ketentuan kami sebelum melanjutkan dengan pemesanan layanan.',
      quesSuccesTitle: 'Kuesioner Dikirim',
      quesSuccesDesc: 'Terima kasih telah berpartisipasi',
      quesSuccesBtn: 'Pesan Layanan Acara'
    },
    serviceBookEvent: {
      captionBtnBookEvent: 'Lanjutkan',
      titleBookEvent: 'Pesan Layanan Acara',
      regisHyundaiCarNo: 'Belum ada mobil terdaftar',
      regisHyundaiCarNoDesc:
        'Dapatkan keuntungan khusus dan berbagai keuntungan saat Anda mendaftarkan Mobil Hyundai',
      regisHyundaiTitle: 'Daftar Mobil Hyundai',
      regisNonHyundaiTitle: 'Daftar Mobil Non-Hyundai',
      licensePlate: 'Plat nomor',
      vin: 'Vin',
      errVin: 'Bidang ini tidak boleh berisi spasi dan karakter khusus',
      modelYear: 'Tahun Model',
      carBrandTitle: 'Merek Mobil',
      carBrandSelect: 'Pilih Merek Mobil',
      carBrandSelectErr: 'Silakan pilih Merek Mobil',
      carModelTitle: 'Model Mobil',
      carModelSelect: 'Pilih Model Mobil',
      carModelSelectErr: 'Silakan pilih Model Mobil',
      addCarNonHyundaiSuccesTitle: 'Pendaftaran sukses',
      addCarNonHyundaiSuccesDesc: 'Mobil Anda berhasil didaftarkan',
      btnMyCarList: 'Daftar Mobil Saya',
      event: 'Acara',
      vehicle: 'Kendaraan',
      repairSelection: 'Pilih Perbaikan',
      kmOdometer: 'KM Odometer',
      OrText: 'Atau'
    }
  }
})
