import { ButtonColor } from 'global/types/enums'
import type { HomeContentProps } from 'global/types/props'
import { Button } from 'shared/components/button'
import { CarCard } from 'shared/components/car-card'
import { SubscriptionPackage } from 'shared/components/subscription-package'

export const HomeContent = ({
  car,
  activePackage,
  onUpgradePlan,
  onStopAutoRenewal
}: HomeContentProps) => (
  <>
    <CarCard car={car} />

    <h2 className='font-heading font-medium mb-4'>Active Package</h2>

    <SubscriptionPackage subsPackage={activePackage} isActive />

    <div className='text-neutral text-xs text-center mb-4'>Your next payment is on 1 Jan 2026</div>

    <div className='grid grid-cols-2'>
      <Button
        label='Stop Auto Renewal'
        color={ButtonColor.Transparent}
        onClick={onStopAutoRenewal}
      />
      <Button label='Upgrade Plan' color={ButtonColor.Primary} onClick={onUpgradePlan} />
    </div>
  </>
)
