import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LayoutType } from 'global/types/enums'
import { DataHandler } from 'shared/components/data-handler'
import Icon from 'shared/components/icon'
import { Layout } from 'shared/components/layout'
import { Empty } from 'shared/components/empty'
import { useGetCarListQuery } from 'store/car'
import { PaymentFooter } from './footer'

export const SelectPayment = () => {
  const navigate = useNavigate()

  const paymentResult = useGetCarListQuery()

  const [selectedPayment, setSelectedPayment] = useState<number>()

  const submitPayment = () => {
    navigate('/payment')
  }

  return (
    <Layout
      title='Payment Options'
      type={LayoutType.HeaderFooter}
      footerComponent={<PaymentFooter total={100000} onSubmit={submitPayment} />}
    >
      <DataHandler {...paymentResult}>
        {paymentResult.data?.length ? (
          <>
            <h2 className='font-heading font-medium mb-4'>Select payment method</h2>

            <div>
              {paymentResult.data.map((payment, id) => (
                <div
                  key={id + 1}
                  className={[
                    'flex flex-row gap-2 items-center bg-white border transition p-3 mb-4 last:mb-0',
                    payment.id === selectedPayment ? 'border-secondary' : 'border-transparent'
                  ].join(' ')}
                  onClick={() => setSelectedPayment(payment.id)}
                >
                  <Icon icon='car' size={20} />

                  <div className='flex flex-col flex-1'>
                    <div className='font-medium text-sm'>Name</div>
                    <div className='text-neutral text-xs'>Description</div>
                  </div>

                  {payment.id === selectedPayment && (
                    <Icon icon='checkmark-filled' size={20} className='text-secondary' />
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <Empty />
        )}
      </DataHandler>
    </Layout>
  )
}
