import type { StatusBadgeProps } from 'global/types/props'

export const StatusBadge = ({ status }: StatusBadgeProps) => (
  <div
    className={[
      'px-2 py-1 font-medium text-xs text-white bg-secondary',
      colorVariants[status.toLowerCase()]
    ].join(' ')}
  >
    {status}
  </div>
)

const colorVariants: ColorVariantProps = {
  paid: 'bg-secondary',
  waitingpayment: 'bg-warning',
  expired: 'bg-danger',
  failed: 'bg-danger'
}

interface ColorVariantProps {
  [key: string]: string
}
