import { L10N } from 'global/localization'
import { SpinnerSize } from 'global/types/enums'
import type { DataHandlerProps } from 'global/types/props'
import { Spinner } from 'shared/components/spinner'
import { ResultContent } from 'shared/components/result-content'

export const DataHandler = ({
  isLoading,
  error,
  onReload,
  noAnimation = false,
  useFullHeight = false,
  children
}: DataHandlerProps) => {
  if (isLoading)
    return (
      <div className='h-full flex items-center justify-center'>
        <Spinner size={SpinnerSize.Large} />
      </div>
    )

  if (error)
    return (
      <div className='h-full flex flex-col items-center justify-center'>
        <ResultContent
          image='/not-found.png'
          title={L10N.common.errMsgResourceNotFound}
          useSmallAction
          actionText={L10N.common.reload}
          onAction={onReload}
        />
      </div>
    )

  return (
    <div className={[noAnimation ? '' : 'animate-fadeIn', useFullHeight ? 'h-full' : ''].join(' ')}>
      {children}
    </div>
  )
}
