import { useEffect } from 'react'
import type { SelectPlanContentProps } from 'global/types/props'
import { Card } from 'shared/components/card'
import { PillNav } from 'shared/components/pill-nav'
import { SubscriptionBadge } from 'shared/components/subscription-badge'
import { SubscriptionPackage } from 'shared/components/subscription-package'

export const SelectPlanContent = ({
  car,
  subsPackageList,
  period,
  setPeriod,
  onSelectSubscriptionPackage
}: SelectPlanContentProps) => {
  useEffect(() => {
    setPeriod(subscriptionPeriods[0])
  })

  return (
    <>
      <Card>
        <div className='flex flex-row items-center justify-between mb-3'>
          <h2 className='font-heading font-medium text-xl'>{car.carModel}</h2>
          <span className='text-secondary text-sm'>{car.licensePlate}</span>
        </div>

        <div className='flex flex-row mb-3'>
          <div className='flex-1'>
            <div className='text-neutral text-xs'>{'Registered Owner'}</div>
            <div className='font-heading text-sm'>{car.carModelFull}</div>
          </div>
          <div className='flex-1'>
            <div className='text-neutral text-xs'>{'VIN'}</div>
            <div className='font-heading text-sm'>{car.vinNumber}</div>
          </div>
        </div>

        <SubscriptionBadge date={car.carRegistrationLetter} />
      </Card>

      <div className='mb-4'>
        <h2 className='font-heading font-medium text-lg text-center'>
          Choose Your Subscription Plan
        </h2>

        <div className='text-neutral text-sm text-center'>Unlock all Bluelink features</div>
      </div>

      <div className='text-center mb-4'>
        <PillNav items={subscriptionPeriods} selected={period} onSelect={setPeriod} />
      </div>

      {subsPackageList?.map((subsPackage, id) => (
        <SubscriptionPackage
          key={id + 1}
          subsPackage={subsPackage}
          onSelect={onSelectSubscriptionPackage}
        />
      ))}
    </>
  )
}

const subscriptionPeriods = ['Monthly', 'Yearly']
