import { ButtonColor } from 'global/types/enums'
import type { OrderDetailFooterProps } from 'global/types/props'
import { Button } from 'shared/components/button'
import { formatCurrency } from 'shared/services/utils'

export const OrderDetailFooter = ({ total, onSubmit }: OrderDetailFooterProps) => (
  <div className='flex flex-row items-center justify-between p-4'>
    <div className='flex flex-col'>
      <div className='text-xs text-neutral'>{'Total Payment'}</div>
      <div className='font-heading font-medium text-lg'>{formatCurrency(total)}</div>
    </div>

    <Button label='Place Order' color={ButtonColor.Primary} onClick={onSubmit} />
  </div>
)
