import type { CarCardProps, CardProps } from 'global/types/props'
import { ButtonColor } from 'global/types/enums'
import { Card } from 'shared/components/card'
import { SubscriptionBadge } from 'shared/components/subscription-badge'
import Icon from 'shared/components/icon'

export const CarCard = ({
  car,
  hideSubscription = false,
  onDetail,
  onHistory,
  onSubscribe
}: CarCardProps) => {
  const getActions = (): CardProps['actions'] => {
    const actions: CardProps['actions'] = []

    if (onDetail) actions.push({ name: 'Detail', onClick: () => onDetail(car) })
    if (onHistory) actions.push({ name: 'History', onClick: () => onHistory(car) })
    if (onSubscribe)
      actions.push({
        name: 'Subscribe Now',
        color: ButtonColor.Primary,
        onClick: () => onSubscribe(car)
      })

    return actions
  }

  return (
    <Card bannerImage={car.imageCar ?? '/unknown-car.png'} actions={getActions()}>
      <div className='text-center mb-3'>
        <h2 className='font-heading font-medium text-xl'>{car.carModel}</h2>
        <span className='text-secondary text-sm'>{car.licensePlate}</span>
      </div>

      <div className='flex flex-row mb-3 last:mb-0'>
        <div className='flex-1'>
          <div className='text-neutral text-xs'>{'Registered Owner'}</div>
          <div className='font-heading text-sm'>{car.carModel}</div>
        </div>
        <div className='flex-1'>
          <div className='text-neutral text-xs'>{'VIN'}</div>
          <div className='font-heading text-sm'>{car.vinNumber}</div>
        </div>
      </div>

      {hideSubscription && (
        <div className='mb-3 last:mb-0'>
          {car.note ? (
            <SubscriptionBadge date={car.note} />
          ) : (
            <SubscriptionBadge
              text='Vehicle is not yet subscribed to Bluelink services'
              color='bg-background'
              date={car.note}
            />
          )}
        </div>
      )}

      {!car.id && (
        <div className='flex flex-row items-center px-3 py-1 bg-background border border-border'>
          <Icon icon='calendar' size={48} className='mr-3' />
          <div className='flex flex-col'>
            <h3 className='text-sm'>Auto Renewal</h3>
            <div className='text-neutral text-xs'>
              Starting from 1 Jan 2025, you&apos;ll be charged automatically every year until you
              cancel.
            </div>
          </div>
        </div>
      )}
    </Card>
  )
}
