import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import type { CarDetailResponse, SubscriptionPackageResponse } from 'global/types/responses'
import { DataHandler } from 'shared/components/data-handler'
import { Layout } from 'shared/components/layout'
import { useGetCarDetailQuery } from 'store/car'
import { LayoutType } from 'global/types/enums'
import { Empty } from 'shared/components/empty'
import { OrderDetailContent } from './content'
import { OrderDetailFooter } from './footer'

export const OrderDetail = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  const carData = useGetCarDetailQuery(1039)

  const [car, setCar] = useState<CarDetailResponse>()

  const [activePackage, setActivePackage] = useState<SubscriptionPackageResponse>()

  useEffect(() => {
    if (carData.data) setCar(carData.data)

    setActivePackage({
      id: 1,
      name: 'Basic Charge',
      desc: 'For the Everyday Commuter',
      image: 'https://dummyimage.com/50',
      price: 800000,
      benefits: [
        '500 km of monthly driving',
        'Access to standard charging station',
        'Roadside assistance 24/7',
        'Maintenance check every 6 months',
        'Basic insurance coverage'
      ]
    })
  }, [carData.data])

  const openEnterPromoPage = () => {
    navigate(`/enter-promo/${id}`)
  }

  const openChoosePaymentPage = () => {
    navigate('/select-payment')
  }

  const openTncPage = (showAction: boolean) => {
    navigate(`/tnc?action=${Number(showAction)}`)
  }

  const openPrivacyPolicyPage = (showAction: boolean) => {
    navigate(`/privacy-policy?action=${Number(showAction)}`)
  }

  return (
    <Layout
      title='Order Detail'
      type={LayoutType.HeaderFooter}
      footerComponent={<OrderDetailFooter total={100000} onSubmit={openChoosePaymentPage} />}
    >
      <DataHandler {...carData}>
        {car && activePackage ? (
          <OrderDetailContent
            car={car}
            subsPackage={activePackage}
            onEnterPromo={openEnterPromoPage}
            onClickTnc={openTncPage}
            onClickPolicy={openPrivacyPolicyPage}
          />
        ) : (
          <Empty />
        )}
      </DataHandler>
    </Layout>
  )
}
