import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { useDispatch } from 'react-redux'
import type { LayoutProps } from 'global/types/props'
import { Header } from 'shared/components/header'
import { LayoutType } from 'global/types/enums'
import { BottomTab } from 'shared/components/bottom-tab'
import Icon from 'shared/components/icon'
import 'react-toastify/dist/ReactToastify.min.css'
import './index.css'
import { setContentHeight } from 'store/slices/layout'
import type { AppDispatch } from 'store/store'

export const Layout = ({
  title,
  type = LayoutType.Header,
  noPadding = false,
  actionIcon,
  onAction,
  onSearch,
  onBack,
  footerComponent,
  children
}: LayoutProps) => {
  const location = useLocation()

  const dispatch = useDispatch<AppDispatch>()

  const footerRef = useRef<HTMLDivElement>(null)

  const contentRef = useRef<HTMLDivElement>(null)

  const [footerHeight, setFooterHeight] = useState<number>(0)

  useEffect(() => {
    const fullTitle = [title ?? '', 'Bluelink Subscription'].filter(Boolean).join(' - ')

    document.title = fullTitle
  }, [location, title])

  useEffect(() => {
    if (!footerLayouts.includes(type)) return

    dispatch(setContentHeight(contentRef.current?.clientHeight ?? 0))
  }, [type, dispatch])

  useEffect(() => {
    setFooterHeight(footerRef.current?.offsetHeight ?? 0)
  }, [footerRef.current?.offsetHeight])

  return (
    <>
      {headerLayouts.includes(type) && (
        <Header
          title={title}
          onBack={onBack}
          actionIcon={actionIcon}
          onAction={onAction}
          search={searchHeaders.includes(type)}
          onSearch={onSearch}
        />
      )}

      <div
        ref={contentRef}
        className={['overflow-x-hidden', !noPadding ? 'p-4' : '', getHeightClass(type)].join(' ')}
        style={{ height: getHeightStyle(type, footerHeight) }}
      >
        {children}
      </div>

      {footerLayouts.includes(type) && (
        <div style={{ paddingBottom: footerHeight }}>
          <div id='footer' ref={footerRef} className='fixed bottom-0 z-10 w-full bg-white'>
            {tabFooters.includes(type) ? <BottomTab /> : footerComponent}
          </div>
        </div>
      )}

      <ToastContainer
        theme='colored'
        position='bottom-center'
        transition={Slide}
        limit={1}
        hideProgressBar
        closeButton={false}
        autoClose={3000}
        icon={(props) => {
          switch (props.type) {
            case 'success':
              return <Icon icon='checkmark-filled' size={20} />
            case 'info':
              return <Icon icon='information-filled' size={20} />
            case 'error':
              return <Icon icon='close-filled' size={20} />
            default:
              return null
          }
        }}
      />
    </>
  )
}

const getHeightClass = (type: LayoutType) => {
  switch (type) {
    case LayoutType.Header:
    case LayoutType.SearchHeader:
    case LayoutType.TabFooter:
      return 'h-[calc(theme(height.screen)-theme(height.14))]'

    case LayoutType.SearchHeaderWithTabFooter:
      return 'h-[calc(theme(height.screen)-theme(height.28))]'

    case LayoutType.Fullscreen:
      return 'h-screen'

    default:
      return ''
  }
}

const getHeightStyle = (type: LayoutType, footerHeight = 0) => {
  switch (type) {
    case LayoutType.HeaderFooter:
      return `calc(100vh - 3.5rem - ${Number(footerHeight)}px)`

    default:
      return ''
  }
}

const headerLayouts = [
  LayoutType.Header,
  LayoutType.SearchHeader,
  LayoutType.HeaderFooter,
  LayoutType.SearchHeaderWithTabFooter
]

const footerLayouts = [
  LayoutType.TabFooter,
  LayoutType.HeaderFooter,
  LayoutType.SearchHeaderWithTabFooter
]

const searchHeaders = [LayoutType.SearchHeader, LayoutType.SearchHeaderWithTabFooter]

const tabFooters = [LayoutType.TabFooter, LayoutType.SearchHeaderWithTabFooter]
