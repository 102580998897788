import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { api } from './api'
import { layoutReducer } from './slices/layout'
import { orderReducer } from './slices/order'

const reducer = combineReducers({
  layout: layoutReducer,
  order: orderReducer,
  [api.reducerPath]: api.reducer
})

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)
