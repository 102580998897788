import { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import type { CounterProps } from 'global/types/props'
import { L10N } from 'global/localization'

export const Counter = ({ type, date, showSublabel = false, onTimeUp }: CounterProps) => {
  const [hours, setHours] = useState('00')

  const [minutes, setMinutes] = useState('00')

  const [seconds, setSeconds] = useState('00')

  /**
   * Add leading zero to a number if necessary.
   * @example
   * zeroPad(3) -> "03"
   *
   * @param num Number.
   * @param digitCount Total digit to display. Defaults to `2`.
   * @returns Padded number.
   */
  const zeroPad = (num: string, digitCount = 2) => String(num).padStart(digitCount, '0')

  const timer = useRef<NodeJS.Timer>()

  useEffect(() => {
    /**
     * Interval callback.
     * @param refDate Reference date.
     */
    const intervalCallback = (refDate: moment.Moment) => {
      const now = moment()

      let diff = refDate.diff(now)
      if (type === 'count-up') diff = diff < 0 ? Math.abs(diff) : 0

      const duration = moment.duration(diff)

      if (duration.hours() >= 0) {
        const h = zeroPad(duration.hours().toString())
        setHours(h)
      }

      if (duration.minutes() >= 0) {
        const m = zeroPad(duration.minutes().toString())
        setMinutes(m)
      }

      if (duration.seconds() >= 0) {
        const s = zeroPad(duration.seconds().toString())
        setSeconds(s)
      }

      if (type === 'count-down') {
        if (diff <= 0) {
          clearInterval(timer.current)
          onTimeUp?.()

          setHours('00')
          setMinutes('00')
          setSeconds('00')
        }
      }
    }

    const refDate = moment(date)

    intervalCallback(refDate)
    timer.current = setInterval(() => intervalCallback(refDate), 1000)

    return () => clearInterval(timer.current)
  }, [date, type, onTimeUp])

  return (
    <div className='flex flex-row items-center justify-center'>
      <div className='flex flex-col items-center w-12'>
        <div className='font-heading font-medium text-4xl'>{hours}</div>
        {showSublabel && <div className='text-sm line-clamp-1'>{L10N.common.labelHours}</div>}
      </div>

      <div
        className={['font-heading font-medium mx-4 text-4xl', showSublabel ? 'mb-6' : ''].join(' ')}
      >
        :
      </div>

      <div className='flex flex-col items-center w-12'>
        <div className='font-heading font-medium text-4xl'>{minutes}</div>
        {showSublabel && <div className='text-sm line-clamp-1'>{L10N.common.labelMinutes}</div>}
      </div>

      <div
        className={['font-heading font-medium mx-4 text-4xl', showSublabel ? 'mb-6' : ''].join(' ')}
      >
        :
      </div>

      <div className='flex flex-col items-center w-12'>
        <div className='font-heading font-medium text-4xl'>{seconds}</div>
        {showSublabel && <div className='text-sm line-clamp-1'>{L10N.common.labelSeconds}</div>}
      </div>
    </div>
  )
}
