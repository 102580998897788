import { ButtonColor } from 'global/types/enums'
import type { ButtonProps } from 'global/types/props'
import { Spinner } from 'shared/components/spinner'
import Icon from 'shared/components/icon'
import './index.css'

export const Button = ({
  label,
  icon,
  color = ButtonColor.White,
  isLoading = false,
  disabled = false,
  onClick
}: ButtonProps) => (
  <button
    className={[
      'relative font-heading custom-button',
      (isLoading || disabled) && color === ButtonColor.Transparent ? 'opacity-50' : ''
    ].join(' ')}
    disabled={isLoading || disabled}
    onClick={onClick}
  >
    <div
      className={[
        'flex flex-row items-center justify-center gap-2 py-3 px-1',
        isLoading || disabled ? disabledColorVariants[color] : colorVariants[color]
      ].join(' ')}
    >
      {isLoading ? (
        <Spinner backColor='text-white' frontColor='fill-primary' />
      ) : (
        icon && <Icon icon={icon} size={20} />
      )}

      {label}

      {(isLoading || disabled) && hasWhiteShade(color) && (
        <div className='absolute left-0 top-0 size-full bg-white bg-opacity-50 z-10' />
      )}
    </div>
  </button>
)

const hasWhiteShade = (color: ButtonColor): boolean =>
  ![ButtonColor.Transparent, ButtonColor.Primary].includes(color)

const colorVariants = {
  [ButtonColor.White]: 'bg-white border border-white text-black',
  [ButtonColor.WhiteBordered]: 'bg-white border border-primary text-black',
  [ButtonColor.Primary]: 'bg-primary border border-primary text-white',
  [ButtonColor.Secondary]: 'bg-secondary border border-secondary text-white',
  [ButtonColor.Transparent]: 'bg-transparent border border-transparent text-primary'
}

const disabledColorVariants = {
  [ButtonColor.White]: 'bg-white border border-white text-black',
  [ButtonColor.WhiteBordered]: 'bg-white border border-primary text-black',
  [ButtonColor.Primary]: 'bg-disabled border border-disabled text-white',
  [ButtonColor.Secondary]: 'bg-secondary border border-secondary text-white',
  [ButtonColor.Transparent]: 'bg-transparent border border-transparent text-primary'
}
