import { useEffect, useState } from 'react'
import { addDays } from 'date-fns'
import type { Range } from 'react-date-range'
import { DateRange } from 'react-date-range'
// @ts-expect-error - @eslint/js is not typed
import * as rdrLocales from 'react-date-range/dist/locale'
import type { DateRangePickerModalProps } from 'global/types/props'
import { ActionSheet } from 'shared/components/action-sheet'
import { Button } from 'shared/components/button'
import { ButtonColor } from 'global/types/enums'
import { CONSTANT } from 'global/constant'

export const DateRangePickerModal = ({
  title,
  value,
  open,
  onClose,
  onReset,
  onApply
}: DateRangePickerModalProps) => {
  const [dates, setDates] = useState<Range[]>([getSelectionDate()])

  useEffect(() => {
    setDates([getSelectionDate(value)])
  }, [value])

  return (
    <ActionSheet title={title} scrollable={false} open={open} onClose={onClose}>
      <DateRange
        locale={rdrLocales.id}
        className='w-full px-4'
        direction='vertical'
        months={2}
        // color='#002c5f'
        rangeColors={['#002c5f']}
        showDateDisplay={false}
        dateDisplayFormat={CONSTANT.FORMAT_DATE_SHORT}
        moveRangeOnFirstSelection={false}
        minDate={addDays(new Date(), -300)}
        maxDate={addDays(new Date(), 30)}
        scroll={{ enabled: true }}
        ranges={dates}
        onChange={(item) => setDates([item.selection])}
      />

      <div className='grid grid-cols-2 p-4 gap-4'>
        <Button
          label='Reset'
          color={ButtonColor.WhiteBordered}
          onClick={() => {
            setDates([getSelectionDate()])
            onReset?.()
          }}
        />
        <Button
          label='Apply'
          color={ButtonColor.Primary}
          onClick={() => onApply?.(getDates(dates))}
        />
      </div>
    </ActionSheet>
  )
}

const getDates = (dateRanges: Range[]): Date[] => {
  const selectionRange = dateRanges.find((range) => range.key === CONSTANT.DATE_RANGE_SELECTION_KEY)

  if (!selectionRange) return []

  return [selectionRange.startDate ?? new Date(), selectionRange.endDate ?? new Date()]
}

const getSelectionDate = (dates?: Date[]) => ({
  startDate: dates?.[0] ?? new Date(),
  endDate: dates?.[1] ?? new Date(),
  key: CONSTANT.DATE_RANGE_SELECTION_KEY
})

export default DateRangePickerModal
