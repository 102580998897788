import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ButtonColor, LayoutType } from 'global/types/enums'
import { Button } from 'shared/components/button'
import { Input } from 'shared/components/input'
import { Layout } from 'shared/components/layout'
import { formatCurrency } from 'shared/services/utils'
import type { AppDispatch } from 'store/store'
import { setPromoCode } from 'store/slices/order'
import { EnterPromoFooter } from './footer'

export const EnterPromo = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  const dispatch = useDispatch<AppDispatch>()

  const [code, setCode] = useState<string>('')

  const [message, setMessage] = useState<React.ReactNode>(undefined)

  const [messageColor, setMessageColor] = useState<string>('text-black')

  const inputCode = (text: string) => {
    setCode(text.toUpperCase())
  }

  const applyCode = () => {
    setMessageColor('text-danger')

    setMessage(
      <>
        Promo code applied! You save <span className='font-medium'>{formatCurrency(500000)}</span>
      </>
    )

    // <>
    //   Sorry, the code you've entered is fully redeemed or invalid
    // </>
  }

  return (
    <Layout
      title='Enter Promo Code'
      type={LayoutType.HeaderFooter}
      onBack={() => navigate(`/order-detail/${id}`)}
      footerComponent={
        <EnterPromoFooter
          total={100000}
          onSubmit={() => {
            dispatch(setPromoCode(code))
            navigate(`/order-detail/${id}`)
          }}
        />
      }
    >
      <div className='animate-fadeIn'>
        <div className='mb-4 font-medium'>{'Enter promo code'}</div>

        <div className='flex flex-row gap-3 items-center mb-2'>
          <div className='flex-1'>
            <Input
              value={code}
              icon='electric-car'
              actionIcon={code && 'close-filled'}
              onChange={inputCode}
              onAction={() => setCode('')}
            />
          </div>
          <Button label='Apply' color={ButtonColor.Primary} onClick={applyCode} />
        </div>

        <div className={`text-sm ${messageColor}`}>{message}</div>
      </div>
    </Layout>
  )
}
