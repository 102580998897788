import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { OrderInitialState } from 'global/types/states'
import type { RootState } from 'store/store'

const initialState: OrderInitialState = {
  promoCode: '',
  isEnableAutoRenewal: false,
  isAgreeTnc: false,
  isAgreePrivacyPolicy: false
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setPromoCode: (state, action: PayloadAction<string>) => {
      state.promoCode = action.payload
    },
    setEnableAutoRenewal: (state, action: PayloadAction<boolean>) => {
      state.isEnableAutoRenewal = action.payload
    },
    setAgreeTnc: (state, action: PayloadAction<boolean>) => {
      state.isAgreeTnc = action.payload
    },
    setAgreePrivacyPolicy: (state, action: PayloadAction<boolean>) => {
      state.isAgreePrivacyPolicy = action.payload
    }
  }
})

export const { setPromoCode, setEnableAutoRenewal, setAgreeTnc, setAgreePrivacyPolicy } =
  orderSlice.actions

export const getOrderState = (state: RootState) => state.order

export const orderReducer = orderSlice.reducer
