import { ButtonColor } from 'global/types/enums'
import type { OrderDetailFooterProps } from 'global/types/props'
import { Button } from 'shared/components/button'
import Icon from 'shared/components/icon'
import { formatCurrency } from 'shared/services/utils'

export const PaymentFooter = ({ total, onSubmit }: OrderDetailFooterProps) => (
  <>
    <div className='flex flex-row items-center gap-2 border-b border-border px-4 py-2 text-xs'>
      <Icon icon='information' size={20} className='text-secondary' />
      <div>You will be charged an amount of {formatCurrency(3500)} for the topup fee</div>
    </div>
    <div className='flex flex-row items-center justify-between p-4'>
      <div className='flex flex-col'>
        <div className='font-heading font-medium text-lg'>{formatCurrency(total)}</div>
        <div className='text-xs text-neutral'>{formatCurrency(100000)} + {formatCurrency(3500)}</div>
      </div>

      <Button label='Continue' color={ButtonColor.Primary} onClick={onSubmit} />
    </div>
  </>
)
