import { useEffect, useState } from 'react'
import moment from 'moment'
import { CONSTANT } from 'global/constant'
import type { SubscriptionBadgeProps } from 'global/types/props'
import Icon from 'shared/components/icon'

export const SubscriptionBadge = ({
  icon = 'checkmark-filled',
  text,
  color = 'gradient-primary',
  date
}: SubscriptionBadgeProps) => {
  const [parsedDate, setParsedDate] = useState<string>()

  useEffect(() => {
    if (!date) return

    const parsedDate = moment(date).format(CONSTANT.FORMAT_DATE_SHORT)
    setParsedDate(parsedDate)
  }, [date])

  return (
    <div
      className={`flex flex-row items-center justify-between rounded-full px-2.5 py-1.5 text-sm ${color} ${colorVariants[color].textColor}`}
    >
      <div className='flex flex-row items-center'>
        <Icon className={`mr-2 ${colorVariants[color].iconColor}`} icon={icon} size={16} />
        <div className='line-clamp-1'>{text ?? 'Active Subscription'}</div>
      </div>

      <div>{parsedDate}</div>
    </div>
  )
}

const colorVariants: ColorVariantProps = {
  'bg-background': {
    textColor: 'text-black',
    iconColor: 'text-light-neutral'
  },
  'gradient-primary': {
    textColor: 'text-white',
    iconColor: 'text-white'
  }
}

interface ColorVariantProps {
  [key: string]: { textColor: string; iconColor: string }
}
