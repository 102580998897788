import { CheckboxType } from 'global/types/enums'
import type { CheckboxProps } from 'global/types/props'
import Icon from 'shared/components/icon'

export const Checkbox = ({ type, checked, onCheck }: CheckboxProps) => {
  if (type === CheckboxType.Switch) {
    return (
      <button
        className={[
          'relative flex flex-row w-10 h-6 rounded-full transition overflow-hidden',
          checked ? 'bg-secondary' : 'bg-background'
        ].join(' ')}
        onClick={onCheck}
      >
        <div
          className={[
            'absolute -translate-y-1/2 top-1/2 size-5 rounded-full bg-white transition-all',
            checked ? 'left-[1.125rem]' : 'left-0.5'
          ].join(' ')}
        />
      </button>
    )
  }

  return (
    <button
      className={[
        'flex items-center justify-center size-5 border border-secondary bg-secondary transition',
        checked ? 'bg-secondary' : 'bg-transparent'
      ].join(' ')}
      onClick={onCheck}
    >
      <Icon className={checked ? 'text-white' : 'text-transparent'} icon='checkmark' size={20} />
    </button>
  )
}
