import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { CONSTANT } from 'global/constant'
import { useGetL10nQuery } from 'store/general'
import { L10N } from 'global/localization'

export const Protected = () => {
  const location = useLocation()

  const { data: l10n, isSuccess } = useGetL10nQuery()

  const [searchParams] = useSearchParams()

  // Authentication status.
  // -1: Loading
  //  0: Not authenticated
  //  1: Authenticated
  const [authStatus, setAuthStatus] = useState<number>(-1)

  useEffect(() => {
    const token = searchParams.get(CONSTANT.TOKEN_KEY)
    const carId = searchParams.get(CONSTANT.CAR_ID_KEY)
    const lang =
      searchParams.get(CONSTANT.LANG_KEY) ||
      localStorage.getItem(CONSTANT.LANG_KEY) ||
      CONSTANT.LOCALE_LANG_ENUS

    if (token) localStorage.setItem(CONSTANT.TOKEN_KEY, token)
    if (carId) localStorage.setItem(CONSTANT.CAR_ID_KEY, carId ?? '1013')
    localStorage.setItem(CONSTANT.LANG_KEY, lang)

    const storedToken = localStorage.getItem(CONSTANT.TOKEN_KEY)

    setAuthStatus(Number(Boolean(storedToken)))
  }, [location, searchParams])

  useEffect(() => {
    if (!isSuccess) return

    L10N.setContent(l10n)
  }, [l10n, isSuccess])

  switch (authStatus) {
    case -1:
      return <div className='flex items-center justify-center h-screen'>Validating...</div>

    case 1:
      if (location.pathname === '/') return <Navigate to={'/home'} />

      return <Outlet />

    default:
      return <div className='flex items-center justify-center h-screen'>Authentication failed.</div>
  }
}
