import type { SettingsListItemProps } from 'global/types/props'
import Icon from 'shared/components/icon'

export const SettingsListItem = ({ name, value, onClick }: SettingsListItemProps) => (
  <button
    className='flex flex-row gap-2 items-center text-left w-full border-b border-border last:border-b-0 py-3'
    onClick={onClick}
  >
    <Icon icon='chevron-right' size={20} />
    <div className='flex-1'>{name}</div>
    {Boolean(value) && <div>{value}</div>}
    <Icon icon='chevron-right' size={20} />
  </button>
)
