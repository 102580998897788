import { useState } from 'react'
import type { DatePickerProps } from 'global/types/props'
import Icon from 'shared/components/icon'
import DateRangePickerModal from 'shared/components/date-range-picker-modal'
import { formatDate } from 'shared/services/utils'

export const DatePicker = ({ label, value, onSelect }: DatePickerProps) => {
  const [showPicker, setShowPicker] = useState<boolean>(false)

  return (
    <>
      {label && <div className='mb-1'>{label}</div>}
      <button
        className='flex flex-row items-center justify-between w-full px-3 mb-4 bg-background'
        onClick={() => setShowPicker(true)}
      >
        <div className='flex-1 flex items-center h-12'>
          {value && value.map((v) => formatDate(v)).join(' - ')}
        </div>

        <Icon icon='calendar' size={20} className='ml-3 text-neutral' />
      </button>

      <DateRangePickerModal
        title={label}
        value={value}
        open={showPicker}
        onClose={() => setShowPicker(false)}
        onReset={() => {
          setShowPicker(false)
          onSelect(undefined)
        }}
        onApply={(dates) => {
          setShowPicker(false)
          onSelect(dates)
        }}
      />
    </>
  )
}
