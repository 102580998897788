import { API } from 'global/api'
import type { CarDetailResponse, CarResponse } from 'global/types/responses'
import { api, headers } from './api'

const carApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCarList: builder.query<CarResponse[], void>({
      query: () => ({
        url: API.URL_GET_CAR_LIST,
        method: 'GET',
        headers: headers()
      })
    }),
    getCarDetail: builder.query<CarDetailResponse, number>({
      query: (carId: number) => ({
        url: `${API.URL_GET_CAR_DETAIL}/${carId}`,
        method: 'GET',
        headers: headers(),
        responseHandler: async (response) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const result: any[] = JSON.parse(await response.text())

          return result.shift()
        }
      })
    })
    // getListUserByMemberCode: builder.mutation<MemberCoinProps[], string[]>({
    //   query: (body) => ({
    //     url: CONSTANT.URL_COIN_MEMBER,
    //     method: 'POST',
    //     headers: headers(),
    //     body: {MemberCode: body}
    //   })
    // }),
    // submitCoinSubmission: builder.mutation<MemberCoinProps[], PartialNewSubmissionCoinProps>({
    //   query: (body) => ({
    //     url: CONSTANT.URL_COIN_SUBMISSION,
    //     method: 'POST',
    //     headers: headers(),
    //     body: body
    //   })
    // })
  })
})

export const { useGetCarListQuery, useGetCarDetailQuery } = carApi
