import { useEffect, useState } from 'react'
import { decode } from 'html-entities'
import { useSearchParams } from 'react-router-dom'
import { LayoutType } from 'global/types/enums'
import { Layout } from 'shared/components/layout'
import { useGetPrivacyPolicyQuery } from 'store/general'
import { DataHandler } from 'shared/components/data-handler'
import { PrivacyPolicyFooter } from './footer'

/** Query param key for `action`. */
const ACTION_QPARAM_KEY = 'action'

export const PrivacyPolicy = () => {
  const [searchParams] = useSearchParams()

  const ppData = useGetPrivacyPolicyQuery()

  const [hasAction, setHasAction] = useState<boolean>(false)

  useEffect(() => {
    setHasAction(Boolean(Number(searchParams.get(ACTION_QPARAM_KEY))))
  }, [searchParams])

  return (
    <Layout
      title='Privacy Policy'
      type={hasAction ? LayoutType.HeaderFooter : undefined}
      footerComponent={hasAction ? <PrivacyPolicyFooter onClick={console.log} /> : undefined}
    >
      <DataHandler {...ppData}>
        <div
          className='prose overflow-auto'
          dangerouslySetInnerHTML={{ __html: decode(ppData.data?.body ?? '') }}
        />
      </DataHandler>
    </Layout>
  )
}
