import { API } from 'global/api'
import type {
  PaginatedResponse,
  TrxHistoryDetailResponse,
  TrxHistoryListItemResponse,
  TrxStatusResponse,
  TrxTypeResponse
} from 'global/types/responses'
import type { TrxHistoryParam } from 'global/types/params'
import { CONSTANT } from 'global/constant'
import { api, headers } from './api'

const transactionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTrxHistory: builder.query<PaginatedResponse<TrxHistoryListItemResponse>, TrxHistoryParam>({
      query: (param) => {
        const body = new FormData()
        body.append('start', String(param.start))
        body.append('length', String(param.length ?? CONSTANT.ITEMS_PER_PAGE))
        body.append('search[value]', param.search ?? '')
        body.append('search[regex]', 'false')
        body.append('columns[0]data', '1')
        body.append('columns[0][orderable]', 'true')
        body.append('columns[0][name]', param.sortBy ?? 'transactionDate')
        body.append('order[0][column]', '1')
        body.append('order[0][dir]', param.sortOrder ?? 'desc')
        body.append('field', 'shortDesc,transactionType,transactionStatus,transactionCode')
        // body.append('transactionType', '')
        // body.append('transactionStatus', '')
        // body.append('transactionStartDate', '')
        // body.append('transactionEndDate', '')

        return {
          url: API.URL_GET_TRX_HISTORY,
          method: 'POST',
          headers: headers({ useContentType: false }),
          body
        }
      }
    }),
    getTrxHistoryDetail: builder.query<TrxHistoryDetailResponse, number>({
      query: (id) => ({
        url: `${API.URL_GET_TRX_HISTORY_DETAIL}/${id}`,
        method: 'GET',
        headers: headers(),
        responseHandler: async (response) => {
          const result = JSON.parse(await response.text())

          return {
            ...result,
            purchaseDetail: result.detailInformation.accessories[0]
          }
        }
      })
    }),
    getTrxTypeList: builder.query<TrxTypeResponse[], void>({
      query: () => ({
        url: API.URL_GET_TRX_TYPE_LIST,
        method: 'GET',
        headers: headers()
      })
    }),
    getTrxStatusList: builder.query<TrxStatusResponse[], void>({
      query: () => ({
        url: API.URL_GET_TRX_STATUS_LIST,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useLazyGetTrxHistoryQuery,
  useGetTrxHistoryDetailQuery,
  useLazyGetTrxTypeListQuery,
  useLazyGetTrxStatusListQuery
} = transactionApi
