export enum ButtonColor {
  White = 1,
  WhiteBordered,
  Primary,
  Secondary,
  Transparent
}

export enum CheckboxType {
  Default = 1,
  Switch
}

export enum LayoutType {
  /** A layout containing only a default header. */
  Header = 1,

  /** A layout containing only a header that has a search input. */
  SearchHeader,

  /** A layout containing only a tab navigation footer. */
  TabFooter,

  /** A layout containing both default header & empty footer. */
  HeaderFooter,

  /** A layout containing both search header & tab navigation footer. */
  SearchHeaderWithTabFooter,

  /** A fullscreen layout. */
  Fullscreen,
}

export enum ResultType {
  PaymentSuccess = 1,
  PaymentFailed,
  AutoRenewalStop
}

export enum SelectType {
  Picker = 1,
  Pills
}

export enum SpinnerSize {
  Small = 1,
  Large
}
