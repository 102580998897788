import { API } from 'global/api'
import type { HelpResponse } from 'global/types/responses'
import { api, headers } from './api'

const helpApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHelp: builder.query<HelpResponse, void>({
      query: () => ({
        url: API.URL_GET_CONTACT_US,
        method: 'GET',
        headers: headers()
      })
    }),
  })
})

export const { useGetHelpQuery } = helpApi
