import ReactModal from 'react-modal'
import Icon from 'shared/components/icon'
import { Button } from 'shared/components/button'
import type { ConfirmModalProps } from 'global/types/props'

export const ConfirmModal = ({
  title,
  desc,
  showXButton = true,
  showCancelButton = true,
  cancelText = 'Cancel',
  confirmText = 'OK',
  confirmColor,
  open,
  onCancel,
  onConfirm
}: ConfirmModalProps) => (
  <ReactModal
    isOpen={open}
    onRequestClose={onCancel}
    contentLabel={title}
    overlayClassName='fixed top-0 left-0 z-10 w-screen h-screen bg-gray-500 bg-opacity-50'
    className='absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 min-w-[320px] bg-white overflow-hidden'
  >
    <div className='flex flex-row items-center'>
      <h3 className='flex-1 font-heading font-medium text-lg p-4'>{title}</h3>
      {showXButton && (
        <button className='p-4' onClick={onCancel}>
          <Icon icon='close' size={20} />
        </button>
      )}
    </div>

    <div className='p-4 pt-0 text-sm'>{desc}</div>

    <div className={`border grid ${showCancelButton ? 'grid-cols-2' : 'grid-cols-1'}`}>
      {showCancelButton && <Button label={cancelText} onClick={onCancel} />}
      <Button label={confirmText} color={confirmColor} onClick={onConfirm ?? onCancel} />
    </div>
  </ReactModal>
)
