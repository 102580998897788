import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetCarDetailQuery } from 'store/car'
import { Layout } from 'shared/components/layout'
import type { CarDetailResponse, SubscriptionPackageResponse } from 'global/types/responses'
import { DataHandler } from 'shared/components/data-handler'
import { ConfirmModal } from 'shared/components/confirm-modal'
import { LayoutType } from 'global/types/enums'
import { CONSTANT } from 'global/constant'
import { Empty } from 'shared/components/empty'
import { HomeContent } from './content'

export const Home = () => {
  const navigate = useNavigate()

  const { data, error, isLoading } = useGetCarDetailQuery(
    Number(localStorage.getItem(CONSTANT.CAR_ID_KEY))
  )

  const [car, setCar] = useState<CarDetailResponse>()

  const [activePackage, setActivePackage] = useState<SubscriptionPackageResponse>()

  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  useEffect(() => {
    if (data) setCar(data)

    setActivePackage({
      id: 1,
      name: 'Basic Charge',
      desc: 'For the Everyday Commuter',
      image: 'https://dummyimage.com/50',
      price: 800000,
      benefits: [
        '500 km of monthly driving',
        'Access to standard charging station',
        'Roadside assistance 24/7',
        'Maintenance check every 6 months',
        'Basic insurance coverage'
      ]
    })
  }, [data])

  const upgradePlan = () => {
    navigate('/select-plan')
  }

  const stopAutoRenewal = () => {
    navigate('/result')
  }

  return (
    <Layout title='Home' type={LayoutType.TabFooter}>
      <DataHandler isLoading={isLoading} error={error}>
        {car && activePackage ? (
          <HomeContent
            car={car}
            activePackage={activePackage}
            onUpgradePlan={upgradePlan}
            onStopAutoRenewal={() => setShowConfirm(true)}
          />
        ) : (
          <Empty />
        )}
      </DataHandler>

      <ConfirmModal
        title='Confirmation'
        desc='Your next payment is scheduled for 1 January 2025. Are you sure you want to stop the auto-renewal?'
        confirmText='Yes, Stop'
        open={showConfirm}
        onCancel={() => setShowConfirm(false)}
        onConfirm={stopAutoRenewal}
      />
    </Layout>
  )
}
