import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import type { CarDetailResponse, SubscriptionPackageResponse } from 'global/types/responses'
import { DataHandler } from 'shared/components/data-handler'
import { Layout } from 'shared/components/layout'
import { useGetCarDetailQuery } from 'store/car'
import { SelectPlanContent } from './content'

export const SelectPlan = () => {
  const navigate = useNavigate()

  const carData = useGetCarDetailQuery(1039)

  const [car, setCar] = useState<CarDetailResponse>()

  const [packageList, setPackageList] = useState<SubscriptionPackageResponse[]>()

  const [period, setPeriod] = useState<string>('')

  useEffect(() => {
    if (carData.data) setCar(carData.data)

    setPackageList([
      {
        id: 1,
        name: 'Basic Charge',
        desc: 'For the Everyday Commuter',
        image: 'https://dummyimage.com/50',
        price: 800000,
        benefits: [
          '500 km of monthly driving',
          'Access to standard charging station',
          'Roadside assistance 24/7',
          'Maintenance check every 6 months',
          'Basic insurance coverage'
        ]
      },
      {
        id: 2,
        name: 'Eco Drive',
        desc: 'For the Everyday Commuter',
        image: 'https://dummyimage.com/50',
        price: 800000,
        benefits: [
          '500 km of monthly driving',
          'Access to standard charging station',
          'Roadside assistance 24/7',
          'Maintenance check every 6 months',
          'Basic insurance coverage'
        ]
      },
      {
        id: 3,
        name: 'Ultimate Power',
        desc: 'For the Everyday Commuter',
        image: 'https://dummyimage.com/50',
        price: 800000,
        benefits: [
          '500 km of monthly driving',
          'Access to standard charging station',
          'Roadside assistance 24/7',
          'Maintenance check every 6 months',
          'Basic insurance coverage'
        ]
      }
    ])
  }, [carData.data])

  const selectSubsPackage = (subsPackage: SubscriptionPackageResponse) => {
    if (!subsPackage.id) return

    navigate(['/order-detail', subsPackage.id].join('/'))
  }

  return (
    <Layout title='Upgrade Plan'>
      <DataHandler {...carData}>
        {car && packageList?.length && (
          <SelectPlanContent
            car={car}
            subsPackageList={packageList}
            period={period}
            setPeriod={setPeriod}
            onSelectSubscriptionPackage={selectSubsPackage}
          />
        )}
      </DataHandler>
    </Layout>
  )
}
