import { useNavigate } from 'react-router-dom'
import { LayoutType } from 'global/types/enums'
import { CarCard } from 'shared/components/car-card'
import { DataHandler } from 'shared/components/data-handler'
import { Layout } from 'shared/components/layout'
import { useGetCarDetailQuery } from 'store/car'
import { useGetTrxHistoryDetailQuery } from 'store/transaction'
import { Empty } from 'shared/components/empty'
import { TrxHistoryDetailFooter } from './footer'
import { TrxHistoryDetailContent } from './content'

export const TrxHistoryDetail = () => {
  const navigate = useNavigate()

  const carResult = useGetCarDetailQuery(1039)

  const trxDetailResult = useGetTrxHistoryDetailQuery(436)

  const openFailedPage = () => {
    const qParam = { date: trxDetailResult.data?.paymentInformation.expiredIn }

    navigate(`/result?data=${encodeURI(JSON.stringify(qParam))}`)
  }

  return (
    <Layout
      title='Transaction Detail'
      type={trxDetailResult.data && LayoutType.HeaderFooter}
      footerComponent={<TrxHistoryDetailFooter trxDetail={trxDetailResult.data} />}
    >
      <DataHandler {...trxDetailResult}>
        {trxDetailResult.data && carResult.data ? (
          <>
            <CarCard car={carResult.data} />
            <TrxHistoryDetailContent trxDetail={trxDetailResult.data} onExpired={openFailedPage} />
          </>
        ) : (
          <Empty />
        )}
      </DataHandler>
    </Layout>
  )
}
