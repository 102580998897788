import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CONSTANT } from 'global/constant'
import { L10N } from 'global/localization'
import type { LangProps, SettingsListItemProps } from 'global/types/props'
import { LayoutType } from 'global/types/enums'
import { Layout } from 'shared/components/layout'
import { ActionSheet } from 'shared/components/action-sheet'
import Icon from 'shared/components/icon'
import { SettingsListItem } from './list-item'

export const Settings = () => {
  const navigate = useNavigate()

  const [showLangPicker, setShowLangPicker] = useState<boolean>(false)

  const list: SettingsListItemProps[] = [
    {
      name: L10N.settingScreen.buttonCaptionLanguageSetting,
      value: langList.find(
        (l) => l.id === (localStorage.getItem(CONSTANT.LANG_KEY) ?? CONSTANT.LOCALE_LANG_ENUS)
      )?.name,
      onClick: () => setShowLangPicker(true)
    },
    {
      name: 'Terms & Conditions',
      onClick: () => navigate('/tnc')
    },
    {
      name: 'Privacy Policy',
      onClick: () => navigate('/privacy-policy')
    }
  ]

  return (
    <Layout title='Settings' type={LayoutType.TabFooter} noPadding>
      <div className='animate-fadeIn'>
        <h2 className='font-heading font-medium m-4'>Settings</h2>

        <div className='bg-white border-y border-border px-4 mb-4'>
          {list.map((item, id) => (
            <SettingsListItem key={id + 1} {...item} />
          ))}
        </div>
      </div>

      <ActionSheet
        title={L10N.settingScreen.buttonCaptionLanguageSetting}
        open={showLangPicker}
        onClose={() => setShowLangPicker(false)}
      >
        <div className='px-4'>
          {langList.map((lang, id) => (
            <button
              key={id + 1}
              className='flex flex-row items-center gap-2 border-b border-border w-full py-3 text-left'
              onClick={() => {
                setShowLangPicker(false)
                localStorage.setItem(CONSTANT.LANG_KEY, lang.id)
                L10N.setLanguage(lang.id)
              }}
            >
              <img src={lang.image} alt={lang.id} className='h-5 max-w-7' />
              <div className='flex-1'>{lang.name}</div>
              {isLangSelected(lang) && (
                <Icon icon='checkmark-filled' size={20} className='text-secondary' />
              )}
            </button>
          ))}
        </div>
      </ActionSheet>
    </Layout>
  )
}

const isLangSelected = (lang: LangProps): boolean =>
  localStorage.getItem(CONSTANT.LANG_KEY) === lang.id

const langList: LangProps[] = [
  { id: CONSTANT.LOCALE_LANG_ENUS, name: L10N.onboardScreen.labelEnglish, image: '/flag-gb.png' },
  { id: CONSTANT.LOCALE_LANG_ID, name: L10N.onboardScreen.labelIndonesia, image: '/flag-id.png' }
]
