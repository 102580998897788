import { L10N } from 'global/localization'
import { LayoutType } from 'global/types/enums'
import type { HelpResponse } from 'global/types/responses'
import { DataHandler } from 'shared/components/data-handler'
import Icon from 'shared/components/icon'
import { Layout } from 'shared/components/layout'
import { useGetHelpQuery } from 'store/help'

export const Help = () => {
  const contactResult = useGetHelpQuery()

  return (
    <Layout title='Help Center' type={LayoutType.TabFooter} noPadding>
      <DataHandler {...contactResult}>
        {contactResult.data && (
          <>
            <div className='bg-white mb-4'>
              <div className='w-full h-52'>
                <img
                  src={contactResult.data.image}
                  alt='help-center'
                  className='size-full object-cover'
                />
              </div>
              <h2 className='font-heading font-medium text-center p-4'>Bluelink Customer Service</h2>
            </div>

            <div className='bg-white mb-4'>
              <h2 className='font-heading font-medium text-sm text-center p-4'>
                PT Hyundai Motors Indonesia
              </h2>

              <div className='pl-4'>
                {getContactList(contactResult.data.contactUsItems).map((item, id) => (
                  <a
                    key={id + 1}
                    className='flex flex-row gap-3 items-center pr-4 py-3 border-b border-border last:border-b-0'
                    href={item.url ?? undefined}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Icon icon={item.mediaType} size={20} />
                    <div className='flex-1'>
                      <div className='text-neutral text-xs'>{item.name}</div>
                      <div className='font-heading text-sm'>{item.data}</div>
                    </div>
                    <Icon icon='chevron-right' size={20} />
                  </a>
                ))}
              </div>
            </div>

            <div className='bg-white mb-4'>
              <div className='text-neutral text-sm text-center p-4'>
                <div>{L10N.helpCenterScreen.CCBody1}</div>
                <div>{L10N.helpCenterScreen.CCBody2}</div>
              </div>

              <div className='pl-4'>
                {getCcList(contactResult.data.contactUsItems).map((item, id) => (
                  <a
                    key={id + 1}
                    className='flex flex-row gap-3 items-center pr-4 py-3 border-b border-border last:border-b-0'
                    href={item.url ?? undefined}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Icon icon={item.mediaType} size={20} />
                    <div className='flex-1'>
                      <div className='text-neutral text-xs'>{item.name}</div>
                      <div className='font-heading text-sm'>{item.data}</div>
                    </div>
                    <Icon icon='chevron-right' size={20} />
                  </a>
                ))}
              </div>
            </div>

            <div className='bg-white mb-4'>
              <h2 className='font-heading font-medium text-sm text-center p-4'>
                {L10N.settingScreen.hyundaiMotor} {L10N.settingScreen.socialMedia}
              </h2>

              <div className='grid grid-cols-4'>
                {getSocMedList(contactResult.data.contactUsItems).map((item, id) => (
                  <a
                    key={id + 1}
                    className='flex flex-col items-center mb-4'
                    href={item.url ?? undefined}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Icon icon={item.mediaType} size={32} className='mb-2' />
                    <div className='text-neutral text-xs'>{item.name}</div>
                  </a>
                ))}
              </div>
            </div>
          </>
        )}
      </DataHandler>
    </Layout>
  )
}

const getContactList = (helpItems: HelpResponse['contactUsItems']) =>
  helpItems.filter((item) => item.groupType.toLowerCase() === 'contact us')

const getCcList = (helpItems: HelpResponse['contactUsItems']) =>
  helpItems.filter((item) => item.groupType.toLowerCase() === 'consumercompliant')

const getSocMedList = (helpItems: HelpResponse['contactUsItems']) =>
  helpItems.filter((item) => item.groupType.toLowerCase() === 'hyundai motors indonesia')
