export const CONSTANT = {
  BASE_URL: process.env.REACT_APP_BASE_URL,

  LOCALE_LANG_ENUS: 'en-US',
  LOCALE_LANG_ID: 'ID',

  // Local storage keys.
  TOKEN_KEY: 'token',
  LANG_KEY: 'lang',
  CAR_ID_KEY: 'carId',

  // Date time formats.
  FORMAT_DATE_SHORT: 'D MMM YYYY',
  FORMAT_DATE_LONG: 'D MMMM YYYY',
  FORMAT_DATETIME_SHORT: 'D MMM YYYY HH:mm',
  FORMAT_DATETIME_LONG: 'D MMM YYYY HH:mm:ss',

  DATE_RANGE_SELECTION_KEY: 'selection',

  // Currency formats.
  LOCALE_CURRENCY_ID: 'id-ID',
  FORMAT_CURRENCY_ID: 'Rp #',

  ITEMS_PER_PAGE: 10
}
