import { useNavigate } from 'react-router-dom'
import { useGetCarListQuery } from 'store/car'
import { CONSTANT } from 'global/constant'
import { LayoutType } from 'global/types/enums'
import type { CarDetailResponse, CarResponse } from 'global/types/responses'
import { CarCard } from 'shared/components/car-card'
import { DataHandler } from 'shared/components/data-handler'
import { Layout } from 'shared/components/layout'
import { Empty } from 'shared/components/empty'

export const MyCar = () => {
  const navigate = useNavigate()

  const carListData = useGetCarListQuery()

  const openHomePage = (car: CarResponse | CarDetailResponse) => {
    localStorage.setItem(CONSTANT.CAR_ID_KEY, String(car.id))

    navigate('/home')
  }

  const viewHistory = (car: CarResponse | CarDetailResponse) => {
    navigate(`/history?car=${car.id}`)
  }

  return (
    <Layout title='My Car' type={LayoutType.TabFooter}>
      <DataHandler {...carListData}>
        {carListData.data?.length ? (
          carListData.data.map((car, id) => (
            <CarCard
              key={id + 1}
              car={car}
              onDetail={openHomePage}
              onHistory={viewHistory}
              onSubscribe={openHomePage}
            />
          ))
        ) : (
          <Empty />
        )}
      </DataHandler>
    </Layout>
  )
}
