import { useEffect, useState } from 'react'
import moment from 'moment'
import { CONSTANT } from 'global/constant'

export const useDebounce = (value?: string, delay = 1000) => {
  const [debounceValue, setDebounceValue] = useState<string | undefined>(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(value)
    }, delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debounceValue
}

export const formatCurrency = (value: number, locale?: string): string => {
  let format: string = ''
  let localeString = locale

  switch (locale) {
    default:
      format = CONSTANT.FORMAT_CURRENCY_ID
      localeString = CONSTANT.LOCALE_CURRENCY_ID
  }

  return format.replace('#', value.toLocaleString(localeString))
}

export const formatDate = (value: moment.MomentInput, long = false): string =>
  moment(value).format(long ? CONSTANT.FORMAT_DATE_LONG : CONSTANT.FORMAT_DATE_SHORT)

export const formatDateTime = (value: moment.MomentInput, long = false): string =>
  moment(value).format(long ? CONSTANT.FORMAT_DATETIME_LONG : CONSTANT.FORMAT_DATETIME_SHORT)
