import { NavLink, useLocation } from 'react-router-dom'
import Icon from 'shared/components/icon'
import menu from 'assets/json/menu.json'

export const BottomTab = () => {
  const location = useLocation()

  return (
    <div className='grid grid-cols-5 h-14'>
      {menu.map(({ name, icon, path }, id) => (
        <NavLink
          key={id + 1}
          to={path}
          className={(props) => {
            const baseClass = 'flex flex-col justify-center text-center border-t-4 overflow-hidden'

            return [baseClass, props.isActive ? 'border-primary' : 'border-transparent'].join(' ')
          }}
        >
          <div className='mb-1'>
            <Icon icon={location.pathname === path ? icon.active : icon.normal} size={24} />
          </div>
          <span className='text-xs line-clamp-1'>{name}</span>
        </NavLink>
      ))}
    </div>
  )
}
