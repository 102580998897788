import { useState } from 'react'
import type { SubscriptionPackageProps } from 'global/types/props'
import { Card } from 'shared/components/card'
import { formatCurrency } from 'shared/services/utils'
import Icon from 'shared/components/icon'
import { Badge } from 'shared/components/badge'

export const SubscriptionPackage = ({
  subsPackage,
  isActive = false,
  onSelect
}: SubscriptionPackageProps) => {
  const [showBenefits, setShowBenefits] = useState<boolean>(false)

  return (
    <Card>
      <div className='flex flex-row items-center justify-between mb-3'>
        <img src={subsPackage.image} alt='subscription-package' className='size-11 rounded-full' />
        {!isActive && (
          <button className='self-stretch text-right' onClick={() => onSelect?.(subsPackage)}>
            {'Choose'}
            <Icon icon='chevron-right' size={20} />
          </button>
        )}
      </div>

      <div className='flex flex-row items-center justify-between mb-3'>
        <div className='flex flex-col'>
          <h3 className='font-heading font-medium text-lg'>{subsPackage.name}</h3>
          <div className='text-neutral text-xs'>{subsPackage.desc}</div>
        </div>

        <div className='flex flex-col text-right'>
          <div className='font-heading font-medium text-secondary'>
            {subsPackage.price > 0 ? formatCurrency(subsPackage.price) : 'FREE'}
          </div>

          {!isActive && (
            <div className='flex flex-row items-center text-xs'>
              <span className='line-through mr-1'>{formatCurrency(subsPackage.price)}</span>
              <Badge text={'15%'} />
            </div>
          )}
        </div>
      </div>

      {(isActive || (!isActive && showBenefits)) && (
        <>
          <h4 className='font-heading font-medium mb-3'>Benefits</h4>

          <ul className={`list-disc text-neutral text-xs ml-4 ${!isActive && 'mb-3'}`}>
            {subsPackage.benefits.map((benefit, id) => (
              <li key={id + 1}>{benefit}</li>
            ))}
          </ul>
        </>
      )}

      {!isActive && (
        <button className='text-sm' onClick={() => setShowBenefits(!showBenefits)}>
          <span className='mr-1'>{showBenefits ? 'Hide Benefits' : 'Learn Benefits'}</span>
          <Icon icon={showBenefits ? 'chevron-up' : 'chevron-down'} size={20} />
        </button>
      )}
    </Card>
  )
}
