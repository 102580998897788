import { useState } from 'react'
import type { KeyValueProps, MultiSelectProps } from 'global/types/props'

export const MultiSelect = ({ label, options, values, onSelect }: MultiSelectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<KeyValueProps['key'][]>([])

  const selectOption = (option: KeyValueProps['key']) => {
    const selectedOption = selectedOptions.find((o) => o === option)

    let newOptions: KeyValueProps['key'][] = []
    if (selectedOption) newOptions = selectedOptions.filter((o) => o !== selectedOption)
    else newOptions = [...selectedOptions, option]

    setSelectedOptions(newOptions)

    onSelect?.(newOptions)
  }

  return (
    <>
      {label && <div className='mb-1'>{label}</div>}
      <div className='flex flex-wrap gap-2 mb-4'>
        {options.map((item, id) => (
          <button
            key={id + 1}
            className={[
              'px-2 py-1 text-sm',
              selectedOptions.find((o) => o === item.key)
                ? 'bg-secondary text-white'
                : 'bg-background text-black'
            ].join(' ')}
            onClick={() => selectOption(item.key)}
          >
            {item.value}
          </button>
        ))}
      </div>
    </>
  )
}
