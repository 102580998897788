import { L10N } from 'global/localization'
import type { EmptyProps } from 'global/types/props'
import { ResultContent } from 'shared/components/result-content'

export const Empty = ({ onAction }: EmptyProps) => (
  <div className='h-full flex flex-col items-center justify-center'>
    <ResultContent
      image='/not-found.png'
      title={L10N.common.errMsgResourceNotFound}
      desc={L10N.common.errMsgResourceNotFound}
      actionText={L10N.common.reload}
      onAction={onAction}
    />
  </div>
)
