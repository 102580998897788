import { API } from 'global/api'
import type { L10nResponse, PrivacyPolicyResponse, TncResponse } from 'global/types/responses'
import { api, headers } from './api'

const generalApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getL10n: builder.query<L10nResponse, void>({
      query: () => ({
        url: API.URL_GET_L10N,
        method: 'GET',
        headers: headers()
      })
    }),
    getTnc: builder.query<TncResponse, void>({
      query: () => ({
        url: API.URL_GET_TNC,
        method: 'GET',
        headers: headers()
      })
    }),
    getPrivacyPolicy: builder.query<PrivacyPolicyResponse, void>({
      query: () => ({
        url: API.URL_GET_PRIVACY_POLICY,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const { useGetL10nQuery, useGetTncQuery, useGetPrivacyPolicyQuery } = generalApi
