export const API = {
  // AUTH
  URL_GET_L10N: '/auth/api/v1/language',
  URL_GET_COUNTRY_CODE: '/auth/api/v1/Country/listPhoneExt',

  // CORE
  URL_GET_TRX_TYPE_LIST: '/core/api/v1/TransactionHistory/GetType',
  URL_GET_TRX_STATUS_LIST: '/core/api/v1/TransactionHistory/GetStatus/0',
  URL_GET_TNC: '/core/api/v1/TermConditions/GetTermConditions',
  URL_GET_PRIVACY_POLICY: '/core/api/v1/PrivacyPolicy/GetPrivacyPolicy',
  URL_GET_CAR_LIST: '/core/api/v1/MyCar/GetMyCarList',
  URL_GET_CAR_DETAIL: '/core/api/v1/MyCar/GetCarDetail',
  URL_GET_TRX_HISTORY: '/core/api/v1/TransactionHistory/List',
  URL_GET_TRX_HISTORY_DETAIL: '/core/api/v1/PurchaseTransaction/Payment',
  URL_GET_CONTACT_US: '/core/api/v1/ContactUs',

  // PAYMENT
  URL_GET_PAYMENT_METHODS: '/payment/api/v1/PaymentMethod'
}
