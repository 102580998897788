import Icon from 'shared/components/icon'
import { formatCurrency, formatDateTime } from 'shared/services/utils'
import type { TrxHistoryListItemProps } from 'global/types/props'
import { StatusBadge } from 'shared/components/status-badge'

export const TrxHistoryListItem = ({ trxDetail, onClick }: TrxHistoryListItemProps) => (
  <button className='flex flex-col bg-white p-3 mb-4 last:mb-0 w-full text-left' onClick={() => onClick(10)}>
    <div className='flex flex-row gap-2 items-center mb-3 w-full'>
      <Icon icon={trxDetail.icon} size={20} />
      <div className='flex flex-col flex-1'>
        <div className='font-medium text-sm'>{trxDetail.transactionType}</div>
        <div className='text-neutral text-xs'>
          {formatDateTime(trxDetail.transactionDate, true)}
        </div>
      </div>
      <StatusBadge status='Paid' />
    </div>

    <div className='font-medium w-full'>{trxDetail.shortDesc}</div>

    <div className='flex flex-row items-center justify-between mb-3 w-full'>
      <div className='text-neutral text-xs'>{trxDetail.transactionCode}</div>
      <div className='text-sm text-right'>{formatCurrency(trxDetail.price ?? 0)}</div>
    </div>

    <div className='flex flex-row items-center justify-between w-full bg-background border border-border px-2 py-1'>
      <div className='text-sm'>Expired in</div>
      <div className='text-sm text-right'>{formatDateTime(trxDetail.transactionDate, true)}</div>
    </div>
  </button>
)
