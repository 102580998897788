import { useEffect, useState } from 'react'
import type { KeyValueProps, TrxFilterValuesProps, TrxHistoryFilterProps } from 'global/types/props'
import type { TrxStatusResponse, TrxTypeResponse } from 'global/types/responses'
import { useLazyGetTrxStatusListQuery, useLazyGetTrxTypeListQuery } from 'store/transaction'
import { ActionSheet } from 'shared/components/action-sheet'
import { DataHandler } from 'shared/components/data-handler'
import { Select } from 'shared/components/select'
import { Button } from 'shared/components/button'
import { ButtonColor, SelectType } from 'global/types/enums'
import { DatePicker } from 'shared/components/date-picker'
import { MultiSelect } from 'shared/components/multi-select'
import { Empty } from 'shared/components/empty'

export const TrxHistoryFilter = ({ open, onClose, onReset, onApply }: TrxHistoryFilterProps) => {
  const [getTrxTypeList, trxTypeListResult] = useLazyGetTrxTypeListQuery()

  const [getTrxStatusList, trxStatusListResult] = useLazyGetTrxStatusListQuery()

  const [values, setValues] = useState<TrxFilterValuesProps>(defaultValues)

  useEffect(() => {
    if (!open || (trxTypeListResult.data && trxStatusListResult.data)) return

    getTrxTypeList()
      .unwrap()
      .then((res) => setValues((prevValues) => ({ ...prevValues, subscriptionPlan: res[0].name })))

    getTrxStatusList()
      .unwrap()
      .then((res) => setValues((prevValues) => ({ ...prevValues, paymentMethod: res[0].name })))
  }, [open, getTrxTypeList, trxTypeListResult.data, getTrxStatusList, trxStatusListResult.data])

  return (
    <ActionSheet title='Filter & Sort' open={open} onClose={onClose}>
      <div className='p-4'>
        <DataHandler
          isLoading={trxTypeListResult.isLoading || trxStatusListResult.isLoading}
          error={trxTypeListResult.error || trxStatusListResult.error}
          noAnimation
        >
          {trxTypeListResult.data?.length && trxStatusListResult.data?.length ? (
            <>
              <MultiSelect
                label='Payment Status'
                options={paymentStatusList.map((o) => ({ key: o, value: o }))}
                onSelect={(option) =>
                  setValues((prevValues) => ({
                    ...prevValues,
                    paymentStatus: option
                  }))
                }
              />

              <Select
                label={'Subscription Plan'}
                options={getOptions(trxTypeListResult.data)}
                value={values.subscriptionPlan}
                onSelect={(option) => {
                  // Seharusnya cukup pake option.value
                  const subscriptionPlan = trxTypeListResult.data?.find(
                    (d) => d.name === option
                  )?.name

                  setValues((prevValues) => ({ ...prevValues, subscriptionPlan }))
                }}
              />

              <Select
                label={'Payment Method'}
                options={getOptions(trxStatusListResult.data)}
                value={values.paymentMethod}
                onSelect={(option) => {
                  // Seharusnya cukup pake option.value
                  const paymentMethod = trxStatusListResult.data?.find(
                    (d) => d.name === option
                  )?.name

                  setValues((prevValues) => ({ ...prevValues, paymentMethod }))
                }}
              />

              <DatePicker
                label='Transaction Date'
                value={values.trxDate}
                onSelect={(value) => {
                  setValues((prevValues) => ({ ...prevValues, trxDate: value }))
                }}
              />

              <Select
                label='Sort'
                type={SelectType.Pills}
                options={sortList.map((o) => ({ key: o, value: o }))}
                value={values.sort}
                onSelect={(option) =>
                  setValues((prevValues) => ({ ...prevValues, sort: option }))
                }
              />

              <div className='grid grid-cols-2 gap-4'>
                <Button
                  label='Reset'
                  color={ButtonColor.WhiteBordered}
                  onClick={() => onReset(defaultValues)}
                />
                <Button label='Apply' color={ButtonColor.Primary} onClick={() => onApply(values)} />
              </div>
            </>
          ) : (
            <Empty />
          )}
        </DataHandler>
      </div>
    </ActionSheet>
  )
}

const getOptions = (rawOptions: (TrxTypeResponse | TrxStatusResponse)[]): KeyValueProps[] =>
  rawOptions.map((option) => ({ key: option.name, value: option.name }))

const paymentStatusList = ['Paid', 'Waiting Payment', 'Expired', 'Failed']

const sortList = ['Newest', 'Oldest', 'Ascending', 'Descending']

const defaultValues: TrxFilterValuesProps = {
  paymentStatus: undefined,
  subscriptionPlan: undefined,
  paymentMethod: undefined,
  trxDate: undefined,
  sort: 'Newest'
}
