import { ButtonColor } from 'global/types/enums'
import type { ResultContentProps } from 'global/types/props'
import { Button } from 'shared/components/button'

export const ResultContent = ({
  image,
  title,
  desc,
  useSmallAction = false,
  actionText,
  onAction
}: ResultContentProps) => (
  <div className='flex flex-col items-center text-center w-full'>
    <img src={image} alt='result-image' className='object-contain size-28 mb-4' />

    <div className='mb-4'>
      <h2 className={['font-heading font-medium mb-4 last:mb-0', desc ? 'text-lg' : ''].join(' ')}>
        {title}
      </h2>
      {desc && <p>{desc}</p>}
    </div>

    {actionText && onAction && (
      <div className={useSmallAction ? '' : 'grid grid-cols-1 w-full'}>
        <Button label={actionText} color={ButtonColor.Primary} onClick={onAction} />
      </div>
    )}
  </div>
)
